import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatChipListboxChange } from '@angular/material/chips';
import { CouponOptions } from '../../model/CouponOptions';
import { PricingOptionsService } from '../../services/pricing-options.service';

@Component({
  selector: 'app-coupon-inputs',
  templateUrl: './coupon-inputs.component.html',
  styleUrl: './coupon-inputs.component.scss'
})
export class CouponInputsComponent implements OnInit, OnChanges {

  @Input()
  frequencyDefault: string;

  @Input()
  coupons: CouponOptions;

  @Output()
  updatedCouponOptions = new EventEmitter<CouponOptions>();

  hasMemory: boolean = false;
  payoff: number = 10;
  barrier: number = 100;
  frequency: string;
  solvingForCoupon: boolean = false;

  constructor(private pricingOptions: PricingOptionsService) {
    this.solvingForCoupon = pricingOptions.currentlySolvingFor == 1;
    pricingOptions.solvingForChange.subscribe(
      id => {
        this.solvingForCoupon = (id == 1);
      }
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['frequencyDefault']) {
      this.frequency = this.frequencyDefault;
      setTimeout(() => this.inputChanged(), 100);
    }

    if(changes['coupons']) {
      let change: CouponOptions = changes['coupons'].currentValue;
      if(change) {
        this.barrier = Number((change.barrier! * 100).toFixed(3))
        this.payoff = Number((change.payoff! * 100).toFixed(3))
        this.hasMemory = change.hasMemory!
        this.selectedObservation = change.barrierType!
        if(change.couponType) {
          this.couponType = change.couponType;
        } else {
          //Lets guess if we dont know
          if(this.hasMemory) {
            this.couponType = 2;
          } else {
            this.couponType = this.barrier == 0 ? 0 : 1;
          }
        }
      } else {
        //Coupons have been removed so reset
        this.hasMemory = false;
        this.couponType = 0;
        this.payoff = 10;
        this.barrier = 100;
      }
    }
  }

  ngOnInit(): void {
    this.frequency = this.frequencyDefault;
    setTimeout(() => this.inputChanged(), 100);
  }


  inputChanged() {
    this.updatedCouponOptions.emit(this.createCouponOptions());
  }

  couponType:number = 0;
  couponTypeChange(e:MatChipListboxChange) {
    if(e.value) {
      this.couponType = e.value;
    }
    this.inputChanged();
  }


  selectedObservation : string = 'Date';
  possibleObservations = [
    {name: 'PERIOD\'S END', value: 'Date'},
    {name: 'DAILY DURING PERIOD', value: '' },
    {name: 'CONTINUOUSLY', value: 'Continuous'}
  ]

  observationTypeChange(e:MatChipListboxChange) {
    this.selectedObservation = e.value;
  }

  createCouponOptions(): CouponOptions {
    switch(Number(this.couponType)) {
      case 0:
        return this.createFixedCouponOptions();
      case 1:
      case 2:
        return this.createContingentCouponOptions();
      default:
        return {};
    }

  }

  createFixedCouponOptions(): CouponOptions {
    return {
      hasMemory: false,
      barrierType: 'Date',
      payoff: this.payoff / 100,
      barrier: 0,
      frequency: this.frequencyDefault,
      couponType: this.couponType
    }
  }

  createContingentCouponOptions(): CouponOptions {
    let hasMemory = this.couponType == 2;
    return {
      hasMemory: hasMemory,
      barrierType: this.selectedObservation,
      payoff: this.payoff / 100,
      barrier: this.barrier / 100,
      frequency: this.frequency,
      couponType: this.couponType
    }
  }

  frequencies = [
    {'name': 'Annually'},
    {'name': 'Semiannually'},
    {'name': 'Quarterly'},
    {'name': 'B-Monthly'},
    {'name': 'Monthly'}
  ]

}
