import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  constructor(private router: Router) {
    
  }

  onTerms() {
    this.router.navigate(['/terms']);
  }

  onPolicy() {
    this.router.navigate(['/policy']);
  }

}
