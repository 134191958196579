<div class="wrapper">
    <div class="login-box">
        <mat-card class="col-md-4 offset-md-4 login-card">
            <mat-card-content class="login-contents">
                <h1 class="heading">Welcome</h1>
                <p class="login-instructions">Please log into to your account</p>
                <div class="col-md-12 label-hover">
                    <mat-label class="label">USERNAME</mat-label>
                    <mat-form-field floatLabel="always" class="col-md-12">
                        <input matInput placeholder="" [(ngModel)]="username" class="email-input">
                        <mat-hint class="error-text" *ngIf="errorMessage">{{errorMessage}}</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-md-12 label-hover" *ngIf="showPasswordInput()">
                    <mat-label class="label">{{passwordLabel()}}</mat-label>
                    <mat-form-field floatLabel="always" class="col-md-12">
                        <input matInput type="password" placeholder="" [(ngModel)]="password" class="password-input" (keyup.enter)="btnClicked()">
                    </mat-form-field>
                </div>
                <div class="col-md-12 label-hover" *ngIf="showPasswordResetCodeInput()">
                    <mat-label class="label">RESET CODE</mat-label>
                    <mat-form-field floatLabel="always" class="col-md-12">
                        <input matInput placeholder="" [(ngModel)]="resetCode" class="email-input">
                    </mat-form-field>
                </div>
                <div class="col-md-12 label-hover" *ngIf="showFirstTimeLogin()">
                    <p>First Time Login - Please update password</p>
                    <mat-label class="label">NEW PASSWORD</mat-label>
                    <mat-form-field floatLabel="always" class="col-md-12">
                        <input matInput type="password" placeholder="" [(ngModel)]="newPassword" class="password-input">
                    </mat-form-field>
                </div>
                <div class="forgotten-login-details" *ngIf="showResetOption()">
                    <u (click)="changeStateToResetPassword()">Forgotten your login details?</u>
                </div>
                <div>
                  <button mat-raised-button color="primary" class="col-md-12 login-button" (click)="btnClicked()">{{btnText()}}</button>
                </div>
            </mat-card-content>
          </mat-card>
    </div>
    <div class="watermark">
        <img src="/assets/ARROW_Brandmark_Outline_Thin_WHITE.png">
    </div>
</div>
