import { Injectable, OnInit } from '@angular/core';
import { signOut, getCurrentUser, JWT } from 'aws-amplify/auth';
import { LocalstorageService, SessionToken } from './localstorage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CognitoService {

  session: SessionToken | null = null;

  constructor(private storage: LocalstorageService, private router: Router) {
    this.session = this.storage.getSessionToken();
  }

  public checkLoggedIn() : boolean {
    if(!this.session) {
      this.session = this.storage.getSessionToken();
    }
    let isLoggedIn = (this.session && (new Date() < new Date(this.session.expiry)))!;
    return isLoggedIn;
  }

  public getTokenString() : string | undefined {
    return this.session?.tokenString;
  }

  public setToken(token: SessionToken) {
    console.log("Token has been set..")
    this.session = token;
    this.storage.setSessionToken(token);
  }

  public signout() {
    signOut();
    this.session = null;
    this.storage.removeSessionToken();
    console.log('User Signed Out');
    this.router.navigate(['']);
  }

  public createSessionToken(jwt: JWT) : SessionToken {

    let token: SessionToken = {
      tokenString: jwt.toString(),
      sub: jwt.payload.sub!,
      username: jwt.payload['username']?.toString(),
      email: jwt.payload['email']?.toString(),
      expiry: new Date(jwt.payload.exp! * 1000)
    }

    return token;
  }
}
