import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, filter, switchMap, take, timer } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  constructor(private http: HttpClient) { }

  public price(productId: number): Observable<RiskEngineResult> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/price/'
    return this.http.get<RiskEngineResult>(url + productId, { withCredentials: true })
  }

  private getResult(uuid: string): Observable<RiskEngineResult> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/price/async/' + uuid;
    return this.http.get<RiskEngineResult>(url);
  }

  public priceProductAsync(productId: number, asOf: Date | undefined, useLambda: boolean): Observable<RiskEngineResult> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/price/async'
    let request: PriceRequest = {productId: productId, asOf: asOf, useLambda: useLambda}

    //Probably need to check on error conditions
    return this.http.post<string>(url, request, { withCredentials: true }).pipe(
      switchMap(
        uuid => timer(4000, 2000).pipe(
          switchMap(() => this.getResult(uuid)),filter(data => (data != null)),take(1)
      ))
    )
  }

}

export interface PriceRequest {
  productId: number;
  asOf: Date | undefined;
  useLambda: boolean | undefined;
}
export interface RiskEngineResult {
  fairValue: number;
  timeTaken: string;
  stdDev: number;
  impliedIssuerPnL: number;
  issuerFee: number;
  error: String;
  compiledOn: Date;
  firstDataDate: Date;
  lastDataDate: Date;
}
