import { Component, OnInit } from '@angular/core';
import { CognitoService } from '../../services/cognito.service';
import { Router } from '@angular/router';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { fetchAuthSession, signIn, getCurrentUser, confirmSignIn, resetPassword, confirmResetPassword } from 'aws-amplify/auth';
import { SessionToken } from '../../services/localstorage.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  
  private LOGIN: string = 'Login';
  private REQUEST_RESET_CODE: string = 'RequestCode';
  private RESET_PASSWROD: string = 'ResetPassword';
  private FIRST_LOGIN: string = 'FirstLogin';

  private CurrentState: string = this.LOGIN;

  username: string;
  password: string;
  resetCode: string;
  newPassword: string;
  errorMessage = "";

  constructor(private router: Router, private cognitoService: CognitoService, public authenticator: AuthenticatorService) {
    
  }

  ngOnInit(): void {
    getCurrentUser().then(() => this.setToken(), () => console.log('No User'));
  }

  public signIn(): void {
    signIn({username: this.username, password: this.password})
      .then(
        (d) => {
          if(d.isSignedIn) {
            console.log("User Logged In");
            this.setToken();
          } else {
            this.CurrentState = this.FIRST_LOGIN;
          }
        },
        (e) => {
          console.log("Problem: " + e);
          this.errorMessage = "Incorrect username or password"
          this.password = '';
        } 
    )
  }

  showFirstTimeLogin() {
    return this.CurrentState == this.FIRST_LOGIN;
  }
  showResetOption() {
    return this.CurrentState == this.LOGIN;
  }
  showPasswordInput() {
    return this.CurrentState != this.REQUEST_RESET_CODE;
  }
  showPasswordResetCodeInput() {
    return this.CurrentState == this.RESET_PASSWROD;
  }  

  public changeStateToResetPassword() {
    this.errorMessage = '';
    this.CurrentState = this.REQUEST_RESET_CODE;
  }

  public requestResetPasswordCode() {
    resetPassword({username: this.username})
    .then(
      (d) => {
        console.log(d);
        this.CurrentState = this.RESET_PASSWROD;
        this.errorMessage = '';
      },
      (e) => {
        console.error(e);
        this.errorMessage = 'Unable to request reset code, please contact Arrow for help'
      }
    )
  }

  public updatePassword() {
    confirmSignIn({challengeResponse: this.newPassword})
    .then(() => {
      this.CurrentState == this.LOGIN;
      this.errorMessage = '';
      this.setToken()
    }, (e) => {
      this.errorMessage = e.message;
    });
  }

  public confirmPasswordReset() {
    confirmResetPassword({username: this.username, newPassword: this.password, confirmationCode: this.resetCode}).then(
      () => {
        this.CurrentState = this.LOGIN;
        this.errorMessage = '';
      }, 
      (e) => {
          this.errorMessage = e.message;
      }
    )
    
  }

  private setToken() {
    fetchAuthSession().then((d) => {
      let token: SessionToken;
      if(d.tokens?.idToken) {
        token = this.cognitoService.createSessionToken(d.tokens.idToken!);
        this.cognitoService.setToken(token);
        this.router.navigate(['']);
      } else {
        console.error("Issue generating a token");
        //show error
      }
    })

  }

  passwordLabel() {
    if(this.CurrentState == this.RESET_PASSWROD) {
      return 'NEW PASSWORD';
    } else {
      return 'PASSWORD';
    }
  }

  public btnClicked() {
    switch(this.CurrentState) {
      case this.LOGIN:
        this.signIn();
        break;
      case this.FIRST_LOGIN:
        this.updatePassword();
        break;
      case this.REQUEST_RESET_CODE:
        this.requestResetPasswordCode();
        break;
      case this.RESET_PASSWROD:
        this.confirmPasswordReset();
        break;
      default:
    }
  }

  public btnText() : string {
    switch(this.CurrentState) {
      case this.LOGIN:
        return 'LOGIN';
      case this.FIRST_LOGIN:
        return 'UPDATE';
      case this.REQUEST_RESET_CODE:
        return 'REQUEST RESET CODE'
      case this.RESET_PASSWROD:
        return 'RESET PASSWORD'
      default:
        return 'LOGIN';
    }
  }



  
}
