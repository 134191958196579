import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Metric} from "../components/dialogs/advisor-analytics/advisor-analytics.component";
import {Observable} from "rxjs";
import {AnalysisResultModel} from "../model/AnalysisResultModel";
import {environment} from "../../../environments/environment";
import {AnalysisRequest} from "./analysis.service";
import {ExcelExportRequest} from "./excel.service";

@Injectable({
  providedIn: 'root'
})
export class AdvisorAnalyticsService {

  constructor(private http: HttpClient) { }

  public getAdvisorAnalytics() : Observable<AdvisorAnalyticsResponse> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/advisor-analytics/metrics'
    return this.http.get<AdvisorAnalyticsResponse>(url, { withCredentials: true })
  }

  public saveAdvisorAnalytics(prefs: AdvisorAnalyticsResponse) {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/advisor-analytics/prefs'
    return this.http.post(url, prefs,{ withCredentials: true })
  }

  public exportToPDF(ids: string[], metrics: AdvisorAnalyticsResponse) {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/advisor-analytics/pdf'
    let request: AdvisorAnalyticsPDFRequest = {
      productIds: ids,
      metrics: metrics
    }
    this.http.post(url, request, { withCredentials: true, observe: 'response', responseType: 'blob', headers: {'Accept' : 'application/octet-stream'} }).subscribe(
      response => {
        this.downloadFile(response, request);
      }
    )
  }
  downloadFile(data: any, request: AdvisorAnalyticsPDFRequest) {
    let fileName = "ArrowExport-" + request.productIds.join("_")
    const blob = new Blob([data.body], { type: 'application/octet-stream' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName + `.pdf`;
    link.click();
  }
}

export interface AdvisorAnalyticsPDFRequest {
  productIds: string[];
  metrics: AdvisorAnalyticsResponse;
}

export interface AdvisorAnalyticsResponse {
  returnMetrics: Metric[];
  riskMetrics: Metric[];
  arrowRatios: Metric[];
}
