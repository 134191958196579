<div mat-dialog-title class="dialog-title">
  <h4 class="schedule-header">Advisor Analytics</h4>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon class="white">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>

    <section>
      <h2>Return Metrics</h2>
      <span>
        <ul>
          @for (metric of advisorAnalytics.returnMetrics; track metric) {
            <li>
              <mat-checkbox [(ngModel)]="metric.enabled" color="primary" (change)="inputChanged($event)">
                {{metric.name}}
              </mat-checkbox>
            </li>
          }
        </ul>
      </span>
    </section>
  <section>
    <h2>Risk Metrics</h2>
    <span>
        <ul>
          @for (metric of advisorAnalytics.riskMetrics; track metric) {
            <li>
              <mat-checkbox [(ngModel)]="metric.enabled" color="primary" (change)="inputChanged($event)">
                {{metric.name}}
              </mat-checkbox>
            </li>
          }
        </ul>
      </span>
  </section>
  <section>
    <h2>Arrow Ratios</h2>
    <span>
        <ul>
          @for (metric of advisorAnalytics.arrowRatios; track metric) {
            <li>
              <mat-checkbox [(ngModel)]="metric.enabled" color="primary" (change)="inputChanged($event)">
                {{metric.name}}
              </mat-checkbox>
            </li>
          }
        </ul>
      </span>
  </section>

</div>

<div mat-dialog-actions class="action-btns">
  <button mat-raised-button color="primary" (click)="downloadPDF()">Create PDF</button>
  <button mat-raised-button color="primary" [disabled]="isSameAsDefault" (click)="saveAsDefault()">Save View As Default</button>
  <button mat-raised-button color="primary" [disabled]="allSelected" (click)="selectAll()">Select All</button>
</div>
