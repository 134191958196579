import { Component, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { ProfileService } from './services/profile.service';
import { Router } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { Amplify } from 'aws-amplify';
import { CognitoService } from './services/cognito.service';
import { SwitcherComponent } from './pages/switcher/switcher.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PolicyComponent } from './pages/policy/policy.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'portal-ui';

  username: string;
  wordMarkPNGLocation: string;
  wordMarkStyle: string;

  constructor(private el:ElementRef, private profileService: ProfileService, private router: Router, private cognito: CognitoService){
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: environment.cognito.userPoolId,
          userPoolClientId: environment.cognito.userPoolWebClientId,
        }
      }
    });
  }

  ngOnInit(): void {

    let alldrpdwn = document.querySelectorAll('.dropdown-container');
    console.log(alldrpdwn, 'alldrpdwn#');
    alldrpdwn.forEach((item:any) => {
      const a = item.parentElement?.querySelector('a:first-child');
      console.log(a, 'a#');
      a.addEventListener('click', (e:any)=> {
        e.preventDefault();
        this.el.nativeElement.classList.toggle('active');
        item.classList.toggle('show');
      })
    })
    if(environment.env==="prod") {
      this.wordMarkPNGLocation = "assets/ARROW_Wordmark_WHITE.png"
      this.wordMarkStyle="height:15px;width:auto"
    } else if(environment.env==="uat") {
      this.wordMarkPNGLocation = "assets/ARROW_UAT_1.png"
      this.wordMarkStyle="height:30px;width:auto"
    } else if(environment.env==="dev") {
        this.wordMarkPNGLocation = "assets/ARROW_DEV.png"
        this.wordMarkStyle="height:30px;width:auto"
    } else if(environment.env==="local") {
      this.wordMarkPNGLocation = "assets/ARROW_Local_4.png"
      this.wordMarkStyle="height:30px;width:auto"
    }
  }

  // resposive menu
  responsiveMenu: any;
  responsiveContent: any;
  defaultStatus=true;
  openNav(status: any) {
    if(status===this.defaultStatus) {
      this.responsiveMenu = {
        'display': 'block'
      }
      this.responsiveContent = {
        // 'margin-left':'150px'
      }
      this.defaultStatus = false;
    } else {
      this.responsiveMenu = {
        'display': null
      }
      this.responsiveContent = {
        'margin-left':null
      }
      this.defaultStatus = true;
    }
  }

  goHome() {
    this.router.navigate(['']);
  }

  signOut() {
    this.cognito.signout();
  }

  signin() {
    this.router.navigate(['/login']);
  }

  showMenu = false;
  showNavOptions = false;
  showSignin = false;
  onActivate(event :EventEmitter<any>) {
    if(event instanceof SwitcherComponent || event instanceof TermsComponent || event instanceof PolicyComponent) {
      this.showNavOptions = false;
      let loggedIn = this.cognito.checkLoggedIn();
      this.showMenu = loggedIn;
      this.showSignin = !loggedIn;
    } else if(event instanceof LoginComponent) {
      this.showNavOptions = false;
      this.showMenu = false;
      this.showSignin = false;
    } else {
      this.showNavOptions = true;
      this.showMenu = true;
      this.showSignin = false;
    }
  }

  protected readonly environment = environment;
}
