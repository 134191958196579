<div class="table-container">
    <table mat-table [dataSource]="pricingQueue" multiTemplateDataRows class="pricing-queue-table">

        <ng-container matColumnDef="arrowId">
            <th mat-header-cell *matHeaderCellDef class="header-cell"> NAME </th>
            <td mat-cell *matCellDef="let element" class="name-cell" matTooltip="{{element.cusip ? element.cusip : element.arrowId}}"> {{element.cusip ? element.cusip : element.arrowId}}</td>
        </ng-container>

        <ng-container matColumnDef="solvedFor">
            <th mat-header-cell *matHeaderCellDef class="header-cell"> VARIABLE SOLVED FOR </th>
            <td mat-cell *matCellDef="let element"> {{element.solvedFor}} </td>
        </ng-container>

        <ng-container matColumnDef="fairValue">
            <th mat-header-cell *matHeaderCellDef class="header-cell"> ARROW FAIR VALUE </th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.isRunning"><mat-spinner [diameter]="20"></mat-spinner></span>
                <span *ngIf="!element.isRunning && element.error"><mat-icon [matTooltip]="element.error" color="warn">warning</mat-icon></span>
                <span>{{element.arrowFair | number:'1.0-2'}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="impliedIssuerPnL">
            <th mat-header-cell *matHeaderCellDef class="header-cell"> SPREAD ABOVE ARROW FAIR VALUE </th>
            <td mat-cell *matCellDef="let element"> {{element.spreadAbove | number:'1.0-2'}} </td>
        </ng-container>

        <ng-container matColumnDef="arrowBookValue">
            <th mat-header-cell *matHeaderCellDef class="header-cell"> ARROW BOOK VALUE </th>
            <td mat-cell *matCellDef="let element"> {{element.fairValue | number:'1.0-2'}} </td>
        </ng-container>

      <ng-container matColumnDef="selectBox">
        <th mat-header-cell *matHeaderCellDef class="header-cell"> </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox class="white-checkbox" [(ngModel)]="element.isSelected" [disabled]="!element.hasAnalysis"></mat-checkbox>
        </td>
      </ng-container>

        <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef class="header-cell"> OPTIONS </th>
            <td mat-cell *matCellDef="let element">
                <div class="row icon-row">
                    <button mat-icon-button aria-label="Save" matTooltip="Save Product" class="icon-display col-md-2" (click)="saveProduct(element)">
                        <mat-icon>save_alt</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="Delete" matTooltip="Delete Product" class="icon-display col-md-2" (click)="deleteProduct(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button mat-icon-button aria-label="Edit" matTooltip="Edit" class="icon-display col-md-2" (click)="editProduct(element)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selected(row)" [ngClass]="getClass(row)" [cdkContextMenuTriggerFor]="context_menu" [cdkContextMenuTriggerData]="{data: row}"></tr>
    </table>
</div>

<ng-template #context_menu let-row="data">
    <div class="example-menu" cdkMenu>
      <button class="example-menu-item" cdkMenuItem (click)="saveProduct(row)">Save Product</button>
      <button class="example-menu-item" cdkMenuItem [disabled]="selectedProducts().length == 0" (click)="saveSelected()">Save Selected Products</button>
      <button class="example-menu-item" cdkMenuItem (click)="deleteProduct(row)">Delete Product</button>
      <button class="example-menu-item" cdkMenuItem (click)="editProduct(row)">Show / Edit Product</button>
      <button class="example-menu-item" cdkMenuItem (click)="sendMailToArrow(row)">Send To Arrow</button>
      <button class="example-menu-item" cdkMenuItem [disabled]="selectedProducts().length == 0" (click)="exportProduct(row)">Export Selected to Excel</button>
      <button class="example-menu-item" cdkMenuItem [disabled]="selectedProducts().length == 0 || selectedProducts().length > 5" (click)="viewAdvisorAnalyticsDialog()">Export Selected to PDF</button>
      <button class="example-menu-item" cdkMenuItem [disabled]="selectedProducts().length == 0" (click)="deleteSelected()">Delete Selected Products</button>
    </div>
</ng-template>
