import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PricingComponent } from './pages/pricing/pricing.component';
import { LoginComponent } from './pages/login/login.component';
import { authGuard } from './auth/auth.guard';
import { SwitcherComponent } from './pages/switcher/switcher.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PolicyComponent } from './pages/policy/policy.component';
import {AdminPageComponent} from "./pages/admin-page/admin-page.component";


const routes: Routes = [
  {path: '', component: SwitcherComponent},
  {path: 'pricing', component: PricingComponent, canActivate: [authGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'policy', component: PolicyComponent},
  {path: 'admin', component: AdminPageComponent, canActivate: [authGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
