<div class="row">
    <div class="col-md-6 label-hover">
        <mat-label class="label">CALL PREMIUM (P.A.)</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="!isSolvingForPremium">
            <input matInput type="number" placeholder="0" class="percent-input" [(ngModel)]="autocallOptions.payoffPA" (change)="inputChanged()">
            <span matTextSuffix>%</span>
        </mat-form-field>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="isSolvingForPremium">
            <input matInput placeholder="SOLVING" disabled>
        </mat-form-field>
    </div>
    <div class="col-md-6 label-hover">
        <mat-label class="label">PAYMENT FREQUENCY</mat-label>
        <mat-form-field class="col-md-12">
            <input matInput [ngModel]="frequency" disabled>
        </mat-form-field>
    </div>
    <div class="row">
      <div class="col-md-12 put-type chip-options">
        <mat-chip-listbox (change)="memoriserChanged($event)" aria-label="Change Call Type">
          <mat-chip-option value="0" [selected]="autocallOptions.isMemoriser">MEMORIZER</mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>
</div>
