import {Component, Inject, OnDestroy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {AdvisorDetails} from "../../../model/AdvisorDetails";
import {DateFilterFn} from "@angular/material/datepicker";
import moment from "moment/moment";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MY_FORMATS} from "../../../../pages/pricing/pricing.component";
import {ProductSearchItem, ProductService} from "../../../services/product.service";
import {BehaviorSubject, Subscription} from "rxjs";

@Component({
  selector: 'app-save-product-dialog',
  templateUrl: './save-product-dialog.component.html',
  styleUrl: './save-product-dialog.component.scss',
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
  ],
})
export class SaveProductDialogComponent implements OnDestroy{
  constructor(
    private productService: ProductService,
    public dialogRef: MatDialogRef<SaveProductDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    if(data.advisorDetails.branchCode) {
      this.currentBranchCode = data.advisorDetails.branchCode;
    }
  }

  currentBranchCode: string | undefined;
  productList = new BehaviorSubject<ProductSearchItem[]>([]);

  subscriptions: Subscription[] = [
    this.productService.loadProductList().subscribe(productList => {
      this.productList.next(productList);
    })
  ];

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  getTradeDateLabelClass(){
    if(this.hasCusipNoTradeDate()){
      return 'label red';
    }
    return 'label';
  }

  getCUSIPLabelClass(){
    if(this.hasCusipNoTradeDate() || this.hasDuplicateCusips()){
      return 'label red';
    }
    return 'label';
  }

  updateProductName(prevCode: string | undefined, newCode: string): void {
    let insertPoint = this.data.productName!.split(" ", 2).join(" ").length
    if(!prevCode) {
      this.data.productName = this.data.productName?.slice(0, insertPoint) + ' ' + newCode  + this.data.productName?.slice(insertPoint);
    } else {
      this.data.productName = this.data.productName?.replace(prevCode, newCode)
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  holidayDateFilter: DateFilterFn<moment.Moment | null> = (d: moment.Moment | null): boolean => {
    return d?.day() != 0 && d?.day() != 6;
  }

  hasCusipNoTradeDate() {
    return !this.data.tradeDate&&!!this.data.cusip;
  }

  hasDuplicateCusips() {
    let collisions = this.productList.getValue().filter(productSearchItem =>
      !!productSearchItem.cusip &&
      productSearchItem.cusip === this.data.cusip &&
      productSearchItem.productId != this.data.supersededId &&
      productSearchItem.productId != this.data.productId
    );
    return collisions.length > 0;
  }

  branchCodeInput(event: any) {
    this.updateProductName(this.currentBranchCode, event.target.value);
    this.currentBranchCode = event.target.value;
  }
}

export interface DialogData {
  productName?: string;
  advisorDetails: AdvisorDetails;
  cusip?: string;
  tradeDate?: Date;
  supersededId?: number;
  bulk: boolean;
  productId: number;
}
