import { AnalysisResultModel } from "./AnalysisResultModel";
import { Barrier } from "./BarrierModel";
import { ProductBuildDetails } from "./ProductBuildModel";
import { ProductFees } from "./ProductSaveResult";
import {AdvisorDetails} from "./AdvisorDetails";

export interface PricingItem {
    arrowId: string;
    solvedFor: string;
    fairValue?: number;
    impliedIssuerPnL?: number;
    arrowFair?: number;
    issuerReoffer?: number;
    spreadAbove?: number;
    analysis: AnalysisResultModel;
    productId?: number;
    error?: String;
    isRunning?: boolean;
    productBuildDetails?: ProductBuildDetails;
    productFees?: ProductFees;
    isSelected?: boolean;
    hasAnalysis?: boolean;
    advisorDetails?: AdvisorDetails;
    cusip?: string;
    tradeDate?: Date;
    supersededId?: number;
    forceTradeDateAnalytics?: boolean;
}

export interface PricingComponentToSave {
  pricingQueue?:string;
  productBuildDetails?:string;
  currentLoadedProduct?:string;
  currentRowSelected?:string;
  currentRowSelectedForPricingQueue?:string;
  barriers?:string;
  ccys?:string;
  coupons?:string;
  defaultDownwardCap?:string;
  finalValuationDate?:string;
  issuers?:string;
  issuerCtrl?:string;
  selectedCcy?:string;
  staleIssuer?:string;
  staleUnderlyers?:string;
  strikeDate?:string;
  tenor?:string;
  underlyingCtrl?:string;
  couponInputs?: string;
  couponType?: string;
  selectedObservation?: string;
  frequencyDefault?: string;
  couponBarrier?: string;
  frequency?: string;
  hasMemory?: string;
  payoff?: string;
  callabilityType?: string;
  callType?: string;
  stepUp?: string;
  selectedHundredProtection?: string;
  currentlySolvingFor?: string;
  maxReturnUp?: string;
  maxReturnDown?: string;
}

export const LOCAL_STORAGE_BASE = "local_storage_base";
