import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Weight } from '../../../model/ProductModel';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { MatChipListboxChange } from '@angular/material/chips';

@Component({
  selector: 'app-underlying-input',
  templateUrl: './underlying-input.component.html',
  styleUrl: './underlying-input.component.scss'
})
export class UnderlyingInputComponent {

  @ViewChild('underlyingInput')
  underlyingInput!: ElementRef<HTMLInputElement>;

  underlyingCtrl = new FormControl('');

  filteredUnderlyings: Observable<any[]>;

  weights: Weight[] = [];

  underlyings: string[] = [];

  displayedColumns = ["underlying", "weight", "options"];

  constructor(
    public dialogRef: MatDialogRef<UnderlyingInputComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UnderlyingInputData,
  ) {
    dialogRef.disableClose = true;
    this.weights = JSON.parse(JSON.stringify(data.weights));
    this.underlyings = data.weights.map(w => w.underlying);

    this.filteredUnderlyings = this.underlyingCtrl.valueChanges.pipe(
      startWith(null),
      map((underlying) => (underlying ? this._filter(underlying) : this.data.fullUnderlyingList!.slice().filter(u => !this.underlyings.includes(u))))
    )

  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  deleteUnderlyer(weight: Weight) {
    const i = this.weights.indexOf(weight);
    if(i > -1) {
      this.weights.splice(i, 1);
    }
    
    const j = this.underlyings.indexOf(weight.underlying);
    if(j > -1) {
      this.underlyings.splice(j,1);
    }
    this.recalcBasketWeights();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.addNewUnderlyer(event.option.viewValue);
    this.underlyingInput.nativeElement.value = '';
    this.underlyingCtrl.setValue(null);
  }

  addNewUnderlyer(underlyerName: string) {
    let name = underlyerName.split(':')[0];
    this.underlyings.push(name);
    this.weights.push({underlying: name, weight: 0});
    this.recalcBasketWeights();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.data.fullUnderlyingList!.filter(u => u.toLowerCase().includes(filterValue)).filter(u => !this.underlyings.includes(u));
  }

  basketTypeChange(e: MatChipListboxChange) {
    if(e.value == '1') {
      this.data.isBasket = true;
    } else {
      this.data.isBasket = false;
    }
    this.recalcBasketWeights();
  }

  recalcBasketWeights() {
    let weight = this.data.isBasket && this.weights.length > 1 ? 100 / this.weights.length : 0;
    this.weights.forEach(w => w.weight = weight);
    this.weights = [...this.weights];
  }

  closeData(): UnderlyingInputData {
    return {
      weights: this.weights,
      isBasket: this.data.isBasket
    }
  }

}

export interface UnderlyingInputData {
  weights: Weight[];
  isBasket: boolean;
  fullUnderlyingList?: string[];
}
