import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AutocallOptions } from '../autocall-trigger-inputs/autocall-trigger-inputs.component';
import { MatChipListboxChange } from '@angular/material/chips';
import { PricingOptionsService } from '../../services/pricing-options.service';

@Component({
  selector: 'app-call-premium-inputs',
  templateUrl: './call-premium-inputs.component.html',
  styleUrl: './call-premium-inputs.component.scss'
})
export class CallPremiumInputsComponent {

  @Input()
  autocallOptions: AutocallOptions;
  @Output()
  autocallOptionsChange = new EventEmitter<AutocallOptions>();

  @Input()
  frequency: string;

  @Output()
  somethingChanged = new EventEmitter();

  inputChanged() {
    this.somethingChanged.emit();
  }

  isSolvingForPremium = false;

  constructor(private pricingOptions: PricingOptionsService) {
    this.isSolvingForPremium = pricingOptions.currentlySolvingFor == 9;
    pricingOptions.solvingForChange.subscribe(
      id => {
        this.isSolvingForPremium = (id == 9);
      }
    )
  }

  memoriserChanged(e: MatChipListboxChange) {
    if(e.value) {
      this.autocallOptions.isMemoriser = true;
    } else {
      this.autocallOptions.isMemoriser = false;
    }
    this.inputChanged();
  }

}
