import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ProductFees } from '../../model/ProductSaveResult';

@Component({
  selector: 'app-fees-view',
  templateUrl: './fees-view.component.html',
  styleUrl: './fees-view.component.scss'
})
export class FeesViewComponent implements OnChanges {
  
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['productFees'] && changes['productFees'].currentValue) {
      let productFees: ProductFees = changes['productFees'].currentValue;
      let newFees = [];
      newFees.push({fee: 'Funding Fraction', value: productFees.fundingFraction})
      newFees.push({fee: 'Vol Spread', value: productFees.volSpread * 100})
      newFees.push({fee: 'Barrier Shift', value: productFees.barrierShift * 100})
      newFees.push({fee: 'Issuer Fee', value: productFees.issuerFees})
      newFees.push({fee: 'Issuer Fee Without Vega', value: productFees.issuerFeeExcludingVega})
      this.fees = newFees;
    }
  }

  @Input()
  productFees: ProductFees | undefined;

  fees: Fee[] = [];

  displayedColumns = ["fee", "value"];

}

export interface Fee {
  fee: string;
  value: number;
}