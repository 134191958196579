import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {MarketDataTimestamps} from "../model/MarketDataTimestamps";

@Injectable({
  providedIn: 'root'
})
export class MarketDataService {

  constructor(private http: HttpClient) { }

  public fetchUnderlyers() : Observable<NameAndId[]> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/underlying'
    return this.http.get<NameAndId[]>(url, { withCredentials: true })
  }

  public fetchInstitutions() : Observable<NameAndId[]> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/institution/filtered'
    return this.http.get<NameAndId[]>(url, { withCredentials: true })
  }

  public fetchMarketData(name: string): Observable<MarketDataTimestamps> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/underlying/marketdata/' + name;
    return this.http.get<MarketDataTimestamps>(url, { withCredentials: true })
  }

  public fetchIssuerMarketData(id: number): Observable<MarketDataTimestamps> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/institution/marketdata/' + id;
    return this.http.get<MarketDataTimestamps>(url, { withCredentials: true })
  }

}


export interface NameAndId {
  name: string,
  id: number,
  altName: string
}
