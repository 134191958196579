<div mat-dialog-title class="dialog-title">
    <h4 class="schedule-header"> LOAD PRODUCT</h4>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon class="white">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
  <div class="row">
    <div class="label-hover col-md-10">
      <mat-label class="label">CUSIP</mat-label>
      <mat-form-field class="col-md-12">
        <input matInput [(ngModel)]="cusip" (ngModelChange)="onFilterUpdate()"/>
      </mat-form-field>
    </div>
  </div>
    <div class="row">
        <div class="label-hover col-md-10">
            <mat-label class="label">PRODUCTS</mat-label>
            <mat-form-field class="col-md-12">
                <input matInput #productInput [matAutocomplete]="auto" [formControl]="productCtrl"/>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                @for (p of filteredProducts | async; track p) {
                    <mat-option [value]="p">{{p.name}}</mat-option>
                }
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
  <div class="row">
    <div class="label-hover col-md-10">
      <mat-label class="label">BRANCH CODE</mat-label>
      <mat-form-field class="col-md-12">
        <input matInput [(ngModel)]="branchCode" (change)="onFilterUpdate()"/>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="label-hover col-md-10">
      <mat-label class="label">ADVISOR INITIALS</mat-label>
      <mat-form-field class="col-md-12">
        <input matInput [(ngModel)]="advisor" (change)="onFilterUpdate()"/>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="label-hover col-md-10">
      <mat-label class="label">TRADE DATE</mat-label>
      <mat-form-field class="col-md-12">
        <input matInput [matDatepicker]="picker" [matDatepickerFilter]="holidayDateFilter" [(ngModel)]="tradeDate" (dateChange)="onFilterUpdate()">
        <!-- <mat-hint>DD/MM/YYYY</mat-hint> -->
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="label-hover col-md-10">
      <mat-label class="label">PORTFOLIO</mat-label>
      <mat-form-field class="col-md-12">
        <input matInput [(ngModel)]="portfolio" (change)="onFilterUpdate()"/>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="label-hover col-md-10">
      <mat-label class="label">USER NOTE</mat-label>
      <mat-form-field class="col-md-12">
        <input [readonly]="true" matInput [(ngModel)]="description"/>
      </mat-form-field>
    </div>
  </div>
</div>

<div mat-dialog-actions class="action-btns">
    <button mat-raised-button color="primary" [disabled]="!canLoadProduct" [mat-dialog-close]="closeData()">Load</button>
  <button mat-raised-button color="primary" (click)="clear()">Clear</button>
  <button mat-raised-button color="primary" [disabled]="!canLoadProduct" (click)="update()">Edit</button>
</div>
