<div *ngIf="canChangeCallType">
    <div class="col-md-12 put-type">
        <mat-chip-listbox (change)="callTypeChange($event)" aria-label="Change Call Type">
            <mat-chip-option value="0" [selected]="callType==0">Autocallable</mat-chip-option>
            <mat-chip-option value="1" [selected]="callType==1">Issuer Callable</mat-chip-option>
            <mat-chip-option value="2" [selected]="callType==2">Non-callable</mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>

<div class="row" *ngIf="callType == 0">
    <p class="chip-label col-md-2">CALL THRESHOLD</p>
    <mat-chip-listbox (change)="typeChanged($event)" aria-label="Call Type" class="col-md-10">
        <mat-chip-option class="threshold-option" value="0" [selected]="callabilityType==0">FIXED</mat-chip-option>
        <mat-chip-option class="threshold-option" value="1" [selected]="callabilityType==1">VARIED</mat-chip-option>
    </mat-chip-listbox>
</div>

<div class="row">
    <div class="col-md-4 label-hover" *ngIf="callType == 0 || callType == 1">
        <mat-label class="label">CALL FREQUENCY</mat-label>
        <mat-form-field class="col-md-12">
            <mat-select [value]="frequency" (selectionChange)="frequencyChange.emit($event.value)" (selectionChange)="frequencyUpdated($event)">
                @for (freq of frequencies; track freq) {
                    <mat-option [value]="freq.name">{{freq.name}}</mat-option>
                }
            </mat-select>
            <mat-icon matSuffix>expand_more</mat-icon>
        </mat-form-field>
    </div>
    <div class="col-md-4 label-hover"  *ngIf="callType == 0 || callType == 1">
        <mat-label class="label">NON-CALL PERIOD</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12">
            <input matInput [matAutocomplete]="auto" [(ngModel)]="autocallOptions.lockoutMonths" class="percent-input" (change)="lockoutUpdated($event)">
            <span matTextSuffix>M</span>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="lockoutSelected($event)">
                @for (option of lockoutOptions; track option) {
                <mat-option [value]="option">{{option}}</mat-option>
                }
            </mat-autocomplete>
        </mat-form-field>
    </div>
    <div class="col-md-4 label-hover" *ngIf="callType == 0">
        <mat-label class="label">INITIAL LEVEL</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="!solvingForBarrier">
            <input onclick="this.select()" matInput type="number" placeholder="" class="percent-input" [(ngModel)]="autocallOptions.barrier" (change)="inputChanged()">
            <span matTextSuffix>%</span>
        </mat-form-field>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="solvingForBarrier">
            <input matInput placeholder="SOLVING" disabled>
        </mat-form-field>
    </div>
</div>

<div class="row" *ngIf="callabilityType == 1">
    <div class="col-md-12 label-hover">
        <mat-label class="label">CHANGING BY</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12" >
            <input onclick="this.select()" matInput type="number" placeholder="0" class="percent-input" [(ngModel)]="autocallOptions.barrierChange" (change)="inputChanged()">
            <span matTextSuffix>%</span>
            <button mat-icon-button matSuffix (click)="toggleDirection()">
                <mat-icon>{{stepUp ? 'arrow_upward' : 'arrow_downward'}}</mat-icon>
            </button>
        </mat-form-field>
    </div>
</div>
