import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../static/footer/footer.component';

@Component({
  selector: 'app-terms',
  standalone: true,
  imports: [CommonModule, FooterComponent],
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss'
})
export class TermsComponent {

}
