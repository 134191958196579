import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { CognitoService } from '../services/cognito.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(CognitoService);
  const router = inject(Router);

  if(authService.checkLoggedIn()) {
    return true;
  } else {
    return router.createUrlTree(['/login']);
  }
};

