import { Component } from '@angular/core';
import { MessageService } from '../../services/message.service';
import { Router } from '@angular/router';
import { FooterComponent } from '../static/footer/footer.component';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent {

  constructor(private messageService: MessageService, private router: Router) {
    
  }

  email: string;
  name: string;
  message: string;

  messageSent: boolean = false;

  onContactUs() {
    this.messageService.send({
      name: this.name,
      email: this.email,
      message: this.message
    }).subscribe(
      {
        next: () => {
          console.log("Message Sent");
          this.messageSent = true;
        }
      }
    );
  }

  onTerms() {
    this.router.navigate(['/terms']);
  }

  onPolicy() {
    this.router.navigate(['/policy']);
  }

}
