<div mat-dialog-title class="dialog-title">
  <h4 class="schedule-header">SAVE PRODUCT</h4>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <mat-icon class="white">close</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <div class="row" *ngIf="!data.bulk">
    <div class="label-hover col-md-10">
      <mat-label class="{{this.getCUSIPLabelClass()}}">CUSIP</mat-label>
      <mat-form-field class="col-md-12">
        <input matInput [(ngModel)]="data.cusip"/>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="!data.bulk">
    <div class="label-hover col-md-10">
      <mat-label class="label">PRODUCT NAME</mat-label>
      <mat-form-field class="col-md-12">
        <input matInput [(ngModel)]="data.productName"/>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="label-hover col-md-10">
      <mat-label class="label">BRANCH CODE</mat-label>
      <mat-form-field class="col-md-12">
        <input matInput [(ngModel)]="data.advisorDetails.branchCode" (change)="branchCodeInput($event)"/>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="label-hover col-md-10">
      <mat-label class="label">ADVISOR INITIALS</mat-label>
      <mat-form-field class="col-md-12">
        <input matInput [(ngModel)]="data.advisorDetails.advisor"/>
      </mat-form-field>
    </div>
  </div>
  <div class="row" *ngIf="!data.bulk">
    <div class="label-hover col-md-10">
      <mat-label class="{{this.getTradeDateLabelClass()}}">TRADE DATE</mat-label>
      <mat-form-field class="col-md-12">
        <input matInput [matDatepicker]="picker" [matDatepickerFilter]="holidayDateFilter" [(ngModel)]="data.tradeDate">
        <!-- <mat-hint>DD/MM/YYYY</mat-hint> -->
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="label-hover col-md-10">
      <mat-label class="label">PORTFOLIO</mat-label>
      <mat-form-field class="col-md-12">
        <input matInput [(ngModel)]="data.advisorDetails.portfolio"/>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="label-hover col-md-10">
      <mat-label class="label">USER NOTE</mat-label>
      <mat-form-field class="col-md-12">
        <input matInput [(ngModel)]="data.advisorDetails.description"/>
      </mat-form-field>
    </div>
  </div>
</div>

<div mat-dialog-actions class="action-btns">
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-raised-button color="primary" mat-button [mat-dialog-close]="data" [disabled]="this.hasCusipNoTradeDate() || this.hasDuplicateCusips()" cdkFocusInitial>Save</button>
</div>
<div class="row cusip" *ngIf="hasCusipNoTradeDate()">
  CUSIP not allowed without TRADE DATE.
</div>
<div class="row cusip" *ngIf="hasDuplicateCusips()">
  That CUSIP is already in use.  Please load the product that has that CUSIP if you want to modify it.
</div>
