import {Component, OnInit} from '@angular/core';
import {Profile, ProfileService} from "../../services/profile.service";
import {FailedSecondaryAnalysisProduct, ProductService} from "../../pricing/services/product.service";
import {PageEvent} from "@angular/material/paginator";
import {AdminService} from "../../pricing/services/admin.service";

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrl: './admin-page.component.scss'
})
export class AdminPageComponent implements OnInit {

  userProfile: Profile = {
    name: 'Unknown',
    isAdmin: false
  };

  columnsToDisplay: ColumnDef[] = [
    {
      header: "PRODUCT ID",
      value: "productId",
      type: 'number'
    },
    {
      header: "JOB ID",
      value: "jobId",
      type: 'string'
    },
    {
      header: "ERROR MESSAGE",
      value: "error",
      type: 'string'
    },
    {
      header: "START",
      value: "start",
      type: 'date'
    },
    {
      header: "RERUN",
      value: "",
      type: "checkbox",
    }
  ];

  constructor(private profileService: ProfileService, private productService: ProductService, private adminService: AdminService) {
  }

  currentPage = 0;
  currentPageSize = 10;

  handlePageEvent(pageEvent: PageEvent) {
    this.currentPage = pageEvent.pageIndex;
    this.currentPageSize = pageEvent.pageSize;
    this.failedSecondaryAnalysisProductsToShow = this.failedSecondaryAnalysisProducts.slice(this.currentPage*this.currentPageSize, (this.currentPage+1)*this.currentPageSize)
  }

  columnNames: string[] = [];

  failedSecondaryAnalysisProducts: FailedSecondaryAnalysisProduct[] = [];
  failedSecondaryAnalysisProductsToShow: FailedSecondaryAnalysisProduct[] = [];

  ngOnInit() {
    this.profileService.getProfile().subscribe({
      next: (data: Profile) => {
        this.userProfile = data;
      },
      error: () => {
        console.error('Unable to fetch Profile');
      }
    });
    this.productService.getFailedSecondaryList().subscribe({
      next: (data: FailedSecondaryAnalysisProduct[]) => {
        this.failedSecondaryAnalysisProducts = data;
        this.failedSecondaryAnalysisProductsToShow = data.slice(this.currentPage*this.currentPageSize, (this.currentPage+1)*this.currentPageSize)
      },
      error: () => {
        console.error('Unable to fetch failed secondary analysis products.');
      }
    });
    for(const column of this.columnsToDisplay) {
      this.columnNames.push(column.header);
    }
  }

  selectAll() {
    for(let product of this.failedSecondaryAnalysisProducts) {
      product.isSelected = true;
    }
  }

  rerunSelected() {
    let idsToRun = this.failedSecondaryAnalysisProducts
      .filter(p=>p.isSelected)
      .map(p=>p.productId)
    this.productService.runSecondaryAnalysis(idsToRun).subscribe({
      next: data => {
        this.productService.getFailedSecondaryList().subscribe({
          next: (data: FailedSecondaryAnalysisProduct[]) => {
            this.failedSecondaryAnalysisProducts = data;
            this.failedSecondaryAnalysisProductsToShow = data.slice(this.currentPage*this.currentPageSize, (this.currentPage+1)*this.currentPageSize)
          },
          error: () => {
            console.error('Unable to fetch failed secondary analysis products.');
          }
        });
      }
    })
  }

  calendarSuccess = false;
  calendarRecipients = '';
  emailError = '';
  runCalendar() {
    if(this.calendarRecipients) {
      this.emailError = '';
      let recepients : string[] = this.calendarRecipients.split(",");
      this.adminService.runCalendar(recepients).subscribe(
        {
          next: data => {
            this.calendarSuccess = true;
            console.log("Started Calendar Run");
          },
          error: () => {
            this.calendarSuccess = false;
            console.log("Error Starting Calendar Run")
      }
        }
      );
    } else {
      this.emailError = "Missing Email Addresses"
    }
  }
  fileName: string;
  uploadResult: string;
  onFileSelected(event: any) {

    this.uploadResult = "";
    const file:File = event.target.files[0];

    if (file) {

      this.fileName = file.name;

      const formData = new FormData();

      formData.append('file', file);
      this.adminService.upload(formData).subscribe({
        next: data => {
          console.log("Success");
          this.uploadResult = "Upload Success";
        },
        error: () => {
          console.log("Error");
          this.uploadResult = "Upload Failed"
      }
      });
    }
  }

}

export interface ColumnDef {
  header: string;
  value: string;
  type: string;
}

