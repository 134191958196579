<div class="row">
    <div class="col-md-12 label-hover">
        <mat-label class="label">PROTECTION TYPE</mat-label>
        <mat-form-field class="col-md-12">
            <mat-select [(value)]="selectedProtection" (selectionChange)="typeChanged()">
                @for (p of protections; track p) {
                    <mat-option [value]="p.value">{{p.name}}</mat-option>
                }
            </mat-select>
            <mat-icon matSuffix>expand_more</mat-icon>
        </mat-form-field>
    </div>
</div>

<div class="row">
    <div class="observations chip-options" *ngIf="selectedProtection==0">
        <mat-chip-listbox (change)="observationChange($event)">
            @for (p of possibleObservations; track p) {
                <mat-chip-option [value]="p.value" [selected]="selectedObservation == p.value">{{p.name}}</mat-chip-option>
            }
        </mat-chip-listbox>
    </div>

    <div class="protectionTypes chip-options" *ngIf="selectedProtection==2">
        <mat-chip-listbox (change)="protectionChange($event)">
            @for (p of hundredProtectionTypes; track p) {
                <mat-chip-option [value]="p.value" [selected]="selectedHundredProtection == p.value">{{p.name}}</mat-chip-option>
            }
        </mat-chip-listbox>
    </div>
</div>
<div class="row" *ngIf="selectedProtection==0">
    <div class="col-md-6 label-hover">
        <mat-label class="label">PUT STRIKE</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12">
            <input matInput type="number" placeholder="100" class="percent-input" [(ngModel)]="strike" (change)="inputChanged()">
            <span matTextSuffix>%</span>
        </mat-form-field>
    </div>
    <div class="col-md-6 label-hover">
        <mat-label class="label">PUT BARRIER</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="!solvingForBarrier">
            <input matInput type="number" placeholder="100" class="percent-input" [(ngModel)]="barrier" (change)="inputChanged()">
            <span matTextSuffix>%</span>
        </mat-form-field>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="solvingForBarrier">
            <input matInput placeholder="SOLVING" disabled>
        </mat-form-field>
    </div>
</div>
<div class="row" *ngIf="selectedProtection==1">
    <div class="col-md-6 label-hover">
        <mat-label class="label">BUFFER</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="!solvingForBuffer">
            <input matInput type="number" class="percent-input" [(ngModel)]="buffer" (change)="inputChanged()">
            <span matTextSuffix>%</span>
        </mat-form-field>
        <mat-form-field floatLabel="always" class="col-md-12" *ngIf="solvingForBuffer">
            <input matInput placeholder="SOLVING" disabled>
        </mat-form-field>
    </div>
    <div class="col-md-6 put-type">
        <mat-chip-listbox aria-label="Gearing" (change)="gearingChange($event)">
            <mat-chip-option [value]="true" [selected]="gearingEnabled">GEARING</mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>
<div class="row" *ngIf="selectedProtection==3">
    <div class="col-md-4 label-hover">
        <mat-label class="label">UPPER STRIKE</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12">
            <input matInput type="number" placeholder="100" class="percent-input" [(ngModel)]="upperStrike" (change)="inputChanged()">
            <span matTextSuffix>%</span>
        </mat-form-field>
    </div>
    <div class="col-md-4 label-hover">
        <mat-label class="label">LOWER STRIKE</mat-label>
        <mat-form-field floatLabel="always" class="col-md-12">
            <input matInput type="number" placeholder="0" class="percent-input" [(ngModel)]="lowerStrike" (change)="inputChanged()">
            <span matTextSuffix>%</span>
        </mat-form-field>
    </div>
    <div class="col-md-4 put-type">
        <mat-chip-listbox aria-label="Gearing" (change)="gearingChange($event)">
            <mat-chip-option [value]="true" [selected]="gearingEnabled">GEARING</mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>
