<div class="container">

    <h1 class="heading">Privacy Policy</h1>
    <hr>

    <p>This Privacy Policy describes how Arrow Investment Analytics, LLC and its affiliates (collectively, “Arrow,” “we,” “us,” or “our”) collects, uses, shares, and protects personal information. This Privacy Policy also tells you about your rights and choices with respect to your personal information, and how you can reach us to get answers to your questions. </p>
    <h2>Information We Collect</h2>
    <p>We collect information about you in a variety of ways depending on how you interact with us and our websites and services, including:</p>
    <ul>
        <li>Directly from you when you provide it to us, such as when you register for an account, sign up to receive communications from us, engage Arrow to provide services, or contact us by phone, email, or otherwise. </li>
        <li>Automatically through the use of cookies, server logs, and other similar technologies when you interact with our websites, advertisements, and emails. </li>
        <li>From other sources, including, for example, our affiliates, business partners, service providers, online social media networks, and other third parties, or from publicly available sources. For example, if you submit a job application, or become an employee, we may conduct a background check. </li>
    </ul>
    <p>The following provides examples of the type of information that we collect in a variety of contexts and how we use that information. </p>
    <table class="policy-table">
        <tr>
            <td width="30%">Account Registration</td>
            <td>We collect your name and contact information when you create an account. We also collect information relating to the actions that you perform while logged into your account.</td>
        </tr>
        <tr>
            <td>Client Information </td>
            <td>We collect the name, and contact information, of our clients and their employees with whom we may interact. </td>
        </tr>
        <tr>
            <td>Cookies and First-Party Tracking</td>
            <td>We use cookies and clear GIFs. “Cookies” are small pieces of information that a website sends to a computer’s hard drive while a website is viewed. </td>
        </tr>
        <tr>
            <td>Cookies and Third-Party Tracking</td>
            <td>We may place tracking technology on our website that collects analytics, records how you interact with our website, or allows us to participate in behavior-based advertising. This means that a third party uses technology (e.g., a cookie) to collect information about your use of our website so that they can report analytics to us or provide advertising about products and services tailored to your interests. That third party might also collect information over time and across different websites in order to serve advertisements on our website or other websites. </td>
        </tr>
        <tr>
            <td>Demographic Information</td>
            <td>We collect personal information, such as your location. </td>
        </tr>
        <tr>
            <td>Distance Information</td>
            <td>When you use one of our Apps we collect your location from the GPS, Wi-Fi, and/or cellular technology in your device to determine your location. </td>
        </tr>
        <tr>
            <td>Email Interconnectivity</td>
            <td>If you receive email from us, we use certain tools to capture data related to when you open our message, click on any links or banners it contains and make purchases.</td>
        </tr>
        <tr>
            <td>Employment</td>
            <td>If you apply for a job posting, or become an employee, we collect information necessary to process your application or to retain you as an employee. This may include, among other things, your Social Security Number. Providing this information is required for employment.</td>
        </tr>
        <tr>
            <td>Feedback/Support</td>
            <td>If you provide us feedback or contact us for support we will collect your name and email address, as well as any other content that you send to us, in order to reply.</td>
        </tr>
        <tr>
            <td>Mailing List</td>
            <td>When you sign up for one of our mailing lists we collect your email address or postal address.  </td>
        </tr>
        <tr>
            <td>Partner Promotion</td>
            <td>We collect information that you provide as part of a co-branded promotion with another company.</td>
        </tr>
        <tr>
            <td>Public Health and Safety</td>
            <td>In certain situations where a serious public health threat has been identified, we may collect information from employees, guests, and other individuals accessing our facilities. This may include medical and health information, such as body temperature, symptoms, and underlying health conditions. In some cases, we may also collect medical, health, and related information about an employee’s children, family member, or other individuals under the employee’s care.</td>
        </tr>
        <tr>
            <td>Surveys</td>
            <td>When you participate in a survey, we collect information that you provide through the survey. If the survey is provided by a third party service provider, the third party’s privacy policy applies to the collection, use, and disclosure of your information.</td>
        </tr>
        <tr>
            <td>Website interactions</td>
            <td>We use technology to monitor how you interact with our website. This may include which links you click on, or information that you type into our online forms. This may also include information about your device or browser.</td>
        </tr>
        <tr>
            <td>Web logs</td>
            <td>We collect information, including your browser type, operating system, Internet Protocol (IP) address (a number that is automatically assigned to a computer when the internet is used), domain name, click-activity, referring website, and/or a date/time stamp for visitors.</td>
        </tr>
    </table>
    <h2>How We Use Information.</h2>
    <p>In addition to the purposes and uses described above, we use information in the following ways: </p>
    <ul>
        <li>To identify you when you visit our website. </li>
        <li>To provide services.</li>
        <li>To improve our services and product offerings.</li>
        <li>To conduct analytics.</li>
        <li>To communicate with you, such as to respond to and/or follow-up on your requests, inquiries, issues, or feedback. </li>
        <li>To send marketing and promotional materials including information relating to our products, services, sales, or promotions, or those of our business partners.</li>
        <li>To detect and protect against malicious, deceptive, fraudulent, or illegal activity, including violation of our policies and terms and conditions, security incidents, and harm to the rights, property, or safety of our company and our users, employees, or others.</li>
        <li>To debug, identify and repair errors that impair existing intended functionality of our website and services.</li>
        <li>To comply with our legal or regulatory obligations, to establish or exercise our rights, and to defend against a legal claim.</li>
        <li>For internal administrative purposes, as well as to manage our relationships.</li>
        <li>For such other purposes as you may consent (from time to time).</li>
    </ul>
    <p>To the extent we maintain and use personal information in a deidentified form, we will not attempt to reidentify the information, except for the purpose of determining whether our deidentification processes satisfy our legal obligations.</p>
    <h2>How We Share Information.</h2>
    <p>In addition to the specific situations discussed elsewhere in this Privacy Policy, we may disclose personal information in the following situations:</p>
    <ul>
        <li><b>Affiliates and Acquisitions.</b> We may share information with our corporate affiliates (e.g., parent company, sister companies, subsidiaries, joint ventures, or other companies under common control). If another company acquires, or plans to acquire, our company, business, or our assets, we will also share information with that company, including at the negotiation stage. </li>
        <li><b>Other Disclosures without Your Consent.</b> We may disclose information in response to subpoenas, warrants, or court orders, or in connection with any legal process, or to comply with relevant laws. We may also share your information in order to establish or exercise our rights, to defend against a legal claim, to investigate, prevent, or take action regarding possible illegal activities, suspected fraud, safety of person or property, or a violation of our policies, or to comply with your request for the provision of services by a third-party intermediary.</li>
        <li><b>Public.</b> Some of our websites may provide the opportunity to post comments, or reviews, in a public forum. If you decide to submit information on these pages, that information may be publicly available. </li>
        <li><b>Service Providers.</b> We share your information with service providers. Among other things service providers help us to administer our website, conduct surveys, provide technical support, process payments, and assist in the fulfillment of orders.</li>
        <li><b>Partners.</b> We share your information with partners as part of a co-branded promotion with another company.</li>
        <li><b>Other Disclosures with Your Consent.</b> We may disclose your information to other third parties when we have your consent or direction to do so. </li>
    </ul>
    <h2>Your Choices</h2>
    <ul>
        <li><b>Changes To Your Personal Information.</b> If you would like to request that we update or correct certain information, you can contact us at the address described below in order to request that your information be modified. You may ask us to corrct information that is inaccurate or incomplete. Note that we may keep historical information in our backup files as permitted by law.  </li>
        <li><b>Online Tracking.</b> We do not currently recognize the “Do Not Track” signal. </li>
        <li><b>Promotional Emails.</b> You may choose to provide us with your email address for the purpose of allowing us to send free newsletters, surveys, offers, and other promotional materials to you, as well as targeted offers from third parties. You can stop receiving promotional emails by following the unsubscribe instructions in emails that you receive. If you decide not to receive promotional emails, we may still send you service related communications. </li>
    </ul>
    <p>You may exercise the rights described above by contacting us as indicated in the “Contact Information” section below. </p>
    <h2>How We Protect and Retain Information</h2>
    <p>No method of transmission over the internet, or method of electronic storage, is fully secure. While we use reasonable efforts to protect your personal information from unauthorized access, use, or disclosure, we cannot guarantee the security of your personal information. In the event that we are required by law to inform you of a breach to your personal information we may notify you electronically, in writing, or by telephone, if permitted to do so by law.</p>
    <p>Some of our websites permit you to create an account. When you do you will be prompted to create a password. You are responsible for maintaining the confidentiality of your password, and you are responsible for any access to or use of your account by someone else that has obtained your password, whether or not such access or use has been authorized by you. You should notify us immediately of any unauthorized use of your password or account.</p>
    <p>We retain your personal information for only as long as necessary to fulfil the purposes outlined in this Privacy Policy, including for the purposes of satisfying any legal, accounting, or reporting requirements, unless a longer retention period is required or permitted by law. To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the information, the potential risk of harm from unauthorized use or disclosure of the information, the purposes for which we obtained the information and whether we can achieve those purposes through other means, as well as applicable legal requirements.</p>
    <h2>Third-Party Applications/Websites</h2>
    <p>For your convenience, we may provide links to websites and other third-party content or services that we do not own or operate. The websites and third-party content to which we link may have separate privacy notices or policies. Please note, we have no control over the privacy practices, websites, or services that we do not own. We encourage you to review the privacy policies of any third-party website or application for details about such third party’s privacy practices.</p>
    <h2>Changes To This Privacy Policy</h2>
    <p>We may change our privacy policy and practices over time. To the extent that our policy changes in a material way, the policy that was in place at the time that you submitted personal information to us will generally govern that information unless we receive your consent to the new privacy policy. Our privacy policy includes an “effective” and “last updated” date. The effective date refers to the date that the current version took effect. The last updated date refers to the date that the current version was last substantively modified. If we make a material change to our privacy notice, we will attempt to notify you through email or through a website pop-up.</p>
    <h2>Contact Information</h2>
    <p>If you have any questions, comments, or complaints concerning our privacy practices, or if you need to access this Privacy Policy in an alternative format due to having a disability, please contact us at info&#64;arrow-ia.com. We will attempt to respond to your requests and to provide you with additional privacy-related information. </p>
    <p>Effective Date: March 11, 2024</p>
    <p>Last Update: March 11, 2024</p>
</div>
<app-footer></app-footer>
