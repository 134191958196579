import { NgModule, importProvidersFrom } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PricingComponent } from './pages/pricing/pricing.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatListModule } from '@angular/material/list';
import { DigitalInputsComponent } from './pricing/components/digital-inputs/digital-inputs.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { TermAndFrequencyComponent } from './pricing/components/term-and-frequency/term-and-frequency.component';
import { AutocallTriggerInputsComponent } from './pricing/components/autocall-trigger-inputs/autocall-trigger-inputs.component';
import { CouponInputsComponent } from './pricing/components/coupon-inputs/coupon-inputs.component';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import {AsyncPipe, DatePipe} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import { BarrierTableComponent } from './pricing/components/barrier-table/barrier-table.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { PrincipleExposureComponent } from './pricing/components/principle-exposure/principle-exposure.component';
import { CallPremiumInputsComponent } from './pricing/components/call-premium-inputs/call-premium-inputs.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {MatTooltipModule} from '@angular/material/tooltip';
import { PricingQueueComponent } from './pricing/components/pricing-queue/pricing-queue.component';
import { AnalysisViewComponent } from './pricing/components/analysis-view/analysis-view.component';
import { MarketParticipationComponent } from './pricing/components/market-participation/market-participation.component';
import { GrowthInputsComponent } from './pricing/components/growth-inputs/growth-inputs.component';
import { GrowthNoteInputsComponent } from './pricing/components/growth-note-inputs/growth-note-inputs.component';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogTitle } from '@angular/material/dialog';
import { SaveProductDialogComponent } from './pricing/components/dialogs/save-product-dialog/save-product-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatCardModule} from '@angular/material/card';
import { ScheduleDialogComponent } from './pricing/components/dialogs/schedule-dialog/schedule-dialog.component';
import { OptionBoxComponent } from './dashboard/option-box/option-box.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UnderlyingInputComponent } from './pricing/components/dialogs/underlying-input/underlying-input.component';
import { LoadDialogComponent } from './pricing/components/dialogs/load-dialog/load-dialog.component';
import { FeesViewComponent } from './pricing/components/fees-view/fees-view.component';
import { LoginComponent } from './pages/login/login.component';
import { AmplifyAuthenticatorModule, AuthenticatorService} from '@aws-amplify/ui-angular';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { SwitcherComponent } from './pages/switcher/switcher.component';
import { LandingComponent } from './pages/landing/landing.component';
import { FooterComponent } from './pages/static/footer/footer.component';
import {CdkMenuModule} from '@angular/cdk/menu';
import {MatCheckboxModule} from "@angular/material/checkbox";
import { AdminComponent } from './pricing/components/admin/admin.component';
import { AdvisorAnalyticsComponent } from './pricing/components/dialogs/advisor-analytics/advisor-analytics.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import {MatPaginatorModule} from "@angular/material/paginator";


@NgModule({
  declarations: [
    AppComponent,
    PricingComponent,
    DigitalInputsComponent,
    TermAndFrequencyComponent,
    AutocallTriggerInputsComponent,
    CouponInputsComponent,
    BarrierTableComponent,
    PrincipleExposureComponent,
    CallPremiumInputsComponent,
    PricingQueueComponent,
    AnalysisViewComponent,
    MarketParticipationComponent,
    GrowthInputsComponent,
    GrowthNoteInputsComponent,
    SaveProductDialogComponent,
    ScheduleDialogComponent,
    OptionBoxComponent,
    DashboardComponent,
    UnderlyingInputComponent,
    LoadDialogComponent,
    FeesViewComponent,
    LoginComponent,
    SwitcherComponent,
    LandingComponent,
    AdminComponent,
    AdvisorAnalyticsComponent,
    AdminPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatMomentDateModule,
    MatListModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatIconModule,
    ReactiveFormsModule,
    MatTableModule,
    MatButtonModule,
    MatExpansionModule,
    MatTooltipModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    MatDialogModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatCardModule,
    AmplifyAuthenticatorModule,
    FooterComponent,
    CdkMenuModule,
    MatCheckboxModule
  ],
  providers: [
    importProvidersFrom(HttpClientModule),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
