import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatChipListboxChange } from '@angular/material/chips';
import { MarketParticipationOptions } from '../../model/MarketParticipationOptions';
import { PricingOptionsService } from '../../services/pricing-options.service';

@Component({
  selector: 'app-growth-inputs',
  templateUrl: './growth-inputs.component.html',
  styleUrl: './growth-inputs.component.scss'
})
export class GrowthInputsComponent implements OnChanges {

  @Input()
  defaultDownwardCap: number = 1;

  @Input()
  isUpward: boolean;

  @Input()
  marketParticipation: MarketParticipationOptions;

  @Output()
  optionsUpdated = new EventEmitter<MarketParticipationOptions>();

  hasUpsideAtMaturity = false;
  isCapped = false;

  payoffStrike = 100;
  participation = 100;
  maxReturn = '100';
  cap = this.defaultDownwardCap * 100;

  solvingForCap: boolean = false;
  solvingForParticipation: boolean = false;
  solvingForAbove: boolean = false;

  constructor(private pricingOptions: PricingOptionsService) {
    this.checkSolving(pricingOptions.currentlySolvingFor)
    pricingOptions.solvingForChange.subscribe(
      id => {
        this.checkSolving(id);
      }
    )
  }

  checkSolving(id: Number)   {
    if(this.isUpward) {
      this.solvingForCap = (id == 3);
      this.solvingForAbove = (id == 10);
      this.solvingForParticipation = (id == 5);
    } else {
      this.solvingForParticipation = (id == 6);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['marketParticipation']) {
      let change = changes['marketParticipation'].currentValue;
      if(change) {
        this.hasUpsideAtMaturity = true;
        this.payoffStrike = Number((change.payoffStrike * 100).toFixed(3));
        this.participation = Number((change.participation * 100).toFixed(3));
        if(change.cap != undefined) {
          this.isCapped = true;
          this.cap = Number((change.cap * 100).toFixed(3));
        } else {
          this.isCapped = false;
        }
      } else {
        this.hasUpsideAtMaturity = false;
      }
    }
    if(changes['defaultDownwardCap']) {
      console.log(changes);
      let change = changes['defaultDownwardCap'].currentValue;
      if(change != undefined) {
        this.cap = Number((change * 100).toFixed(3));
        this.calculateMaxReturn();
      }
    }
  }

  inputChanged() {
    this.calculateMaxReturn();
    this.optionsUpdated.emit(this.createMarketParticipationOptions())

  }

  createMarketParticipationOptions() {

    if(!this.hasUpsideAtMaturity) {
      return undefined;
    }

    let marketParticipation: MarketParticipationOptions = {
      payoffType: this.isUpward ? 'Call' : 'Put',
      payoffStrike: this.payoffStrike / 100,
      participation: this.participation / 100,
    }

    if(this.isCapped) {
      marketParticipation.cap = this.cap / 100;
    }

    return marketParticipation;
  }

  uamChanged(e: MatChipListboxChange) {
    if(e.value) {
      this.hasUpsideAtMaturity = true;
    } else {
      this.hasUpsideAtMaturity = false;
    }
    this.inputChanged();
  }

  cappedChanged(e: MatChipListboxChange) {
    if(e.value) {
      this.isCapped = true;
    } else {
      this.isCapped = false;
    }
    this.inputChanged();
  }

  calculateMaxReturn() {
    if(this.participation) {
      this.maxReturn = (((this.participation/100) * (this.cap/100)) * 100).toFixed(2);
    }
  }

}
