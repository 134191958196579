<mat-card [ngClass]="getCardClass()" (click)="cardClicked()">
    <mat-card-content class="content">
        <div class="icon">
            <img src="{{iconPath}}">
        </div>
    </mat-card-content>
    <mat-card-footer class="footer">
        <h5>{{name}}</h5>
        <span>
            <div class="text-container">
                <p>{{description}}</p>
                <mat-icon>chevron_right</mat-icon>
            </div>
        </span>
    </mat-card-footer>
</mat-card>
