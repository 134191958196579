import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, filter, switchMap, take, timer } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SolvingService {

  constructor(private http: HttpClient) { }

  private getResult(uuid: string): Observable<SolveResult> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/solve/async/' + uuid;
    return this.http.get<SolveResult>(url);
  }

  public solveProductAsync(productId: number, price: number, solveFor: string, commitResult: boolean, asOf: Date | undefined, useLambda: boolean | undefined): Observable<SolveResult> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/solve/async'
    let request: SolveRequest = {
      productId: productId,
      price: price,
      solveFor: solveFor,
      commitResult: commitResult,
      asOfDate: asOf,
      useLambda: useLambda
    }

    //Probably need to check on error conditions
    return this.http.post<string>(url, request, { withCredentials: true }).pipe(
      switchMap(
        uuid => timer(5000, 4000).pipe(
          switchMap(() => this.getResult(uuid)),filter(data => (data != null)),take(1)
      ))
    )
  }
}

export interface SolveRequest {
  productId: number;
  price: number;
  solveFor: string;
  commitResult?: boolean;
  asOfDate: Date | undefined;
  useLambda: boolean | undefined;
}

export interface SolveResult {
  value: number;
  name: string
  error: string;
  fair: number;
  impliedIssuerPnL: number;
  barrierShiftUsed: number;
}
