import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatChipListboxChange } from '@angular/material/chips';
import { MatSelectChange } from '@angular/material/select';
import { PutOptions } from '../../model/PutOptions';
import { PricingOptionsService } from '../../services/pricing-options.service';

@Component({
  selector: 'app-principle-exposure',
  templateUrl: './principle-exposure.component.html',
  styleUrl: './principle-exposure.component.scss'
})
export class PrincipleExposureComponent implements OnChanges {


  @Input()
  putDetails: PutOptions;
  @Output()
  principleExposureChanged = new EventEmitter<PutOptions>();

  @Output()
  somethingChanged = new EventEmitter();

  solvingForBarrier = false;
  solvingForBuffer = false;

  constructor(private pricingOptions: PricingOptionsService) {
    this.checkForSolving(pricingOptions.currentlySolvingFor);
    pricingOptions.solvingForChange.subscribe(
      id => {
        this.checkForSolving(id);
      }
    )
  }

  checkForSolving(id : Number) {
    this.solvingForBarrier = false;
    this.solvingForBuffer = false;
    if(id == 2) {
      this.solvingForBarrier = true;
    }
    if(id == 7) {
      this.solvingForBuffer = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['putDetails']) {
      let change: PutOptions = changes['putDetails'].currentValue;
      if(!change) {
        //No Put Details Specified Default to None
        this.selectedProtection = 4;
      } else {
        if(!(change.type == undefined)) {
          this.selectedProtection = change.type;
          this.strike = change.payoffStrike != undefined ? Number((change.payoffStrike * 100).toFixed(3)) : 100;
          this.barrier = change.barrier != undefined ? Number((change.barrier * 100).toFixed(3)) : 100;
          this.selectedObservation = change.barrierType != undefined ? change.barrierType : 'Date';
          this.setBuffer(change);
          this.setUpperLowerStrike(change);
          this.depositInsured = change.depositInsured!;
          this.gearingEnabled = change.participation != -1;
        }
      }
    }
  }

  setBuffer(options: PutOptions) {
    if(options.type == 1) {
      this.buffer = Number(((1 - options.payoffStrike!) * 100).toFixed(3));
    } else {
      this.buffer = 0;
    }
  }

  setUpperLowerStrike(options: PutOptions) {
    if(options.type == 3) {
      this.upperStrike = options.barrier! * 100;
      this.lowerStrike = Number(((options.barrier! - options.cap!) * 100).toFixed(3));
    } else {
      this.upperStrike = 100;
      this.lowerStrike = 0;
    }
  }

  setProtectionInsured(options: PutOptions) {
    this.depositInsured = options.depositInsured!;
  }

  selectedProtection = 4;
  protections = [
    {name: 'Contingent Protection', value: 0},
    {name: 'Fixed Protection', value: 1},
    {name: '100% Protection', value: 2},
    {name: 'Fixed Protection with Floor', value: 3},
    {name: 'No Protection', value: 4}
  ];

  strike: number = 100;
  barrier: number = 100;
  buffer: number = 0;
  upperStrike:number = 100;
  lowerStrike:number = 0;

  selectedObservation : string = 'Date';
  possibleObservations = [
    {name: 'OBSERVED AT MATURITY', value: 'Date'},
    {name: 'OBSERVED DAILY', value: '' },
    {name: 'OBSERVED CONTINUOUSLY', value: 'Continuous'}
  ]

  observationChange(e:MatChipListboxChange) {
    this.selectedObservation = e.value;
    this.inputChanged();
  }

  selectedHundredProtection = 0;
  hundredProtectionTypes = [
    {name: 'NOTE', value: 0},
    {name: 'MARKET LINKED CD', value: 1}
  ]

  depositInsured: boolean = false;
  protectionChange(e:MatChipListboxChange) {
    this.selectedHundredProtection = e.value;
    if(this.selectedHundredProtection == 0) {
      this.depositInsured = false;
    } else {
      this.depositInsured = true;
    }
    this.inputChanged();
  }

  gearingEnabled : boolean = false;
  gearingChange(e:MatChipListboxChange) {
    if(e.value) {
      this.gearingEnabled = true;
    } else {
      this.gearingEnabled = false;
    }
    this.inputChanged();
  }

  typeChanged() {
    this.strike = 100;
    this.barrier = 100;
    this.buffer = 0;
    this.upperStrike = 100;
    this.lowerStrike = 0;
    this.inputChanged();
  }
  inputChanged() {
    this.principleExposureChanged.emit(this.createPutOptions());
  }

  createPutOptions(): PutOptions {
    switch(this.selectedProtection) {
      case 4:
        return this.createNoProtection();
      case 3:
        return this.createFixedWithFloorProtection();
      case 2:
        return this.create100PercentProtection();
      case 1:
        return this.createFixedProtection();
      case 0:
        return this.createContingentProtection();
      default:
        return {};
    }

  }

  create100PercentProtection() : PutOptions {
    return {
      isDisabled: true,
      depositInsured: this.depositInsured,
      type: this.selectedProtection
    }
  }

  createNoProtection(): PutOptions {
    return {
      strike: 1,
      barrier: 1,
      participation: -1,
      payoffStrike: 1,
      cap: 1,
      payoffType: 'Put',
      barrierType: this.possibleObservations[0].value, //Date
      depositInsured: false,
      isDisabled: false,
      type: this.selectedProtection
    }
  }

  createContingentProtection(): PutOptions {
    return {
      strike: 1,
      barrier: this.barrier / 100,
      participation: -1,
      payoffStrike: this.strike / 100,
      cap: 1,
      payoffType: 'Autocall Put',
      barrierType: this.selectedObservation,
      depositInsured: false,
      isDisabled: false,
      type: this.selectedProtection
    }
  }

  createFixedProtection(): PutOptions {
    let payoffStrike = (100 - this.buffer) / 100;
    return {
      strike: 1,
      barrier: payoffStrike,
      participation: this.calculateParticipation(payoffStrike),
      payoffStrike: payoffStrike,
      cap: payoffStrike,
      payoffType: 'Put',
      barrierType: this.possibleObservations[0].value, //Date
      depositInsured: false,
      isDisabled: false,
      type: this.selectedProtection
    }
  }

  createFixedWithFloorProtection(): PutOptions {
    let upperStrike = this.upperStrike / 100;
    let lowerStrike = this.lowerStrike / 100;
    return {
      strike: 1,
      barrier: upperStrike,
      participation: this.calculateParticipation(upperStrike - lowerStrike),
      payoffStrike: upperStrike,
      cap: upperStrike - lowerStrike,
      payoffType: 'Put',
      barrierType: this.possibleObservations[0].value, //Date
      depositInsured: false,
      isDisabled: false,
      type: this.selectedProtection
    }
  }

  calculateParticipation(payoffStrike: number): number {
    return this.gearingEnabled ? -1/payoffStrike : -1;
  }

  static NO_PROTECTION: PutOptions = {
    strike: 1,
    barrier: 1,
    participation: -1,
    payoffStrike: 1,
    cap: 1,
    payoffType: 'Put',
    barrierType: 'Date',
    depositInsured: false,
    isDisabled: false,
    type: 4
  }

}
