import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-option-box',
  templateUrl: './option-box.component.html',
  styleUrl: './option-box.component.scss'
})
export class OptionBoxComponent {

  constructor(private router:Router) {
    
  }

  @Input()
  name: string;

  @Input()
  path: string;

  @Input()
  description: string;

  @Input()
  iconPath: string;

  cardClicked() {
    this.router.navigate([this.path]);
  }

  getCardClass() {
    return ["option-card", "option-card-"+this.name.toLocaleLowerCase()];
  }

}
