import { Injectable } from '@angular/core';
import {Environment} from "@angular/cli/lib/config/workspace-schema";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LocalstorageService {

  private SESSION_TOKEN_KEY: string = 'SESSION_TOKEN';

  constructor() { }

  public setSessionToken(token: SessionToken) {
    localStorage.setItem(this.SESSION_TOKEN_KEY, JSON.stringify(token))
    let d: Date = new Date();
    d.setTime(d.getTime() + (24 * 60 * 60 * 1000)); // 1 day expiry
    document.cookie = 'arrowia-auth-token' + '=' + token.tokenString + ';' + 'expires=' + d.toUTCString() + ';' + 'domain=.'+ environment.cookieDomain +';' + 'secure';
  }

  public getSessionToken() : SessionToken | null {
    let token = localStorage.getItem(this.SESSION_TOKEN_KEY)
    if(token) {
      return JSON.parse(token);
    } else {
      return null;
    }
  }

  public removeSessionToken() {
    localStorage.removeItem(this.SESSION_TOKEN_KEY);
  }
}


export interface SessionToken {
  tokenString: string;
  sub: string;
  username?: string;
  email?: string;
  expiry: Date;
}
