<div class="table-container">
    <table mat-table [dataSource]="barriers" multiTemplateDataRows class="mat-elevation-z0">
        @for (col of columnsToDisplay; track col) {
            <ng-container matColumnDef="{{col.header}}">
                <th mat-header-cell *matHeaderCellDef class="table-header"> {{col.header}} </th>
                <td mat-cell *matCellDef="let element; let idx = dataIndex">
                    <div [ngSwitch]="col.type">
                        <span *ngSwitchCase="'date-no-edit'" [ngClass]="getClass(element, col.value)">
                            {{ element[col.value] | date:'MM/dd/yy'}}
                        </span>
                        <mat-form-field *ngSwitchCase="'percent'" class="call-threshold">
                            <input type="number" matInput [(ngModel)]="element[col.value]" class="percent-input" [disabled]="element.disable" (change)="barrierManuallyUpdated.emit()"/>
                            <span matTextSuffix>%</span>
                        </mat-form-field>
                        <span *ngSwitchCase="'percent-no-edit'" [ngClass]="getClass(element, col.value)">
                            {{ element[col.value] }} {{ (element[col.value]) ? '%' : ''}}
                        </span>
                        <span *ngSwitchCase="'number'" [ngClass]="getClass(element, col.value)">
                            {{ element[col.value] | number:'1.0-2'}}
                        </span>
                        <!-- <span *ngSwitchCase="'disable'">
                            <mat-chip-listbox aria-label="Disable">
                                <mat-chip-option>Disable</mat-chip-option>
                            </mat-chip-listbox>
                        </span> -->
                        <span *ngSwitchCase="'options'">
                            <div class="options-container">
                                <button mat-icon-button aria-label="Disable" color="primary" matTooltip="Disable" *ngIf="!element.disable" (click)="disable(element)">
                                    <mat-icon  class="enabled-eye">visibility</mat-icon>
                                </button>
                                <button mat-icon-button aria-label="Enable" color="primary" matTooltip="Enable" *ngIf="element.disable" (click)="enable(element)">
                                    <mat-icon class="disabled-eye">visibility_off</mat-icon>
                                </button>
                                <!-- <button mat-icon-button aria-label="Copy" color="primary" matTooltip="Copy" [disabled]="element.disable" (click)="copyBarrier(element, idx)">
                                    <mat-icon>content_copy</mat-icon>
                                </button> -->
                            </div>
                        </span>
                        <span *ngSwitchDefault>
                        {{ element[col.value] }}
                        </span>
                    </div>
                </td>
            </ng-container>
        }

            <!-- <ng-container matColumnDef="expand">
            <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
            <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                @if (expandedElement === element) {
                    <mat-icon>keyboard_arrow_up</mat-icon>
                } @else {
                    <mat-icon>keyboard_arrow_down</mat-icon>
                }
            </button>
            </td>
        </ng-container> -->

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <!-- <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length">
                <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="example-element-diagram">
                    <div class="example-element-position"> {{element.position}} </div>
                    <div class="example-element-symbol"> {{element.symbol}} </div>
                    <div class="example-element-name"> {{element.name}} </div>
                    <div class="example-element-weight"> {{element.weight}} </div>
                    </div>
                    <div class="example-element-description">
                        {{element.description}}
                        <span class="example-element-description-attribution"> -- Wikipedia </span>
                    </div>
                </div>
            </td>
        </ng-container> -->

        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
        <tr mat-row *matRowDef="let element; columns: columnNames;" class="example-element-row table-row" [class.example-expanded-row]="expandedElement === element"></tr>
        <!-- <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr> -->
    </table>

<!-- <pre>{{barriers | json}}</pre> -->
</div>
