import { ALLOWED_SPECIAL_CHARACTERS, emailRegex } from './constants.mjs';

// replaces all characters in a string with '*', except for the first and last char
const censorAllButFirstAndLast = value => {
  const split = value.trim().split('');
  for (let i = 0; i < split.length; i++) {
    if (i > 0 && i < split.length - 1) {
      split[i] = '*';
    }
  }
  return split.join('');
};
// censors all but the last four characters of a phone number
const censorPhoneNumber = val => {
  if (val.length < 4) {
    return val;
  }
  const split = val.split('');
  for (let i = 0; i < split.length - 4; i++) {
    split[i] = '*';
  }
  return split.join('');
};
const hasSpecialChars = password => ALLOWED_SPECIAL_CHARACTERS.some(char => password.includes(char));
const getTotpCodeURL = (issuer, username, secret) => encodeURI(`otpauth://totp/${issuer}:${username}?secret=${secret}&issuer=${issuer}`);
function trimValues(values, ...ignored) {
  return Object.entries(values).reduce((acc, [name, value]) => ({
    ...acc,
    [name]: ignored.includes(name) ? value : value?.trim()
  }), {});
}
const isValidEmail = value => {
  if (!value) return false;
  return emailRegex.test(value);
};
export { censorAllButFirstAndLast, censorPhoneNumber, getTotpCodeURL, hasSpecialChars, isValidEmail, trimValues };