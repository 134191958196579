import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-option-box',
  templateUrl: './option-box.component.html',
  styleUrl: './option-box.component.scss'
})
export class OptionBoxComponent {

  constructor(private router:Router) {

  }

  @Input()
  name: string;

  @Input()
  path: string;

  @Input()
  description: string;

  @Input()
  iconPath: string;

  @Input()
  isV2: boolean

  cardClicked() {
    if(this.isV2) {
      window.location.replace(this.path)
    } else {
      this.router.navigate([this.path]);
    }
  }

  getCardClass() {
    return ["option-card", "option-card-"+this.name.toLocaleLowerCase()];
  }

}
