import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { Observable, startWith, map } from 'rxjs';
import { ProductSearchItem, ProductService } from '../../../services/product.service';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {DateAdapter, MAT_DATE_LOCALE} from "@angular/material/core";
import {DateFilterFn} from "@angular/material/datepicker";
import moment from "moment";
import {PricingItem} from "../../../model/PricingItem";
import {AdvisorDetails} from "../../../model/AdvisorDetails";
import {SaveProductDialogComponent} from "../save-product-dialog/save-product-dialog.component";

@Component({
  selector: 'app-load-dialog',
  templateUrl: './load-dialog.component.html',
  styleUrl: './load-dialog.component.scss',
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
  ],
})
export class LoadDialogComponent implements OnInit {

  @ViewChild('productInput')
  underlyingInput!: ElementRef<HTMLInputElement>;

  holidayDateFilter: DateFilterFn<moment.Moment | null> = (d: moment.Moment | null): boolean => {
    return d?.day() != 0 && d?.day() != 6;
  }

  productCtrl = new FormControl('');

  filteredProducts: Observable<ProductSearchItem[]>;

  fullProductList: ProductSearchItem[] = [];

  selectedProduct: ProductSearchItem | undefined;

  branchCode: string;
  advisor: string;
  portfolio: string;
  description: string;
  tradeDate?: Date;
  cusip: string;

  canLoadProduct = false;

  constructor(
    public dialogRef: MatDialogRef<LoadDialogComponent>,
    private productService: ProductService,
    public dialog: MatDialog
  ) {
    dialogRef.disableClose = true;
    this.filteredProducts = this.productCtrl.valueChanges.pipe(
      startWith(null),
      map(
        (product) => (product ? this._filter(product) :
          this.fullProductList.slice()
            .filter(p => this.matchesCusip(p))
            .filter(p => this.matchesBranchCode(p))
            .filter(p => this.matchesAdvisoryCode(p))
            .filter(p => this.matchesTradeDate(p))
            .filter(p => this.matchesPortfolioCode(p)))
      )
    )
    this.filteredProducts.subscribe({
      next: (products: ProductSearchItem[]) => {
        if(products.length == 1 && !this.selectedProduct) {
          this.updateForm(products[0]);
        }
      }
    })
  }

  ngOnInit(): void {
    this.productService.loadProductList().subscribe({
      next: (data) => {
        this.fullProductList = data;
        this.productCtrl.setValue("");
      },
      error: (e) => {
        console.log(e);
      }
    })
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  private _filter(value: any): ProductSearchItem[] {
    const filterValue = (typeof value === "string") ? value.toLowerCase() : value.name.toLowerCase();
    const products = this.fullProductList
      .filter(p => this.matchesCusip(p))
      .filter(p => this.matchesBranchCode(p))
      .filter(p => this.matchesAdvisoryCode(p))
      .filter(p => this.matchesPortfolioCode(p))
      .filter(p => this.matchesTradeDate(p))
      .filter(p => p.name.toLowerCase().includes(filterValue));
    return products;
  }

  private matchesBranchCode(product: ProductSearchItem): boolean {
    if(this.branchCode) {
      if(product.advisorDetails?.branchCode) {
        return product.advisorDetails.branchCode.toLowerCase().includes(this.branchCode.toLowerCase());
      }
      return false;
    }
    return true;
  }

  private matchesTradeDate(product: ProductSearchItem): boolean {
    if(this.tradeDate) {
      if(product.tradeDate) {
        return Date.parse(product.tradeDate.toString())===Date.parse(this.tradeDate.toString());
      }
      return false;
    }
    return true;
  }

  private matchesCusip(product: ProductSearchItem): boolean {
    if(this.cusip) {
      if(product.cusip) {
        return product.cusip.toLowerCase().includes(this.cusip.toLowerCase());
      }
      return false;
    }
    return true;
  }

  private matchesPortfolioCode(product: ProductSearchItem): boolean {
    if(this.portfolio) {
      if(product.advisorDetails?.portfolio) {
        return product.advisorDetails.portfolio.toLowerCase().includes(this.portfolio.toLowerCase());
      }
      return false;
    }
    return true;
  }

  private matchesAdvisoryCode(product: ProductSearchItem): boolean {

    if(this.advisor) {
      if(product.advisorDetails?.advisor) {
        return product.advisorDetails.advisor.toLowerCase().includes(this.advisor.toLowerCase());
      }
      return false;
    }
    return true;
  }

  onFilterUpdate() {
    console.log("Filter Changed")
    this.productCtrl.setValue("");
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.updateForm(event.option.value);
  }

  updateForm(product: ProductSearchItem) {
    this.selectedProduct = product;
    this.productCtrl.setValue(this.selectedProduct.name);
    this.advisor = this.selectedProduct.advisorDetails.advisor;
    this.portfolio = this.selectedProduct.advisorDetails.portfolio;
    this.description = this.selectedProduct.advisorDetails.description;
    this.branchCode = this.selectedProduct.advisorDetails.branchCode;
    this.cusip = this.selectedProduct.cusip;
    this.tradeDate = this.selectedProduct.tradeDate;
    this.canLoadProduct = true;
  }

  update() {
    let defaultAdvisorDetails: AdvisorDetails = {
      branchCode: '',
      advisor: '',
      description: '',
      portfolio: ''
    };

    if(this.selectedProduct?.advisorDetails) {
      defaultAdvisorDetails = this.selectedProduct.advisorDetails;
    }

    // this.dialogRef.close();

    const dialogSaveRef = this.dialog.open(SaveProductDialogComponent, {

      data: {
        productName: this.selectedProduct?.name,
        advisorDetails: defaultAdvisorDetails,
        cusip: this.selectedProduct?.cusip,
        tradeDate: this.selectedProduct?.tradeDate,
        bulk: false,
        productId: this.selectedProduct?.productId
      },
      height: '800px',
      width: '600px'
    });


    dialogSaveRef.afterClosed().subscribe(result => {
      if(result) {
        this.productService.updateProduct(this.selectedProduct?.productId!, result.productName, result.advisorDetails, result.cusip, result.tradeDate, result.supersededId).subscribe({
          next: () => {
            console.log("Updated: " + this.selectedProduct?.productId!)
            this.selectedProduct!.name = result.productName;
            this.selectedProduct!.advisorDetails = result.advisorDetails;
            this.selectedProduct!.cusip = result.cusip;
            this.selectedProduct!.tradeDate = result.tradeDate;
            this.dialogRef.close(this.selectedProduct);
          },
          error: (e) => {
            console.error('Unable to update name')
            console.error(e)
          }
        });
      }
    });
  }

  closeData(): ProductSearchItem {
    return this.selectedProduct!;
  }

  clear() {
    this.advisor = '';
    this.portfolio = '';
    this.description = '';
    this.branchCode = '';
    this.tradeDate = undefined;
    this.cusip = '';
    this.productCtrl.setValue('');
    this.selectedProduct = undefined;
    this.canLoadProduct = false;
  }

}
