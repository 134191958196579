import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Barrier } from '../../../model/BarrierModel';

@Component({
  selector: 'app-schedule-dialog',
  templateUrl: './schedule-dialog.component.html',
  styleUrl: './schedule-dialog.component.scss'
})
export class ScheduleDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ScheduleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ScheduleData,
  ) {
    dialogRef.disableClose = true;
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

}

export interface ScheduleData {
  barriers: Barrier[];
  showPayoff: boolean;
  showTrigger: boolean;
  isEditable: boolean;
  showCoupons: boolean;
  showBarriers: boolean;
  title: string;
}
