import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  public getProfile() : Observable<Profile> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/profile'
    return this.http.get<Profile>(url, { withCredentials: true });
  }
}

export interface Profile {
  name: string;
  userId?: string;
  deskId?: number;
  deskName?: string;
  isAdmin: boolean;
}
