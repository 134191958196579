<div mat-dialog-title class="dialog-title">
    <h4 class="schedule-header"> UNDERLYING INPUTS </h4>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon class="white">close</mat-icon>
    </button>
</div>
<div mat-dialog-content>
    <div class="row justify-content-center underlying-selector">
        <div class="label-hover col-md-8 ">
            <mat-label class="label">UNDERLYINGS</mat-label>
            <mat-form-field class="col-md-12">
                <input matInput #underlyingInput [matAutocomplete]="auto" [formControl]="underlyingCtrl"/>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                @for (u of filteredUnderlyings | async; track u) {
                    <mat-option [value]="u">{{u}}</mat-option>
                }
                </mat-autocomplete>
            </mat-form-field>
        </div>
    </div>
    
    <div class="table-container">
        <table mat-table [dataSource]="weights" multiTemplateDataRows class="mat-elevation-z0">
            <ng-container matColumnDef="underlying">
                <th mat-header-cell *matHeaderCellDef class="table-header"> UNDERLYING </th>
                <td mat-cell *matCellDef="let element"> {{element.underlying}} </td>
            </ng-container>
    
            <ng-container matColumnDef="weight">
                <th mat-header-cell *matHeaderCellDef class="table-header">WEIGHT</th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field>
                        <input type="number" matInput [(ngModel)]="element.weight" [disabled]="!this.data.isBasket" class="percent-input"/>
                        <span matTextSuffix>%</span>
                    </mat-form-field>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="options">
                <th mat-header-cell *matHeaderCellDef class="table-header">OPTIONS</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button aria-label="Delete" matTooltip="Delete Underlyer" (click)="deleteUnderlyer(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    
    <div class="basket-type">
        <mat-chip-listbox (change)="basketTypeChange($event)" aria-label="Basket Type">
            <mat-chip-option value="0" [selected]="!this.data.isBasket">Worst of</mat-chip-option>
            <mat-chip-option value="1" [selected]="this.data.isBasket">Basket</mat-chip-option>
        </mat-chip-listbox>
    </div>
</div>

<div mat-dialog-actions class="action-btns">
    <button mat-raised-button color="primary" [mat-dialog-close]="closeData()">Save</button>
</div>