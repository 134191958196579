<div class="full-page">
    <div class="topOfPage row">
        <div></div>
        <div class="col-5 offset-1 title">
            <h1 class="titleTextBlock">Arrow Investment Analytics</h1>
            <h2 class="pageHeading">Optimized Selection and Management of Structured Investments</h2>
        </div>
        <div class="col-6 watermark">
            <div>
                <img height="75%" src="/assets/ARROW_Brandmark_Gradient2_GEMGREEN_Crop.png">
            </div>
        </div>
    </div>
    <div class="row about-us">
        <div class="row header-row">
            <div class="offset-sm-1 col-sm-10">
                <span>ABOUT US</span>
                <hr>
            </div>
        </div>
        <div class="row wrap-text">
            <div class="col-sm-5 offset-sm-1">
                <p class="largerFontSize">Arrow IA is an investment platform that
                    provides its clients with services relating to
                    the innovation, origination, evaluation and
                    optimization of Structured Investments
                    and other derivative products.</p>
            </div>
            <div class="col-sm-4 offset-sm-1">
                <p class="grey-text">Arrow IA combines significant product expertise and industry
                    experience with market-leading proprietary investment technology
                    that aims to help financial professionals select the best possible investment for
                    their clients. With offerings ranging from product consulting to
                    technology licensing, Arrow is uniquely and independently focused
                    on investment selection.</p>
            </div>
        </div>
    </div>

    <!-- <div class="row laptopImage">
        <img src="assets\ARROW_RiskEngineMockup.png"
                alt="laptop showing the risk engine's build and price page">
    </div> -->


    <div class="row info-section">
        <div class="row header-row">
            <div class="offset-sm-1 col-sm-10">
                <span>OUR APPROACH</span>
                <hr>
            </div>
        </div>
        <div class="row content-row no-padding-right">
            <div class="col-sm-5 offset-sm-1 squish">
                <p class="largerFontSize">Arrow IA’s market-leading proprietary
                    investment technology is core to the
                    services it provides to its clients.</p>
                <p class="grey-text">Our innovative technology enables clients to independently
                    price and evaluate Structured Investments and to
                    generate robust quantitative analysis that allows for the full
                    evaluation and optimization of products based on client-
                    directed risk and return targets.</p>
            </div>
            <div class="col-md-5 offset-md-1 no-padding-right no-padding-left">
                <img class="shapes-img" src="/assets/landing/OurApproach.png"
                    alt="geometric shapes">
            </div>
        </div>
    </div>

    <div class="row text-with-hr">
        <div class="row header-row">
            <div class="offset-sm-1 col-sm-10">
                <hr>
            </div>
        </div>
        <div class="row squish">
            <div class="col-md-5 offset-md-1">
                <p class="largerFontSize">Independent Pricing and Valuation</p>
            </div>
            <div class="col-md-4">
                <p class="mediumFontSize">Arrow IA’s proprietary pricing platform enables its
                    clients to independently appraise a Structured Investment,
                    accounting for all relevant inputs, such as issuer fees, hedge
                    costs, and credit worthiness.</p>
                    <p class="grey-text">The purpose of this platform is to provide clients with a fair value, and
                        related analytics, that Arrow IA believes is rational and reasonable for
                        a particular product. Through independent valuation, Arrow IA seeks
                        to empower its clients to accurately assess the merits of a product
                        and act in the best interest of their investors.</p>
            </div>
        </div>
    </div>

    <div class="row text-with-hr">
        <div class="row header-row">
            <div class="offset-sm-1 col-sm-10">
                <hr>
            </div>
        </div>
        <div class="row squish">
            <div class="col-md-5 offset-md-1">
                <p class="largerFontSize">Robust Quantitative Analysis</p>
            </div>
            <div class="col-md-4">
                <p class="mediumFontSize">Arrow IA offers clients access to a comprehensive set of risk
                    and return metrics so that they can make more informed
                    decisions when selecting an investment.</p>
                    <p class="grey-text">To calculate the various metrics, Arrow IA creates an independent
                        data set made up of hundreds of thousands of simulated product
                        outcomes spanning across over hundreds of thousands of unique
                        market pathways. This statistically significant dataset provides
                        investors with an enhanced level of confidence and objectivity as they
                        choose a product.</p>
            </div>
        </div>
    </div>

    <div class="row">
        <img class="swirls" src="/assets/landing/swirls.png">
    </div>

    <div class="row our-offering">
        <div class="row header-row">
            <div class="offset-sm-1 col-sm-10">
                <span>OUR OFFERING</span>
                <hr>
            </div>
        </div>
        <div class="row align-center">
            <div class="col-md-6 offering-shapes">
                <img class="shapes-img" src="/assets/landing/geometric.png"
                    alt="geometric shapes">
            </div>
            <div class="offset-md-1 col-md-4 squish">
                <p class="largerFontSize">Arrow IA’s core focus is seeking to provide
                    institutions with the tools they
                    need to select the best possible
                    investments for their clients.</p>
                    <p class="grey-text">Arrow AI combines in-house expertise with advanced
                        investment analytics to optimize risk / return goals and
                        to present products that most efficiently target a client’s
                        investment objectives.</p>
            </div>
        </div>
    </div>

    <div class="align-center">
        <div class="row mobile-small">
            <div class="col-md-2 offset-md-1 no-padding-left">
                <p class="mediumFontSize">Our offering includes, but is not limited to...</p>
            </div>
            <div class="col-md-4 side-bar">
                <div class="right-gap">
                    <p class="mediumFontSize">Customized Investment Analysis</p>
                    <p class="grey-text-small">Leverage our proprietary analytics technology to
                        generate a full spectrum of risk and return metric for
                        any product.</p>
                </div>
                <hr>
                <div>
                    <p class="mediumFontSize">Product Optimization</p>
                    <p class="grey-text-small">Ideate across payout profiles, underlyings, issuers,
                        return metrics, and risk metrics to arrive at a final
                        optimized investment that most efficiently targets a
                        client’s investment objectives.</p>
                </div>
                <hr class="show-on-mobile-only">
            </div>
            <div class="col-md-4 side-bar">
                <div>
                    <p class="mediumFontSize">Independent Fair Valuation</p>
                    <p class="grey-text-small">Utilize our proprietary pricing apparatus to evaluate
                        any product prior to trade, and on an ongoing basis
                        at any time during its investment life.</p>
                </div>
                <hr>
                <div>
                    <p class="mediumFontSize">Identify “Restructuring” Opportunities</p>
                    <p class="grey-text-small">Compare the expected returns and risks of an
                        existing product with the expected returns and risks
                        of a potential new investment, thereby constantly
                        optimizing towards a client’s targeted outcomes.</p>
                </div>
                <hr>
                <div class="large-bottom">
                    <p class="mediumFontSize">Implement a Product Approval Framework</p>
                    <p class="grey-text-small">Partner with our team of product experts to design
                        and implement a product approval framework for
                        your business.</p>
                </div>
    
            </div>
        </div>
    </div>

    <div class="row">
        <img class="example-screen" src="assets\landing\example-screen.png">
    </div>

    <div class="row our-team">
        <div class="row header-row">
            <div class="offset-sm-1 col-sm-10">
                <span>OUR TEAM</span>
                <hr>
            </div>
        </div>
        <div class="row offset-md-1 col-md-10 align-center">
            <div class="row profiles-center">
                <div class="col-md-4 profile">
                    <img class="round-edge" src="assets\ARROW_BrandWebsite_FrankCopplestone_B&W.png"
                    alt="black and white portrait of founding partner Frank Copplestone">
                    <div class="profile-text">
                        <p class="findout">Frank Copplestone</p>
                        <p>FOUNDING PARTNER</p>
                        <p class="grey-text-small">Frank started his career in 1993 at Credit Suisse Financial
                            Products as an Equity Derivatives trader based in London.
                            He later worked at Bankers Trust as a senior multi-asset
                            Equity Derivatives Trader before becoming Head of Exotic
                            Equity Derivatives Trading at Commerzbank. After a
                            decade as a trader, Frank moved to Deutsche Bank to
                            build and manage the Quantitative Products Engineering
                            businesses across EMEA and Asia, being based in London
                            and Hong Kong. In 2008, Frank joined Morgan Stanley to
                            run Financial Engineering in EMEA and Americas, being
                            based in London and New York and later became Global
                            Head of the Retail Structured Products platform. In 2013, Frank was named the Global Head of Equities (Structured Products) at Jefferies. Prior to co-founding Arrow IA, Frank launched Levendi Investment Management in 2017 which is a specialist Structured Investment firm. 
                            Frank holds a Doctorate from Oxford University
                            and is a Freeman of the City of London.</p>
                    </div>
                </div>
                <div class="col-md-4 profile">
                    <img class="round-edge" src="assets\ARROW_BrandWebsite_DaveWalsh_B&W.png"
                    alt="black and white portrait of founding partner Dave Walsh">
                    <div class="profile-text">
                        <p class="findout">Dave Walsh</p>
                        <p>FOUNDING PARTNER</p>
                        <p class="grey-text-small">Dave is a founding partner at Arrow IA. Prior to founding
                            Arrow, Dave was an Executive Director at Morgan Stanley,
                            overseeing the firm’s origination and distribution of
                            Structured Investments into Independent Broker Dealers
                            and Registered Investment Advisors. Dave also spent
                            seven years at JPMorgan, beginning in Institutional Equity
                            Sales before moving to Structured Products Origination,
                            and eventually Structured Products Sales. Dave holds a
                            Bachelor’s Degree in Liberal Arts from Boston College, and
                            currently resides in New York City.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="align-center getInTouchSection">
        <div class="header-row">
            <div class="offset-sm-1 col-sm-10 startConvo">
                <span>START THE CONVERSATION</span>
                <hr>
            </div>
            <div class="row offset-md-1 col-md-10 squish">
                <p class="findout col-md-6">Get in touch to find out what our team can do for you.</p>
                <p><i class="light">Let's talk.</i></p>
            </div>
            <div class="row contactCard">
                <div>
                    <mat-card class="col-md-6 offset-md-3 contact-card">
                        <mat-card-content class="contents">
                            <h2 *ngIf="messageSent">Message Recieved, someone will be in touch.</h2>
                            <div *ngIf="!messageSent">
                                <div class="col-12 label-hover">
                                    <mat-label class="label">NAME</mat-label>
                                    <mat-form-field floatLabel="always" class="col-12">
                                        <input matInput type="text" placeholder="" [(ngModel)]="name" class="email-input">
                                    </mat-form-field>
                                </div>
                                <div class="col-12 label-hover">
                                    <mat-label class="label">EMAIL</mat-label>
                                    <mat-form-field floatLabel="always" class="col-12">
                                        <input matInput type="email" placeholder="" [(ngModel)]="email" class="email-input">
                                    </mat-form-field>
                                </div>
                                <div class="col-12 label-hover">
                                    <mat-label class="label">MESSAGE</mat-label>
                                    <mat-form-field floatLabel="always" class="col-12">
                                        <textarea matInput type="text" placeholder="" [(ngModel)]="message" class="message-input"></textarea>
                                    </mat-form-field>
                                </div>
                                <div>
                                <button mat-raised-button color="primary" class="col-md-12 login-button" (click)="onContactUs()">CONTACT US</button>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>
