import { Component, Input, OnInit } from '@angular/core';
import { PricingItem } from '../../model/PricingItem';

@Component({
  selector: 'app-analysis-view',
  templateUrl: './analysis-view.component.html',
  styleUrl: './analysis-view.component.scss'
})
export class AnalysisViewComponent implements OnInit {

  @Input()
  pricingItem: PricingItem;

  displayedColumns = ["measurement", "metricWithCredit", "metricWithoutCredit"];

  ngOnInit(): void {
    // this.pricingItem = {
    //   arrowId: '',
    //   fairValue: 0,
    //   impliedIssuerPnL: 0,
    //   solvedFor: '',
    //   analysis: {
    //     riskMetrics: [],
    //     returnMetrics: [],
    //     arrowRatios: []
    //   }
    // }
  }

  percent(name: string) {
    return name != "Expected Term to Maturity"
  }

  isLoading() {
    if(this.pricingItem && this.pricingItem.analysis?.riskMetrics && this.pricingItem.analysis?.riskMetrics.length > 0) {
      return false;
    } else {
      return true;
    }
  }

}
