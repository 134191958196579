import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { Barrier } from '../../model/BarrierModel';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-barrier-table',
  templateUrl: './barrier-table.component.html',
  styleUrl: './barrier-table.component.scss',
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class BarrierTableComponent implements OnInit, OnChanges {


  @Input()
  barriers: Barrier[];
  @Output()
  barriersChange = new EventEmitter<Barrier[]>();

  @Output()
  barrierManuallyUpdated = new EventEmitter();

  @Input()
  showPayoff: Boolean;

  @Input()
  showCouponPayoff: Boolean = true;

  @Input()
  showTrigger: Boolean;

  @Input()
  showBarriers: Boolean = true;

  @Input()
  showCoupons: Boolean = false;

  @Input()
  isEditable: Boolean;

  ngOnInit(): void {
    // for (const column of this.columnsToDisplayWithExpand) {
    //   this.columnNames.push(column.header);
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.barriers) {

      this.columnsToDisplay = [...this.defaultColumns]

      if(this.showBarriers) {
        if(this.showTrigger) {
          if(this.isEditable) {
            this.columnsToDisplay.push(this.triggerColumn)
          } else {
            this.columnsToDisplay.push(this.nonEditabletriggerColumn)
          }
        }

        if(this.showPayoff) {
          this.columnsToDisplay.push(this.isEditable ? this.payoffColumn : this.nonEditablepayoffColumn)
        }
      }

      if(this.showCoupons) {

        if(this.showTrigger) {
          let column = {...this.couponBarrierColumn};
          if(!this.isEditable) {
            column.type = column.type + '-no-edit';
          }
          this.columnsToDisplay.push(column);
        }

        if(this.showCouponPayoff) {
          let column = {...this.couponColumn};
          if(!this.isEditable) {
            column.type = column.type + '-no-edit';
          }
          this.columnsToDisplay.push(column);
        }
      }
      this.columnsToDisplay.push(...this.nonEditableDates);


      if(this.isEditable) {
        this.columnsToDisplay.push(this.optionsColumn)
      }

      this.columnNames = [];
      for (const column of this.columnsToDisplay) {
        this.columnNames.push(column.header);
      }
    }
  }

  couponBarrierColumn: ColumnDef = {
    header: 'COUPON BARRIER',
    value: 'couponTrigger',
    type: 'percent'
  };
  couponColumn: ColumnDef = {
    header: 'COUPON',
    value: 'coupon',
    type: 'percent'
  }

  defaultColumns: ColumnDef[] = [
    {
      header: 'TENOR (YRS)',
      value: 'dateInYears',
      type: 'number'
    },
  ]

  editableDates: ColumnDef[] = [
    {
      header: 'OBSERVATION DATE',
      value: 'date',
      type: 'date'
    },
    {
      header: 'SETTLEMENT DATE',
      value: 'settlementDate',
      type: 'date'
    },
  ]

  nonEditableDates: ColumnDef[] = [
    {
      header: 'OBSERVATION DATE',
      value: 'date',
      type: 'date-no-edit'
    },
    {
      header: 'SETTLEMENT DATE',
      value: 'settlementDate',
      type: 'date-no-edit'
    },
  ]

  payoffColumn: ColumnDef = {
    header: 'PAYOFF',
    value: 'payoff',
    type: 'percent'
  }

  nonEditablepayoffColumn: ColumnDef = {
    header: 'PAYOFF',
    value: 'payoff',
    type: 'percent-no-edit'
  }

  triggerColumn: ColumnDef = {
    header: 'CALL THRESHOLD',
    value: 'trigger',
    type: 'percent'
  }

  nonEditabletriggerColumn: ColumnDef = {
    header: 'CALL THRESHOLD',
    value: 'trigger',
    type: 'percent-no-edit'
  }

  disableColumn: ColumnDef = {
    header: 'Disable',
    value: 'disable',
    type: 'disable'
  }

  optionsColumn: ColumnDef = {
    header: 'OPTIONS',
    value: '',
    type: 'options'
  }

  columnsToDisplay: ColumnDef[] = [];

  auxilaryColumns: ColumnDef[] = [
    {
      header: 'Operations',
      type: 'isEdit',
      value: ''
    }
  ]

  columnsToDisplayWithExpand = [...this.columnsToDisplay, {header: 'expand'}];
  expandedElement: any | undefined;

  columnNames: string[] = [];

  getValue(e:any, d:any) {
    if(d.value) {
      return e[d.value];
    } else {
      return this.getSpecialHeader(d.header);
    }
  }

  getType(e:any, d:any): string {
    return "text";
  }

  getSpecialHeader(header: string) {
    return "";
  }

  disable(b: Barrier) {
    b.disable = true;
    this.barrierManuallyUpdated.emit();
  }

  enable(b: Barrier) {
    b.disable = false;
    this.barrierManuallyUpdated.emit();
  }

  // copyBarrier(b: Barrier, idx: number) {
  //   console.log(idx)
  //   let newBarrier = {...b}
  //   this.barriers.splice(idx+1, 0, newBarrier);
  //   this.barriers = [...this.barriers];
  //   this.barriersChange.emit(this.barriers);
  // }

  observationDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.recalcDateInYears();
  }

  recalcDateInYears() {
    //update the dateInYears for the barriers
  }

  getClass(b: Barrier, col: string) {

    if(col == 'coupon' || col == 'couponTrigger') {
      return b.copuonDisable ? 'disabled-text' : '';
    }

    if(col == 'payoff' || col == 'trigger') {
      return b.disable ? 'disabled-text' : '';
    }

    return '';
  }

}

export interface ColumnDef {
  header: string;
  value: string;
  type: string;
}
