<div class="table-container">
    <table mat-table [dataSource]="fees" multiTemplateDataRows class="analysis-table">

        <ng-container matColumnDef="fee">
            <th mat-header-cell *matHeaderCellDef> FEE NAME </th>
            <td mat-cell *matCellDef="let element"> {{element.fee}} </td>
        </ng-container>

        <ng-container matColumnDef="value">
            <th mat-header-cell *matHeaderCellDef>FEE VALUE</th>
            <td mat-cell *matCellDef="let element"> {{element.value | number : '1.2-4'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>