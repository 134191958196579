import {Component, EventEmitter, Input, Output} from '@angular/core';
import {PricingOptionsService} from "../../services/pricing-options.service";
import {CouponOptions} from "../../model/CouponOptions";
import {MatPaginatorModule} from '@angular/material/paginator'
import {ProductBuildDetails} from "../../model/ProductBuildModel";
import {ProductSearchItem, ProductService} from "../../services/product.service";
import { PdfService } from '../../services/pdf.service';
import {AdminService} from "../../services/admin.service";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent {

  @Input()
  currentLoadedProduct: ProductSearchItem | undefined;

  @Output()
  loadProductEmitter = new EventEmitter<ProductBuildDetails>();

  constructor(private pricingOptions: PricingOptionsService, private productService: ProductService,
              private pdf: PdfService, private admin: AdminService, private router: Router) {
  }

  disableDownload = false;

  commitSolve = true;
  toggleCommitSolve() {
    this.pricingOptions.toggleCommitSolve();
    this.commitSolve = this.pricingOptions.commitSolve;
  }

  enableVolShift = false;
  toggleEnableVolShift() {
    this.pricingOptions.toggleEnableVolShiftOnMetrics();
    this.enableVolShift = this.pricingOptions.enableVolShiftOnMetrics;
  }

  useLambda = false;
  toggleUseLambda() {
    this.pricingOptions.toggleUseLambda();
    this.useLambda = this.pricingOptions.useLambda;
  }

  productInput = '';
  loadProduct() {
    this.productService.loadProductByName(this.productInput).subscribe({
      next: data => {
        this.loadProductEmitter.next(data);
      }, error: data => {
        console.log(data);
      }
    })
  }

  downloadZSpreadPDF() {
    this.disableDownload = true;
    this.pdf.generateZSpreadPDF();
  }

  isRunningSecondary = false;
  forceRunSecondary() {
    this.isRunningSecondary = true;
    this.admin.forceRunSecondaryAnalysis().subscribe(
      {
        next: data => {
          this.isRunningSecondary = false;
          console.log("Complete");
        },
        error: data => {
          this.isRunningSecondary = false;
          console.error(data);
        }
      }
    )
  }

  adminPage() {
    this.router.navigate(['admin']);
  }

  newBuildPage() {
    window.location.href = environment.httpProtocol + 'build.' + environment.cookieDomain;
  }

  forceRunZSpreads() {
    this.admin.forceRunZSpreads().subscribe(
      {
        next: data => {
          console.log("Complete");
        },
        error: data => {
          console.error(data);
        }
      }
    )
  }

}
