
<div *ngIf="isLoading() && pricingItem">
    <mat-spinner [diameter]="50"></mat-spinner>
</div>
<div *ngIf="!isLoading() && pricingItem">
    Return Metrics
    <div class="table-container">
        <table mat-table [dataSource]="pricingItem.analysis.returnMetrics" multiTemplateDataRows class="analysis-table">

            <ng-container matColumnDef="measurement">
                <th mat-header-cell *matHeaderCellDef class="first-column"> MEASUREMENT </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="metricWithCredit">
                <th mat-header-cell *matHeaderCellDef>METRIC WITH CREDIT</th>
                <td mat-cell *matCellDef="let element"> {{element.resultWithCredit | percent: '1.2-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="metricWithoutCredit">
                <th mat-header-cell *matHeaderCellDef>METRIC WITHOUT CREDIT</th>
                <td mat-cell *matCellDef="let element"> {{element.resultWithoutCredit | percent : '1.2-2'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row [class.highlight-row]="row.highlight" *matRowDef="let row; columns: displayedColumns;" ></tr>
        </table>
    </div>

    Risk Metrics
    <div class="table-container">
        <table mat-table [dataSource]="pricingItem.analysis.riskMetrics" multiTemplateDataRows class="analysis-table">

            <ng-container matColumnDef="measurement">
                <th mat-header-cell *matHeaderCellDef class="first-column"> MEASUREMENT </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="metricWithCredit">
                <th mat-header-cell *matHeaderCellDef>METRIC WITH CREDIT</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="percent(element.name)">
                        {{element.resultWithCredit | percent : '1.2-2'}}
                    </span>
                    <span *ngIf="!percent(element.name)">
                        {{element.resultWithCredit * 100 | number : '1.2-2'}}
                    </span>
            </ng-container>

            <ng-container matColumnDef="metricWithoutCredit">
                <th mat-header-cell *matHeaderCellDef>METRIC WITHOUT CREDIT</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="percent(element.name)">
                        {{element.resultWithoutCredit | percent : '1.2-2'}}
                    </span>
                    <span *ngIf="!percent(element.name)">
                        {{element.resultWithoutCredit * 100 | number : '1.2-2'}}
                    </span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row [class.highlight-row]="row.highlight" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    Arrow Ratios
    <div class="table-container">
        <table mat-table [dataSource]="pricingItem.analysis.arrowRatios" multiTemplateDataRows class="analysis-table">

            <ng-container matColumnDef="measurement">
                <th mat-header-cell *matHeaderCellDef class="first-column"> MEASUREMENT </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="metricWithCredit">
                <th mat-header-cell *matHeaderCellDef>METRIC WITH CREDIT</th>
                <td mat-cell *matCellDef="let element"> {{element.resultWithCredit | number: '1.2-2'}} </td>
            </ng-container>

            <ng-container matColumnDef="metricWithoutCredit">
                <th mat-header-cell *matHeaderCellDef>METRIC WITHOUT CREDIT</th>
                <td mat-cell *matCellDef="let element"> {{element.resultWithoutCredit | number : '1.2-2'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row [class.highlight-row]="row.highlight" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>
