import {Component, EventEmitter, Input, Output, QueryList, ViewChildren} from '@angular/core';
import { DigitalOptions, MarketParticipation, MarketParticipationOptions } from '../../model/MarketParticipationOptions';
import {GrowthInputsComponent} from "../growth-inputs/growth-inputs.component";

@Component({
  selector: 'app-market-participation',
  templateUrl: './market-participation.component.html',
  styleUrl: './market-participation.component.scss'
})
export class MarketParticipationComponent {

  @Input()
  templateType: number;

  @Input()
  defaultDownwardCap: number;

  @Input()
  participation: MarketParticipation;

  @Output()
  marketParticipationChanged = new EventEmitter<MarketParticipation>();

  @ViewChildren(GrowthInputsComponent)
  growthInputs: QueryList<GrowthInputsComponent>;

  marketParticipation: MarketParticipation = {};

  upsideChanged(options: MarketParticipationOptions) {
    this.marketParticipation.upside = options;
    this.marketParticipationChanged.emit(this.marketParticipation);
  }

  downsideChanged(options: MarketParticipationOptions) {
    this.marketParticipation.downside = options;
    this.marketParticipationChanged.emit(this.marketParticipation);
  }

  digitalChanged(options: DigitalOptions) {
    this.marketParticipation.digital = options;
    this.marketParticipationChanged.emit(this.marketParticipation);
  }

}
