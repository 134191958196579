import {LiveAnnouncer} from '@angular/cdk/a11y';
import {Component, ElementRef, inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent, MatChipListboxChange} from '@angular/material/chips';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {map, Observable, startWith} from 'rxjs';
import {ProductBuildDetails} from '../../pricing/model/ProductBuildModel';
import {Barrier, BarrierModel} from '../../pricing/model/BarrierModel';
import moment from 'moment';
import {MarketDataService, NameAndId} from '../../pricing/services/market-data.service';
import {PricingQueueComponent} from '../../pricing/components/pricing-queue/pricing-queue.component';
import {ProductModel, Weight} from '../../pricing/model/ProductModel';
import {ProductSearchItem, ProductService} from '../../pricing/services/product.service';
import {LOCAL_STORAGE_BASE, PricingComponentToSave, PricingItem} from '../../pricing/model/PricingItem';
import {PutOptions} from '../../pricing/model/PutOptions';
import {CouponOptions} from '../../pricing/model/CouponOptions';
import {
  DigitalOptions,
  MarketParticipation,
  MarketParticipationOptions
} from '../../pricing/model/MarketParticipationOptions';
import {MatSelectChange} from '@angular/material/select';
import {
  ScheduleData,
  ScheduleDialogComponent
} from '../../pricing/components/dialogs/schedule-dialog/schedule-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {
  UnderlyingInputComponent,
  UnderlyingInputData
} from '../../pricing/components/dialogs/underlying-input/underlying-input.component';
import {LoadDialogComponent} from '../../pricing/components/dialogs/load-dialog/load-dialog.component';
import {PrincipleExposureComponent} from '../../pricing/components/principle-exposure/principle-exposure.component';
import {PricingOptionsService} from '../../pricing/services/pricing-options.service';
import {Profile, ProfileService} from "../../services/profile.service";
import {
  AutocallOptions,
  AutocallTriggerInputsComponent
} from "../../pricing/components/autocall-trigger-inputs/autocall-trigger-inputs.component";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {DateFilterFn} from "@angular/material/datepicker";
import {DatePipe} from "@angular/common";
import {CouponInputsComponent} from "../../pricing/components/coupon-inputs/coupon-inputs.component";
import {
  MarketParticipationComponent
} from "../../pricing/components/market-participation/market-participation.component";
import {AccountType} from "../../pricing/model/AccountType";

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss',
  providers: [
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class PricingComponent implements OnInit {

  isAccountTypeFee(): boolean {
    return this.productBuildDetails.accountType===AccountType.FEE
  }

  isAccountTypeCommission(): boolean {
    return this.productBuildDetails.accountType===AccountType.COMMISSION
  }
  showFees:boolean = false;
  accountTypeChanged(e: MatChipListboxChange){
    if(e.value) {
      this.productBuildDetails.accountType = e.value == 4 ? AccountType.FEE : AccountType.COMMISSION;
    } else {
      this.productBuildDetails.accountType = AccountType.UNKNOWN;
    }
  }
  isSendingRequest:boolean = false;
  userProfile: Profile = {
    name: 'Unknown',
    isAdmin: false
  };

  strikeDate = new Date();

  static staticShapes = [
    {name: 'Auto Callable Review Note', value: 0},
    {name: 'Yield Note', value: 1},
    {name: 'Growth Note', value: 2}
  ];

  public static getShapeName(shape: number): string {
    return this.staticShapes.filter(s => s.value == shape).map(s => s.name).map(s => s.replaceAll(" ", "")).join("");
  }

  public static getShapes() {
    return this.staticShapes;
  }

  shapes = PricingComponent.getShapes();

  filteredIssuers: Observable<NameAndId[]>;
  filteredUnderlyings: Observable<any[]>;
  fullUnderlyerList = ['SX5E: Euro Stoxx 50 Pr' ,'SPX: S&P 500 INDEX', 'FTSE: FTSE 100 INDEX']

  issuers: NameAndId[] = [];

  selectedCcy = 'USD';
  ccys = ['GBP', 'USD', 'EUR', 'JPY', 'HKD']

  // issuerPnL: number | null = null;
  solveFor = 0;
  //These are replicated on the backend careful changing the names
  solveForOptions = [
    {name : 'FairValue', value: 0, templates: [0,1,2]},
    {name : 'Coupon (p.a.)', value: 1, templates: [1]},
    {name : 'Call Premium', value: 9, templates: [0]},
    {name : 'Put Barrier', value: 2, templates: [0,1,2]},
    {name : 'Cap', value: 3, templates: [0,1,2]},
    {name : 'Digital', value: 4, templates: [2]},
    {name : 'Participation Rate', value: 5, templates: [0,1,2]},
    {name : 'Abs Return Participation Rate', value: 6, templates: [0,1,2]},
    {name : 'Buffer', value: 7, templates: [0,1,2]},
    {name : 'Autocall Level', value: 8, templates: [0,1]},
    {name : 'Uncapped Digital', value: 10, templates: [2]}
  ];
  shouldShowOption(templates: number[]) {
    return templates.indexOf(this.productBuildDetails.shape!) > -1
  }

  updateProductDetailsWithSolveDefaults(value: number) {
    this.pricingOptions.solvingFor(value);
    // this.issuerPnL = null;
    switch(value) {
      case 9:
        this.productBuildDetails.autocallOptions.payoffPA = 10;
        break;
      case 2:
        this.productBuildDetails.principleExposure.type = 0;
        this.productBuildDetails.principleExposure.barrier = 0.8;
        this.productBuildDetails.principleExposure = this.deepCopyPrincipleExposure(this.productBuildDetails.principleExposure);
        break;
      case 3:
        if(this.productBuildDetails.marketParticipation.upside) {
          this.productBuildDetails.marketParticipation.upside.cap = 0.1;
        } else {
          this.productBuildDetails.marketParticipation.upside =
            {
              payoffStrike: 1,
              participation: 1,
              cap: 1,
              payoffType: 'Call'
            }
        }
        break;
      case 10:
        if(this.productBuildDetails.marketParticipation.digital) {
          this.productBuildDetails.marketParticipation.digital.payoff = 130;
        } else {
          this.productBuildDetails.marketParticipation.digital = {
            barrier: 100,
            payoff: 130
          }
        }
        if(this.productBuildDetails.marketParticipation.upside) {
          this.productBuildDetails.marketParticipation.upside.participation = 1;
        } else {
          this.productBuildDetails.marketParticipation.upside = {
            participation: 1,
            payoffStrike: 1,
            payoffType: 'Call'
          };
        }
        break;
      case 4:
        if(this.productBuildDetails.marketParticipation.digital) {
          this.productBuildDetails.marketParticipation.digital.payoff = 130;
        } else {
          this.productBuildDetails.marketParticipation.digital = {
            barrier: 100,
            payoff: 130
          }
        }
        break;
      case 5:
        if(this.productBuildDetails.marketParticipation.upside) {
          this.productBuildDetails.marketParticipation.upside.participation = 1;
        } else {
          this.productBuildDetails.marketParticipation.upside = {
            participation: 1,
            payoffStrike: 1,
            payoffType: 'Call'
          };
        }
        break;
      case 6:
        if(this.productBuildDetails.marketParticipation.downside) {
          this.productBuildDetails.marketParticipation.downside.participation = 1;
        } else {
          this.productBuildDetails.marketParticipation.downside = {
            participation: 1,
            payoffStrike: 1,
            payoffType: 'Put'
          }
        }
        break;
      case 7:
        this.productBuildDetails.principleExposure.type = 1;
        this.productBuildDetails.principleExposure.payoffStrike = 0.9;
        this.productBuildDetails.principleExposure.participation = -1;
        this.productBuildDetails.principleExposure = this.deepCopyPrincipleExposure(this.productBuildDetails.principleExposure);
        break;
      case 8:
        this.productBuildDetails.autocallOptions.barrier = 100;
        break;
    }
  }
  solveForUpdate(change: MatSelectChange) {
    if(change && (change.value || change.value == 0)) {
      this.updateProductDetailsWithSolveDefaults(change.value);
    }
  }

  tenor: string = '5y';
  finalValuationDate = new Date();

  separatorKeysCodes: number[] = [];
  underlyingCtrl = new FormControl('');
  issuerCtrl = new FormControl('');

  @ViewChild('underlyingInput')
  underlyingInput!: ElementRef<HTMLInputElement>;

  @ViewChild('tenorInput')
  tenorInput!: ElementRef<HTMLInputElement>;

  @ViewChild(PrincipleExposureComponent)
  principleExposureComponent: PrincipleExposureComponent;

  @ViewChild(PricingQueueComponent)
  pricingQueue: PricingQueueComponent;

  @ViewChild(MarketParticipationComponent)
  marketParticipationComponent: MarketParticipationComponent;

  @ViewChildren(CouponInputsComponent)
  couponInputsComponents: QueryList<CouponInputsComponent>;

  couponInputsComponent?: CouponInputsComponent;

  @ViewChildren(AutocallTriggerInputsComponent)
  autocallTriggerInputsComponents: QueryList<AutocallTriggerInputsComponent>;

  autocallTriggerInputsComponent?: AutocallTriggerInputsComponent;

  announcer = inject(LiveAnnouncer);
  defaultDownwardCap = 1;

  productBuildDetails: ProductBuildDetails = {
    frequency: 'Annually',
    term: 5,
    ccy: 'USD',
    strikeDate: this.todayOrNextBusinessDay(),
    finalValuationDate: new Date(),
    underlyings: [],
    underlyingWeights: [],
    shape: 0,
    principleExposure: PrincipleExposureComponent.NO_PROTECTION,
    autocallOptions: {
      barrier: 100,
      barrierChange: 0,
      lockoutMonths: 12,
      payoffPA: 0,
    },
    marketParticipation: {

    },
    solveFor: 0,
    accountType: AccountType.FEE
  }

  differ: any;

  constructor(private marketdata: MarketDataService, private productService: ProductService,
              public dialog: MatDialog, private pricingOptions: PricingOptionsService,
              private profileService: ProfileService, private datePipe: DatePipe) {

    this.filteredUnderlyings = this.underlyingCtrl.valueChanges.pipe(
      startWith(null),
      map((underlying) => (underlying ? this._filter(underlying) : this.fullUnderlyerList.slice().filter(u => !this.productBuildDetails.underlyings.includes(u))))
    )

    this.filteredIssuers = this.issuerCtrl.valueChanges.pipe(
      startWith(null),
      map((issuer) => (issuer ? this._filterIssuers(issuer) : this.issuers.slice()))
    )

    this.tenorChanged()
  }
  ngOnInit(): void {
    this.marketdata.fetchUnderlyers().subscribe({
      next: (data) => {
        this.fullUnderlyerList = data.map(n => n.name + ':' + n.altName).sort();
        this.underlyingCtrl.setValue("");
      },
      error: (e) => {
        console.error('Unable to fetch Underlyers')
        console.error(e)
        //We should probably have a small default lift?
      }
    })

    this.marketdata.fetchInstitutions().subscribe({
      next: (data) => {
        this.issuers = data.sort((a, b) => a.name < b.name ? -1 : 1);
        this.issuerCtrl.setValue("");
      },
      error: (e) => {
        console.error('Unable to fetch Institutions')
        console.error(e)
        //We should probably have a small default lift?
      }
    })

    this.profileService.getProfile().subscribe({
      next: (data: Profile) => {
        this.userProfile = data;
      },
      error: () => {
        console.error('Unable to fetch Profile');
      }
    })

    window.addEventListener('beforeunload', (e)=>{this.savePricingComponentInSessionStorage();});
  }

  shouldShowRestoreLastSessionButton() {
    return !!localStorage.getItem(LOCAL_STORAGE_BASE)&&!this.pricingQueue?.pricingQueue?.length;
  }

  savePricingComponentInSessionStorage() {
    if(!this.pricingQueue?.pricingQueue?.length) {
      return;
    }
    this.couponInputsComponent = this.couponInputsComponents.length>0?this.couponInputsComponents.first:undefined;
    this.autocallTriggerInputsComponent = this.autocallTriggerInputsComponents.length>0?this.autocallTriggerInputsComponents.first:undefined;
    let toSave: PricingComponentToSave = {
      pricingQueue: JSON.stringify(this.pricingQueue?.pricingQueue),
      productBuildDetails: JSON.stringify(this.productBuildDetails),
      currentLoadedProduct: this.currentLoadedProduct===undefined?undefined:JSON.stringify(this.currentLoadedProduct),
      currentRowSelected: this.currentRowSelected===undefined?undefined:JSON.stringify(this.currentRowSelected),
      currentRowSelectedForPricingQueue: this.pricingQueue.currentRowSelected===undefined?undefined:JSON.stringify(this.pricingQueue.currentRowSelected),
      barriers: JSON.stringify(this.barriers),
      ccys: JSON.stringify(this.ccys),
      coupons: JSON.stringify(this.coupons),
      defaultDownwardCap: JSON.stringify(this.defaultDownwardCap),
      finalValuationDate: JSON.stringify(this.finalValuationDate),
      issuers: JSON.stringify(this.issuers),
      issuerCtrl: JSON.stringify(this.issuerCtrl?.value),
      selectedCcy: JSON.stringify(this.selectedCcy),
      staleIssuer: JSON.stringify(this.staleIssuer),
      staleUnderlyers: JSON.stringify(this.staleUnderlyers),
      strikeDate: JSON.stringify(this.strikeDate),
      tenor: JSON.stringify(this.tenor),
      underlyingCtrl: JSON.stringify(this.underlyingCtrl?.value),
      couponInputs: JSON.stringify(this.couponInputsComponent?.coupons),
      couponType: JSON.stringify(this.couponInputsComponent?.couponType),
      selectedObservation: JSON.stringify(this.couponInputsComponent?.selectedObservation),
      frequencyDefault: JSON.stringify(this.couponInputsComponent?.frequencyDefault),
      couponBarrier: JSON.stringify(this.couponInputsComponent?.barrier),
      frequency: JSON.stringify(this.couponInputsComponent?.frequency),
      hasMemory: JSON.stringify(this.couponInputsComponent?.hasMemory),
      payoff: JSON.stringify(this.couponInputsComponent?.payoff),
      callabilityType: JSON.stringify(this.autocallTriggerInputsComponent?.callabilityType),
      callType: JSON.stringify(this.autocallTriggerInputsComponent?.callType),
      stepUp: JSON.stringify(this.autocallTriggerInputsComponent?.stepUp),
      currentlySolvingFor: JSON.stringify(this.pricingOptions.currentlySolvingFor),
      selectedHundredProtection: JSON.stringify(this.principleExposureComponent.selectedHundredProtection),
      maxReturnUp: JSON.stringify(this.marketParticipationComponent.growthInputs.first.maxReturn),
      maxReturnDown: JSON.stringify(this.marketParticipationComponent.growthInputs.last.maxReturn),
    }
    localStorage.setItem(LOCAL_STORAGE_BASE, JSON.stringify(toSave));
  };

  restoreSavedSession() {
    let toRestoreString = localStorage.getItem(LOCAL_STORAGE_BASE);
    if(!toRestoreString) {
      return;
    }
    let toRestoreObject = this.parseJson(toRestoreString);
    this.pricingQueue.pricingQueue = this.parseJson(toRestoreObject.pricingQueue);
    this.productBuildDetails = this.parseJson(toRestoreObject.productBuildDetails);
    this.currentLoadedProduct = this.parseJson(toRestoreObject.currentLoadedProduct);
    this.currentRowSelected = this.parseJson(toRestoreObject.currentRowSelected);
    this.pricingQueue.currentRowSelected = this.parseJson(toRestoreObject.currentRowSelectedForPricingQueue);
    this.barriers = this.parseJson(toRestoreObject.barriers);
    this.ccys = this.parseJson(toRestoreObject.ccys);
    this.coupons = this.parseJson(toRestoreObject.coupons);
    this.defaultDownwardCap = this.parseJson(toRestoreObject.defaultDownwardCap);
    this.finalValuationDate = this.parseJson(toRestoreObject.finalValuationDate);
    this.issuers = this.parseJson(toRestoreObject.issuers);
    this.issuerCtrl.setValue(this.parseJson(toRestoreObject.issuerCtrl));
    this.selectedCcy = this.parseJson(toRestoreObject.selectedCcy);
    this.staleIssuer = this.parseJson(toRestoreObject.staleIssuer);
    this.staleUnderlyers = this.parseJson(toRestoreObject.staleUnderlyers);
    this.strikeDate = this.parseJson(toRestoreObject.strikeDate);
    this.tenor = this.parseJson(toRestoreObject.tenor);
    this.underlyingCtrl.setValue(this.parseJson(toRestoreObject.underlyingCtrl));
    this.pricingOptions.solvingFor(this.parseJson(toRestoreObject.currentlySolvingFor));
    this.principleExposureComponent.selectedHundredProtection = this.parseJson(toRestoreObject.selectedHundredProtection);
    this.marketParticipationComponent.growthInputs.first.maxReturn = this.parseJson(toRestoreObject.maxReturnUp);
    this.marketParticipationComponent.growthInputs.last.maxReturn = this.parseJson(toRestoreObject.maxReturnDown);

    this.couponInputsComponents.changes.subscribe(change => {
      this.couponInputsComponent = change.length > 0 ? change.first : undefined;
      if(!!this.couponInputsComponent) {
        this.couponInputsComponent.coupons = this.parseJson(toRestoreObject.couponInputs);
        this.couponInputsComponent.couponType = this.parseJson(toRestoreObject.couponType);
        // SelectedXYZ doesn't normally need to be explicitly saved, but here the coupon component is not available
        // when all the classes are being initialized because it's only in the DOM conditionally.  Therefore,
        // I have to wait for it to be loaded, which occurs when this function is called since the starting condition
        // of the build page does not pass the condition.  Once the restore is executed, this subscription then waits
        // for the coupon component to load, but by then it is too late for it to detect a "change" in the coupon options
        // via the normal ProductBuildDetails pathway.  Therefore, we have to explicitly change selectedObservation to the
        // correct value, unlike with other components and their selections.
        this.couponInputsComponent.selectedObservation = this.parseJson(toRestoreObject.selectedObservation);
        this.couponInputsComponent.frequencyDefault = this.parseJson(toRestoreObject.frequencyDefault);
        this.couponInputsComponent.barrier = this.parseJson(toRestoreObject.couponBarrier);
        this.couponInputsComponent.frequency = this.parseJson(toRestoreObject.frequency);
        this.couponInputsComponent.hasMemory = this.parseJson(toRestoreObject.hasMemory);
        this.couponInputsComponent.payoff = this.parseJson(toRestoreObject.payoff);
      }
    });

    // autocallTriggerInputsComponent has a change detector that fires on changes coming from its inputs.  These changes
    // trigger that, which can cause values set during restore to be changed.  But those changes are for making the
    // state consistent.  E.g. you cannot have a "varied" barrier scheme where the variation is 0% - just call that
    // fixed instead, and that's what it's resolved to after the change detection fires  But if you have varied with
    // a variation of 1%, that sticks from one session to the next.
    if(!!this.autocallTriggerInputsComponents && this.autocallTriggerInputsComponents.length > 0) {
      this.autocallTriggerInputsComponent = this.autocallTriggerInputsComponents.first;
      this.autocallTriggerInputsComponent.callType = this.parseJson(toRestoreObject.callType);
      this.autocallTriggerInputsComponent.stepUp = this.parseJson(toRestoreObject.stepUp);
      this.autocallTriggerInputsComponent.callabilityType = this.parseJson(toRestoreObject.callabilityType);
    }
  }

  parseJson(toParse:string|undefined|null) {
    return !!toParse?JSON.parse(toParse):undefined;
  }

  updateProductBuildDetails(product: ProductBuildDetails, useProductBuildDetailsForBarriersAndCoupons = true) {
    this.productBuildDetails = this.deepCopyProductDetails(product);
    this.tenor = this.productBuildDetails.term + 'Y';
    //The effect of setting barriers from this is ignored, but we want the other effects of tenorChanged.
    this.tenorChanged()
    if(!!this.productBuildDetails.coupons && !!this.productBuildDetails.barriers && useProductBuildDetailsForBarriersAndCoupons) {
      this.coupons = [...this.productBuildDetails.coupons];
      this.barriers = [...this.productBuildDetails.barriers];
    } else if(this.productBuildDetails.barriers && useProductBuildDetailsForBarriersAndCoupons) {
      this.barriers = [...this.productBuildDetails.barriers];
    } else {
      this.updateBarriers(false, false, false, true);
    }
    this.resetIssuer();
    this.updateProductDetailsWithSolveDefaults(product.solveFor);
    this.productBuildDetails.underlyings.forEach(underlying => {
      this.checkMarketData(underlying)
    });
    this.checkIssuerMarketData();
  }

  principleExposureChange(principleExposure: PutOptions) {
    this.productBuildDetails.principleExposure = principleExposure;
    let payoffStrike = principleExposure.payoffStrike == undefined ? 1 : principleExposure.payoffStrike;
    this.defaultDownwardCap = (1 - payoffStrike);
    console.log('Updated default cap ' + this.defaultDownwardCap);
    //this.updateBarriers(true);
  }

  marketParticipationChange(marketParticipation: MarketParticipation) {
    this.productBuildDetails.marketParticipation = marketParticipation;
    //this.updateBarriers(true);
  }

  suppressCouponOptionsChangedUpdateBarriers = false;
  couponOptionsChanged(couponOptions: CouponOptions) {
    this.productBuildDetails.couponOptions = couponOptions;
    //console.log(couponOptions);
    if(!this.suppressCouponOptionsChangedUpdateBarriers) {
      this.updateCouponBarriers();
    }
  }

  calendarInputsHaveChanged() {
    //console.log(this.productBuildDetails.putDetails)
    console.log('input changed')
    this.updateBarriers(true, false, false, true);
  }

  autoCallPremiumInputsHaveChanged() {
    this.updateBarriers(true, true, false, false)
  }

  autoCallTriggerInputsHaveChanged() {
    this.updateBarriers(true, false, true, false)
  }

  addNewUnderlyer(underlyerName: string) {
    let shortName = underlyerName.split(':')[0];
    this.productBuildDetails.underlyings.push(shortName);
    this.checkMarketData(shortName);
    this.recalcBasketWeights();
    if(this.productBuildDetails.underlyingWeights.length > 3) {
      this.viewUnderlyingInput();
    }
  }

  staleUnderlyers: any = {}
  hasStaleMarketData(underlyerName: string) {
    if(this.staleUnderlyers[underlyerName] != undefined) {
      return this.staleUnderlyers[underlyerName];
    }
    return false;
  }
  checkMarketData(underlyerName: string) {
    if(this.staleUnderlyers[underlyerName]) {
      return this.staleUnderlyers[underlyerName];
    } else {
      this.marketdata.fetchMarketData(underlyerName).subscribe({
        next: result => {
          this.staleUnderlyers[underlyerName] = result.staleData;
        }
      })
    }
  }

  recalcBasketWeights() {
    let underlyingWeights: Weight[] = [];
    let weight = this.productBuildDetails.isBasket && this.productBuildDetails.underlyings.length > 1 ? 100 / this.productBuildDetails.underlyings.length : 0;

    this.productBuildDetails.underlyings.forEach(
      u => {
        underlyingWeights.push({underlying: u, weight: weight});
      }
    )
    this.productBuildDetails.underlyingWeights = underlyingWeights;
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.addNewUnderlyer(value);
    }
    event.chipInput!.clear();
    this.underlyingCtrl.setValue(null);
  }

  remove(underlyer: string): void {
    const index = this.productBuildDetails.underlyings.indexOf(underlyer);
    if (index >= 0) {
      this.productBuildDetails.underlyings.splice(index, 1);
      this.announcer.announce(`Removed ${underlyer}`);
      this.recalcBasketWeights();
      this.underlyingCtrl.setValue(null);
    }
  }

  staleIssuer = false;
  selectedIssuer(event: MatAutocompleteSelectedEvent): void {
    let selectedIssuer: NameAndId = event.option.value;
    this.productBuildDetails.issuer = selectedIssuer.id;
    this.issuerCtrl.setValue(selectedIssuer.name);
    this.checkIssuerMarketData();
  }

  checkIssuerMarketData() {
    this.marketdata.fetchIssuerMarketData(this.productBuildDetails.issuer!).subscribe({
      next: result => {
        if(result) {
          this.staleIssuer = result.staleData;
        }
      },
      error: err => {
        console.log("Unable to fetch issuer marketdata")
      }
    })
  }

  resetIssuer(): void {
    if(this.productBuildDetails.issuer) {
      let issuer: NameAndId = this.issuers.filter(id => id.id == this.productBuildDetails.issuer).at(0)!;
      this.issuerCtrl.setValue(issuer.name);
    } else {
      this.issuerCtrl.setValue('');
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.addNewUnderlyer(event.option.viewValue);
    this.underlyingInput.nativeElement.value = '';
    this.underlyingCtrl.setValue(null);
  }

  private _filterIssuers(value: any): NameAndId[] {
    const filterValue = (typeof value === "string") ? value.toLowerCase() : value.name.toLowerCase();
    return this.issuers.filter(p => p.name.toLowerCase().includes(filterValue));
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.fullUnderlyerList.filter(u => u.toLowerCase().includes(filterValue)).filter(u => !this.productBuildDetails.underlyings.includes(u));
  }

  lockoutUpdated(event: number) {
    this.updateBarriers(false, false, false, false);
  }

  barriers: Barrier[];
  coupons: Barrier[];
  updateBarriers(preserveDisabled: boolean, preserveTrigger: boolean, preservePayoff: boolean, updateCoupons: boolean) {

    let isCallable = true;
    let updatedBarriers = new Array<Barrier>();
    if(this.productBuildDetails.term) {

      let numberOfBarriers = this.productBuildDetails.term * FREQUENCIES[this.productBuildDetails.frequency].numberInYear;

      let startDate = moment(this.productBuildDetails.strikeDate)

      let triggerStartDate = this.productBuildDetails.autocallOptions?.lockoutMonths ?
        this.addMonthsWithRollForwardToNextBusinessDay(startDate, this.productBuildDetails.autocallOptions?.lockoutMonths) : moment(startDate);

      let triggerLevel = this.productBuildDetails.autocallOptions?.barrier;
      let triggerChange = this.productBuildDetails.autocallOptions?.barrierChange;

      let payoffChange = this.productBuildDetails.autocallOptions?.payoffPA;
      isCallable = !(this.productBuildDetails.autocallOptions.disable || this.productBuildDetails.autocallOptions.nonCallable);

      if(!triggerLevel || triggerChange==undefined || payoffChange==undefined) {
        console.log('Autocall options not valid');
        return;
      }

      let numOfMonths = FREQUENCIES[this.productBuildDetails.frequency].inMonths;

      let callPremiumPerBarrier = (payoffChange / 12) * numOfMonths;

      let shouldCompound = this.productBuildDetails.shape == 0; //only compound Review Notes
      let barrierCount = 0;
      for(let i = 0; i < numberOfBarriers; i++) {
        let inMonths = (i+1)*numOfMonths
        let inYears = inMonths/12;
        let dateOfBarrier = this.addMonthsWithRollForwardToNextBusinessDay(startDate, inMonths);
        let trigger = triggerLevel + (triggerChange * barrierCount);
        let payoff = Number((shouldCompound ? (callPremiumPerBarrier + (callPremiumPerBarrier * barrierCount)) + 100 : 100).toFixed(3)); //payoffLevel + (payoffChange * barrierCount);

        let settlementDate = this.addBusinessDaysToDate(dateOfBarrier, 3);

        barrierCount++;
        if(dateOfBarrier.isSameOrAfter(triggerStartDate) && isCallable) {
          updatedBarriers.push(createBarrier(false));
        } else {
          updatedBarriers.push(createBarrier(true));
        }
        function createBarrier(disableBarrier: boolean) : Barrier {
          return {
            dateInYears: inYears,
            date: dateOfBarrier.toDate(),
            settlementDate: settlementDate.toDate(),
            trigger: trigger,
            payoff: payoff,
            disable: disableBarrier,
            copuonDisable: false
          }
        }
      }
    } else {
      console.log('No Term Defined');
      return;
    }

    if(preserveDisabled && this.barriers && (this.barriers.length == updatedBarriers.length) && isCallable) {
      for(let i = 0; i < this.barriers.length; i++) {
        updatedBarriers[i].disable = this.barriers[i].disable;
        //maybe the same with dates?
      }
    }

    if(preserveTrigger && this.barriers && (this.barriers.length == updatedBarriers.length)) {
      for(let i = 0; i < this.barriers.length; i++) {
        updatedBarriers[i].trigger = this.barriers[i].trigger;
      }
    }

    if(preservePayoff && this.barriers && (this.barriers.length == updatedBarriers.length)) {
      for(let i = 0; i < this.barriers.length; i++) {
        updatedBarriers[i].payoff = this.barriers[i].payoff;
      }
    }

    this.barriers = updatedBarriers;

    if(updateCoupons) {
      this.updateCouponBarriers();
    }
    this.productBuildDetails.callThresholdAtMaturity = this.callThresholdAtMaturity();
  }

  updateCouponBarriers() {
    console.log("Updating Coupon Barriers")
    let updatedCoupons = new Array<Barrier>();
    if(this.productBuildDetails.couponOptions) {
      if(this.productBuildDetails.term) {
        var numberOfBarriers = this.productBuildDetails.term * FREQUENCIES[this.productBuildDetails.couponOptions.frequency!].numberInYear;

        let startDate = moment(this.productBuildDetails.strikeDate)
        let barrierCount = 0;

        let numOfMonths = FREQUENCIES[this.productBuildDetails.couponOptions.frequency!].inMonths;
        let barrier: number = this.productBuildDetails.couponOptions?.barrier!;
        let couponPayoff: number = this.productBuildDetails.couponOptions?.payoff!;
        let couponValuePerCoupon = (couponPayoff / 12) * numOfMonths;

        for(let i = 0; i < numberOfBarriers; i++) {
          let inMonths = (i+1)*numOfMonths
          let inYears = inMonths/12;
          let dateOfBarrier = this.addMonthsWithRollForwardToNextBusinessDay(startDate, inMonths);

          let settlementDate = this.addBusinessDaysToDate(dateOfBarrier, 3);

          barrierCount++;
          updatedCoupons.push(createCoupon());

          function createCoupon() : Barrier {
            return {
              dateInYears: inYears,
              date: dateOfBarrier.toDate(),
              settlementDate: settlementDate.toDate(),
              couponTrigger: Number((barrier * 100).toFixed(3)),
              coupon: Number((couponValuePerCoupon * 100).toFixed(3)),
              disable: false,
              copuonDisable: false
            }
          }
        }
      } else {
        console.log('No Term Defined');
        return;
      }
    }

    if(this.coupons && (this.coupons.length == updatedCoupons.length)) {
      for(let i = 0; i < this.coupons.length; i++) {
        updatedCoupons[i].disable = this.coupons[i].disable;
        //maybe the same with dates?
      }
    }

    this.coupons = updatedCoupons;
  }

  callThresholdAtMaturity() : number {
    if(this.barriers && this.barriers.length > 0) {
      return Math.round(this.barriers[this.barriers.length-1].trigger! * 100)
    } else {
      return 100;
    }
  }

  strikeDateChanged() {
    this.tenorChanged();
  }

  holidayDateFilter: DateFilterFn<moment.Moment | null> = (d: moment.Moment | null): boolean => {
    return d?.day() != 0 && d?.day() != 6;
  }

  tenorChanged() {
    //Should only run when input valid
    try {
      var value: number = Number(this.tenor.slice(0, this.tenor.length - 1));
      var type: any = this.tenor.toUpperCase().slice(-1);
      if (['M', 'Y'].includes(type)) {

        this.productBuildDetails.finalValuationDate = this.addMonthsWithRollForwardToNextBusinessDay(
          moment(this.productBuildDetails.strikeDate), 'M' == type ? value : (value * 12)
        ).toDate();

        if('M' == type) {
          this.productBuildDetails.term = (value / 12);
        } else {
          this.productBuildDetails.term = value;
        }
        this.calendarInputsHaveChanged();
      }
    } catch(e) {
      console.log('Not a valid tenor')
    }
  }

  // tenorChanged() {
  //   let finalDate = moment(new Date()).add(this.tenor, 'M').toDate()
  //   this.productBuildDetails.finalValuationDate = finalDate;
  //   this.productBuildDetails.term = (this.tenor / 12);
  //   this.inputsHaveChanged();
  // }

  basketTypeChange(e: MatChipListboxChange) {
    if(e.value == '1') {
      this.productBuildDetails.isBasket = true;
      this.recalcBasketWeights();
      this.viewUnderlyingInput();
    } else {
      this.productBuildDetails.isBasket = false;
      this.recalcBasketWeights();
    }
  }

  addToPricingQueue(forceLaunchAnalytics: boolean = false) {
    if(this.pastTradeDate() && !confirm("If you click OK, you are going to modify a live trade past its trade date.")){
      return;
    }

    this.productBuildDetails.barriers = [...this.barriers];
    this.productBuildDetails.coupons = [...this.coupons];

    let productName = this.generateArrowId();
    let pricingItem: PricingItem =       {
      arrowId: productName,
      solvedFor: 'FairValue',
      analysis: {
        riskMetrics: [],
        returnMetrics: [],
        arrowRatios: []
      },
      productBuildDetails: this.deepCopyProductDetails(this.productBuildDetails),
      forceTradeDateAnalytics: forceLaunchAnalytics
    }

    if(this.currentLoadedProduct) {
      pricingItem.arrowId = this.currentLoadedProduct.name;
      pricingItem.cusip = this.currentLoadedProduct.cusip;
      pricingItem.tradeDate = this.currentLoadedProduct.tradeDate;
      pricingItem.advisorDetails = this.currentLoadedProduct.advisorDetails;
      pricingItem.supersededId = this.currentLoadedProduct.productId;
    }

    if(this.productBuildDetails.solveFor) {
      pricingItem.solvedFor = this.solveForOptions.filter(o => o.value == this.productBuildDetails.solveFor)[0].name;
      pricingItem.impliedIssuerPnL = this.productBuildDetails.reoffer!;
    }
    this.saveProduct(pricingItem);
  }

  generateArrowId() {
    return 'Arrow-' + Math.floor(Math.random() * 100000)
  }

  deepCopyProductDetails(product: ProductBuildDetails) {
    return JSON.parse(JSON.stringify(product));
  }

  deepCopyPrincipleExposure(pe: PutOptions): PutOptions {
    return JSON.parse(JSON.stringify(pe));
  }


  saveProduct(pricingItem: PricingItem) {
    this.isSendingRequest = true;
    let product: ProductModel = this.createProductToSave(pricingItem.arrowId);
    let barriers: BarrierModel[] = [];
    let autocallBarriers: BarrierModel[] = [];
    let couponBarriers: BarrierModel[] = [];

    switch(this.productBuildDetails.shape) {
      case 0: //Autocalls
        autocallBarriers = this.createAutocallBarriers();
        barriers.push(...autocallBarriers);
        break;
      case 1:
        autocallBarriers = this.createAutocallBarriers();
        barriers.push(...autocallBarriers);
        if(this.productBuildDetails.couponOptions) {
          couponBarriers = this.createCoupons();
          barriers.push(...couponBarriers)
        }
        break;
      case 2:
        //When we are solving for uncapped digital we need the digital and upward participation to be on the same leg
        if(this.productBuildDetails.marketParticipation?.digital && this.productBuildDetails.solveFor != 10) {
          let uncappedDigi : boolean = this.productBuildDetails.marketParticipation?.upside != undefined && this.productBuildDetails.marketParticipation?.upside?.cap == undefined;
          barriers.push(this.createDigitalBarrier(this.productBuildDetails.marketParticipation?.digital, uncappedDigi))
        }
        break;
    }

    if(this.productBuildDetails.marketParticipation?.upside) {
      //If we are solving for uncapped digital we need to capture the digital barrier
      let defaultBarrier = this.productBuildDetails.solveFor == 10 ? this.getDigitalBarrier() : 100;
      barriers.push(this.createGrowthBarrier(this.productBuildDetails.marketParticipation.upside, defaultBarrier))
    }
    if(this.productBuildDetails.marketParticipation?.downside) {
      barriers.push(this.createGrowthBarrier(this.productBuildDetails.marketParticipation.downside, 100))
    }

    this.addPutBarrier(barriers);
    barriers.push(this.createRepaymentBarrier());
    this.productService.saveProduct(product, barriers, this.productBuildDetails, autocallBarriers, couponBarriers).subscribe({
        next: (data) => {
          console.log("Saved: " + data.productId);
          pricingItem.productId = data.productId;
          pricingItem.productFees = data.fees;
          this.pricingQueue.notifySaved(pricingItem);
          this.isSendingRequest = false;
        },
        error: (e) => {
          console.error(e.error);
          if(e.error?.message && e.error?.status == 400) {
            pricingItem.error = e.error.message;
          } else {
            pricingItem.error = "Unable to Save Product, Please contact Arrow"
          }
          pricingItem.isRunning = false;
          this.isSendingRequest = false;
        }
    })
    this.pricingQueue.addToPricingQueue(pricingItem);
  }

  private getDigitalBarrier() : number {
    if (this.productBuildDetails.marketParticipation.digital?.barrier) {
      return this.productBuildDetails.marketParticipation.digital.barrier;
    } else {
      return 100;
    }
  }

  private createProductToSave(productName: string) {
    let product: ProductModel = {
      productName: productName,
      ccy: this.productBuildDetails.ccy,
      shape: this.productBuildDetails.shape,
      strikeDate: this.productBuildDetails.strikeDate,
      finalValuationDate: this.productBuildDetails.finalValuationDate,
      underlyings: this.productBuildDetails.underlyings,
      issuer: this.productBuildDetails.issuer,
      isDepositInsured: this.productBuildDetails.principleExposure?.depositInsured,
      issuerCallable: this.productBuildDetails.autocallOptions?.isIssuerCallable,
      nonCallable: this.productBuildDetails.autocallOptions.nonCallable,
      underlyingWeights: this.productBuildDetails.underlyingWeights,
      accountType: this.productBuildDetails.accountType
    }
    return product;
  }

  // This is inverted by createCouponBarrier, must modify both at the same time
  private barrierModelToBarrierCoupon(barrierModel: BarrierModel) {
    let barrier: Barrier = {
      date: barrierModel.startDate,
      settlementDate: barrierModel.settlementDate, //SettlementDate is not actually useful, and probably shouldn't be in the UI at all, we'll just set it to the same as date.
      dateInYears: !!barrierModel.dateInYears ? barrierModel.dateInYears : 0,
      payoffType: barrierModel.payoffType,
      underlyingFunction: barrierModel.underlyingFunction,
      isAbove: barrierModel.above,
      strike: barrierModel.strike,
      participation: barrierModel.participation,
      cap: barrierModel.cap,
      disable: !!barrierModel.disable,
      copuonDisable: !!barrierModel.couponDisable,
      couponTrigger: barrierModel.barrier,
      coupon: barrierModel.payoff,
    }
    return barrier;
  }

  // This is inverted by createAutocallBarrier, must modify both at the same time
  private barrierModelToBarrierAutocall(barrierModel: BarrierModel) {
    let barrier: Barrier = {
      date: barrierModel.startDate,
      settlementDate: barrierModel.settlementDate, //SettlementDate is not actually useful, and probably shouldn't be in the UI at all, we'll just set it to the same as date.
      dateInYears: !!barrierModel.dateInYears ? barrierModel.dateInYears : 0,
      payoff: barrierModel.payoff,
      payoffType: barrierModel.payoffType,
      underlyingFunction: barrierModel.underlyingFunction,
      isAbove: barrierModel.above,
      strike: barrierModel.strike,
      participation: barrierModel.participation,
      cap: barrierModel.cap,
      disable: !!barrierModel.disable,
      copuonDisable: !!barrierModel.couponDisable,
      trigger: barrierModel.barrier,
    }
    return barrier;
  }

  // This is inverted by BarrierModelToBarrierCoupon, must modify both at the same time
  private createCoupons(): BarrierModel[] {
    let coupons: BarrierModel[] = [];

    let cap = 1000000;
    let payoffType = 'Fixed';
    let participation = 0;
    let payoffStrike = 0;

    this.coupons.forEach(
      c => {
        let barrier: BarrierModel = {
          capitalOrIncome: false,
          settlementDate: c.date, // This needs to be date not settlement date, i think the field is named incorrectly
          startDate: c.date, //c.date means observation date, and we load it into several fields because the booking model expects it across the barrierRelations and productBarrier tables.
          endDate: c.date,
          nature: 'and',
          payoff: c.coupon,
          barrier: c.couponTrigger,
          above: true,
          at: true,
          participation: participation,
          payoffStrike: payoffStrike,
          cap: cap,
          payoffType: payoffType,
          barrierType: 'Date',
          underlyingFunction: 'Largest',
          hasMemory: this.productBuildDetails.couponOptions?.hasMemory,
          disable: c.disable,
          couponDisable: c.copuonDisable,
          strike: c.strike,
          dateInYears: c.dateInYears
        }
        coupons.push(barrier);

      }
    );
    return coupons;
  }

  // This is inverted by BarrierModelToBarrierAutocall, must modify both at the same time
  private createAutocallBarriers() : BarrierModel[] {
    let barriers: BarrierModel[] = [];

    let cap = 1000000;
    let payoffType = 'Fixed';
    let participation = 0;
    let payoffStrike = 0;

    //Disabling this as I don't think market participation should affect the autocall barriers

    // if(this.productBuildDetails.marketParticipation && this.productBuildDetails.marketParticipation.upside) {
    //   let options = this.productBuildDetails.marketParticipation.upside;
    //   if(options.cap) {
    //     cap = options.cap;
    //   }
    //   participation = options.participation!;
    //   payoffStrike = options.payoffStrike!;
    //   payoffType = options.payoffType!;

    // }
    this.barriers.forEach(
      b => {
        let barrier: BarrierModel = {
          capitalOrIncome: true,
          settlementDate: b.date, //This needs to be date not settlement date, i think the field is named incorrectly
          startDate: b.date, //b.date means observation date, and we load it into several fields because the booking model expects it across the barrierRelations and productBarrier tables.
          endDate: b.date,
          nature: 'and',
          payoff: b.payoff,
          barrier: b.trigger,
          above: true,
          at: true,
          participation: participation,
          payoffStrike: payoffStrike,
          cap: cap,
          payoffType: payoffType,
          barrierType: 'Date',
          underlyingFunction: 'Largest',
          hasMemory: false,
          couponDisable: b.copuonDisable,
          strike: b.strike,
          disable: b.disable,
          dateInYears: b.dateInYears,
        }
        barriers.push(barrier);
      }
    )
    return barriers;
  }

  private addPutBarrier(barriers: BarrierModel[]) {
    let putDetails = this.productBuildDetails.principleExposure;
    if(putDetails.isDisabled) {
      console.log('Put Barrier Disabled');
      return;
    }
    let isBasket = this.productBuildDetails.isBasket;
    let date = this.productBuildDetails.finalValuationDate!;
    let barrier = {
      capitalOrIncome: true,
      settlementDate: date,
      startDate: date,
      endDate: date,
      nature: 'or',
      payoff: putDetails.strike! * 100,
      barrier: putDetails.barrier! * 100,
      at: false,
      above: false,
      participation: putDetails.participation,
      payoffStrike: putDetails.payoffStrike,
      cap: putDetails.cap,
      description: 'Principle Exposure',
      payoffType: (isBasket ? 'basket': '') + putDetails.payoffType,
      barrierType: putDetails.barrierType!,
      underlyingFunction: 'Largest',
    }
    barriers.push(barrier);
  }

  private getGrowthBarrierBarrierLevel(growthOptions: MarketParticipationOptions, defaultBarrier: number): number {
    //if Put (Downside) the barrier should be other side of 1-cap
    if('Put' == growthOptions.payoffType && growthOptions.cap) {
      return 100 - (growthOptions.cap * 100);
    } else {
      return defaultBarrier;
    }
  }
  private createGrowthBarrier(growthOptions: MarketParticipationOptions, defaultBarrier: number) {
    let date = this.productBuildDetails.finalValuationDate!
    let isBasket = this.productBuildDetails.isBasket;
    let growthBarrier: number = this.getGrowthBarrierBarrierLevel(growthOptions, defaultBarrier);
    let payoff: number = this.productBuildDetails.marketParticipation?.digital ? this.productBuildDetails.marketParticipation.digital.payoff : 100;
    return {
      payoff: 100,
      payoffType: (isBasket ? 'basket': '') + growthOptions.payoffType,
      barrier: growthBarrier,
      barrierType: 'Date',
      capitalOrIncome: true,
      settlementDate: date,
      startDate: date,
      endDate: date,
      nature: 'and',
      description: growthOptions.payoffType == 'Call' ? 'Growth' : 'Abs Return',
      at: true,
      above: true,
      participation: growthOptions.participation,
      payoffStrike: growthOptions.payoffStrike,
      cap: growthOptions.cap,
      underlyingFunction: growthOptions.payoffType == 'Call' ? 'Smallest' : 'Largest'
    }

  }

  private createDigitalBarrier(digitalOptions: DigitalOptions, uncappedDigi: boolean): BarrierModel {
    let date = this.productBuildDetails.finalValuationDate!;
    return {
      payoff: uncappedDigi ? digitalOptions.payoff - 100 : digitalOptions.payoff,
      payoffType: 'Fixed',
      barrier: digitalOptions.barrier,
      barrierType: 'Date',
      capitalOrIncome: !uncappedDigi, //UncappedDigi are marked as Income
      settlementDate: date,
      startDate: date,
      endDate: date,
      nature: 'and',
      description: 'Digital',
      at: true,
      above: true,
      participation: 0,
      payoffStrike: 0,
      cap: 1000000,
      underlyingFunction: 'Largest'
    }
  }

  private createRepaymentBarrier(): BarrierModel {
    let date = this.productBuildDetails.finalValuationDate!;
    return {
      capitalOrIncome: true,
      settlementDate: date,
      startDate: date,
      endDate: date,
      nature: 'and',
      payoff: 100,
      barrier: 0,
      at: true,
      above: true,
      participation: 0,
      payoffStrike: 0,
      cap: 0,
      description: 'Repayment Of Capital',
      payoffType: 'Fixed',
      barrierType: '',
      underlyingFunction: 'Largest',
    }
  }


  addBusinessDaysToDate(date: moment.Moment, businessDays: number) : moment.Moment {
    // A bit of type checking, and making sure not to mutate inputs ::
    const momentDate = moment.isMoment(date) ? date.clone() : moment(date);

    if (!Number.isSafeInteger(businessDays) || businessDays <= 0) {
        // handle these situations as appropriate for your program; here I'm just returning the moment instance ::
        return momentDate;
    } else {
        // for each full set of five business days, we know we want to add 7 calendar days ::
        const calendarDaysToAdd = Math.floor(businessDays / 5) * 7;
        momentDate.add(calendarDaysToAdd, "days");

        // ...and we calculate the additional business days that didn't fit neatly into groups of five ::
        const remainingDays = businessDays % 5;

        // if the date is currently on a weekend, we need to adjust it back to the most recent Friday ::
        const dayOfWeekNumber = momentDate.day();
        if (dayOfWeekNumber === 6) {
            // Saturday -- subtract one day ::
            momentDate.subtract(1, "days");
        } else if (dayOfWeekNumber === 0) {
            // Sunday -- subtract two days ::
            momentDate.subtract(2, "days");
        }

        // now we need to deal with any of the remaining days calculated above ::
        if ((momentDate.day() + remainingDays) > 5) {
            // this means that adding the remaining days has caused us to hit another weekend;
            // we must account for this by adding two extra calendar days ::
            return momentDate.add(remainingDays + 2, "days");
        } else {
            // we can just add the remaining days ::
            return momentDate.add(remainingDays, "days");
        }
    }
  };

  templateChange(template: MatSelectChange) {
    let newTemplate: number = template.value;
    this.productBuildDetails.autocallOptions = DEFAULT_AUTOCALL_OPTIONS;
    this.updateLockout();
    this.updateProductBuildDetails(this.productBuildDetails, false);
    this.productBuildDetails.shape = newTemplate;
    this.solveFor = 0;
    if(newTemplate == 2) {
      this.productBuildDetails.autocallOptions.disable = true;
    } else {
      this.productBuildDetails.marketParticipation.digital = undefined;
    }
  }

  resetProduct() {
    this.currentLoadedProduct = undefined;
    let shape = this.productBuildDetails.shape;
    this.updateProductBuildDetails(DEFAULT_BUILD_DETAILS);
    this.productBuildDetails.shape = shape;
  }

  currentRowSelected: PricingItem;
  rowSelected(selected: PricingItem) {
    this.currentRowSelected = selected;
  }

  updateDataDateRange(selected: PricingItem) {
    if(selected && selected.analysis && selected.analysis.firstDataDate && selected.analysis.lastDataDate) {
      let first = moment(selected.analysis.firstDataDate).format('MMMM Do, YYYY');
      let last = moment(selected.analysis.lastDataDate).format('MMMM Do, YYYY');
      return 'Data Set Date Range: ' + first + ' - ' + last;
    } else {
      return '';
    }
  }

  private calculateHeightForDialog(barriers: Barrier[]): string {
    return Math.min(150 + (this.barriers.length * 67), 800) + 'px';
  }

  viewDateSchedule() {
    let data: ScheduleData = {
      barriers: this.barriers,
      isEditable: true,
      showPayoff: false,
      showTrigger: true,
      showCoupons: false,
      showBarriers: true,
      title: 'DATE SCHEDULE'
    }
    let height:string = this.calculateHeightForDialog(this.barriers);
    const dialogRef = this.dialog.open(ScheduleDialogComponent, {
      data: data,
      height: height,
      width: '1000px'
    });

    dialogRef.afterClosed().subscribe(() => {
      this.updateLockout();
      this.checkAllBarriersDisabled();
    });
  }

  checkAllBarriersDisabled() {
    if(this.barriers.filter(b => !b.disable).length == 0) {
      console.log("All Barriers Disabled");
      this.productBuildDetails.autocallOptions.nonCallable = true;
    } else {
      this.productBuildDetails.autocallOptions.nonCallable = false;
    }
  }

  viewPaymentSchedule() {
    let showCoupons = false;
    let showBarriers = true;
    let barriersToShow = this.barriers;
    let showTrigger: boolean = false;
    if(this.productBuildDetails.shape == 1) {
      showCoupons = true;
      showBarriers = false;
      barriersToShow = this.coupons;
      showTrigger = true;
    }
    let data: ScheduleData = {
      barriers: barriersToShow,
      isEditable: true,
      showPayoff: true,
      showTrigger: showTrigger,
      showCoupons: showCoupons,
      showBarriers: showBarriers,
      title: 'PAYMENT SCHEDULE'
    }
    const dialogRef = this.dialog.open(ScheduleDialogComponent, {
      data: data,
      height: this.calculateHeightForDialog(this.barriers),
      width: '1000px'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
    });
  }

  viewFullSchedule() {
    let showCoupons = false;
    let barriersToShow = this.barriers;
    if(this.productBuildDetails.shape == 1) {
      showCoupons = true;
      barriersToShow = this.combineBarriers();
    }
    let data: ScheduleData = {
      barriers: barriersToShow,
      isEditable: false,
      showPayoff: true,
      showTrigger: true,
      showCoupons: showCoupons,
      showBarriers: true,
      title: 'FULL SCHEDULE'
    }
    const dialogRef = this.dialog.open(ScheduleDialogComponent, {
      data: data,
      height: this.calculateHeightForDialog(this.barriers),
      width: '1000px'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result);
    });
  }

  viewUnderlyingInput() {
    let data: UnderlyingInputData = {
      weights: this.productBuildDetails.underlyingWeights,
      isBasket: this.productBuildDetails.isBasket!,
      fullUnderlyingList: this.fullUnderlyerList
    }
    const dialogRef = this.dialog.open(UnderlyingInputComponent, {
      data: data,
      height: '600px',
      width: '1000px'
    });

    dialogRef.afterClosed().subscribe((result: UnderlyingInputData) => {
      if(result) {
        this.productBuildDetails.underlyingWeights = result.weights;
        this.productBuildDetails.underlyings = this.productBuildDetails.underlyingWeights.map(m => m.underlying);
        this.productBuildDetails.isBasket = result.isBasket;
      }
    });
  }

  pastTradeDate() {
    // return false;
    if(!this.currentLoadedProduct) {
      return false;
    }
    if(!this.currentLoadedProduct.tradeDate) {
      return false;
    }
    return moment(this.currentLoadedProduct.tradeDate).isBefore(moment(), 'D');
    // return Date.parse(this.currentLoadedProduct.tradeDate.toString()) < Date.parse(moment(today).format("yyyy-MM-DD"));
  }

  currentLoadedProduct: ProductSearchItem | undefined = undefined;
  viewLoadProductDialog() {
    const dialogRef = this.dialog.open(LoadDialogComponent, {
      height: '800px',
      width: '600px'
    });

    dialogRef.afterClosed().subscribe((result: ProductSearchItem) => {
      if(result) {
        this.currentLoadedProduct = result;
        this.productService.loadProduct(result.productId).subscribe({
          next: (data) => {
            // Need to check for account type FEE because it has ordinal 0 which is falsy.
            if(!data.productBuildDetails.accountType && data.productBuildDetails.accountType != AccountType.FEE) {
              data.productBuildDetails.accountType = AccountType.UNKNOWN;
            }
            // Note that we don't actually pass coupon and autocall barriers to the back end through productBuildDetails
            // even though the front end model of productBuildDetails has two fields for these.  I decided to just put
            // an explicit set of fields on the back end to separately store coupon and autocall.
            this.validateAndLoadProduct(data.productBuildDetails)
            let barrierObservationDates: Date[] = [];
            let couponObservationDates: Date[] = [];
            let barrierSettlementDates: Date[] = [];
            let couponSettlementDates: Date[] = [];

            this.barriers.forEach(b => {
              barrierObservationDates.push(b.date);
              barrierSettlementDates.push(b.settlementDate);
            });
            this.coupons.forEach(c => {
              couponObservationDates.push(c.date);
              couponSettlementDates.push(c.settlementDate);
            });

            // Loading old data where we weren't yet storing couponBarriers and autocallBarriers works as before.  But
            // for new data, either we have shape is 0 or 1 and these need to be reset. (Legitimate data would never have
            // both couponBarriers and autocallBarriers empty) or the shape is 2, in which case both are empty but neither
            // is used in any way.
            if((!!data.couponBarriers && data.couponBarriers.length>0) || (!!data.autocallBarriers && data.autocallBarriers.length>0)) {
              this.barriers = [];
              this.coupons = [];
            }
            if(!!data.autocallBarriers && data.autocallBarriers.length>0) {
              data.autocallBarriers.sort((a, b) => (!!a.dateInYears ? a.dateInYears : 0) - (!!b.dateInYears ? b.dateInYears : 0));
              data.autocallBarriers.forEach(barrier => {
                this.barriers.push(this.barrierModelToBarrierAutocall(barrier));
              });
              barrierObservationDates.forEach((date, i) => this.barriers[i].date = date);
              barrierSettlementDates.forEach((settlementDate, i) => this.barriers[i].settlementDate = settlementDate);
            }
            if(!!data.couponBarriers && data.couponBarriers.length>0) {
              data.couponBarriers.sort((a, b) => (!!a.dateInYears ? a.dateInYears : 0) - (!!b.dateInYears ? b.dateInYears : 0));
              if (data.productBuildDetails.shape == 0) {
                data.couponBarriers.forEach(barrier => {
                  this.barriers.push(this.barrierModelToBarrierCoupon(barrier));
                });
                barrierObservationDates.forEach((date, i) => this.barriers[i].date = date);
                barrierSettlementDates.forEach((settlementDate, i) => this.barriers[i].settlementDate = settlementDate);
              } else if (data.productBuildDetails.shape == 1) {
                data.couponBarriers.forEach(barrier => {
                  this.coupons.push(this.barrierModelToBarrierCoupon(barrier));
                });
                couponObservationDates.forEach((date, i) => this.coupons[i].date = date);
                couponSettlementDates.forEach((settlementDate, i) => this.coupons[i].settlementDate = settlementDate);
              }
            }
            this.productBuildDetails.coupons = this.coupons;
            this.productBuildDetails.barriers = this.barriers;
            // This 150ms delay is important because loading triggers several changes "inputChanged()" and some subcomponents.
            // Those changes are registered via ngOnChanges and programmed to take 100ms delay.  So I chose 150ms as a number
            // greater than 100ms, but still fast enough nobody could tell the difference.  Without a significant rework of the
            // front end, we have to assume that the ngOnChanges, tenorChanged, and inputChanged logic is here to stay.
            // In that case, we have to deal with load this way now that we want to load the saved schedules, because
            // we can't be sure if the user's next action will be to load the schedule or to make further changes before
            // opening the schedule - the latter option prevents us from just ignoring the ngOnChange type followups
            // to this load method.
            setTimeout(()=>{
              this.barriers=!!this.productBuildDetails.barriers?this.productBuildDetails.barriers:[];
              this.coupons=!!this.productBuildDetails.coupons?this.productBuildDetails.coupons:[];
              }, 150)
          },
          error: (e) => {
            console.log("Unable to load product " + result.productId);
          }
        });
      }
    });
  }

  validateAndLoadProduct(data: ProductBuildDetails) {
    this.validate(data);
    this.updateProductBuildDetails(data);
  }

  updateLockout() {
    let enabledDates = this.barriers.filter(b => !b.disable).map(b => b.dateInYears).sort().splice(0);
    if(enabledDates && enabledDates.length > 0) {
      let firstEnabledDate = enabledDates[0];
      this.productBuildDetails.autocallOptions.lockoutMonths = firstEnabledDate*12;
    }

  }

  validate(data: ProductBuildDetails) {
    if(!data.solveFor) {
      data.solveFor = 0;
    }
    if(!this.pastTradeDate()) {
      data.strikeDate = this.todayOrNextBusinessDay();
    } else {
      // If we are past the trade date, then it must be because there was a trade date, and a loaded product to put it on.
      data.strikeDate = this.currentLoadedProduct?.tradeDate;
    }

    if(!data.autocallOptions) {
      data.autocallOptions = {
        payoffPA: 0,
        barrier: 100,
        barrierChange: 0,
        lockoutMonths: 12
      }
    }
    if(!data.frequency) {
      data.frequency = "Annually";
    }

    if(data.frequency == "Annual") {
      data.frequency = "Annually";
    }
    if(data.couponOptions?.frequency == "Annual") {
      data.couponOptions.frequency = "Annually"
    }
    if(data.frequency == "SemiAnnual") {
      data.frequency = "Semiannually";
    }
    if(data.couponOptions?.frequency == "SemiAnnual") {
      data.couponOptions.frequency = "Semiannually"
    }
    if(!data.marketParticipation) {
      data.marketParticipation = {};
    }
  }

  combineBarriers(): Barrier[] {
    console.log('Combining Barriers');
    let combined = new Array<Barrier>();

    if(this.barriers && this.coupons) {

      //Add All Coupon Values
      this.barriers.forEach(b => {
        let barrier = {...b};
          this.coupons.forEach(c => {
            if(b.dateInYears == c.dateInYears) {
              barrier.coupon = c.coupon;
              barrier.couponTrigger = c.couponTrigger;
              barrier.copuonDisable = c.disable;
            }
          });
          combined.push(barrier);
        }
      )

      //Add Coupons that didn't match
      this.coupons.forEach(c => {
        let foundMatch = false;
        this.barriers.forEach(b => {
          if(b.dateInYears == c.dateInYears) {
            foundMatch = true;
          }
        })
        if(!foundMatch) {
          combined.push(c);
        }
      })
    }

    combined.sort((a,b) => a.dateInYears - b.dateInYears);

    return combined;

  }

  addMonthsWithRollForwardToNextBusinessDay(startDate: moment.Moment, noMonths: number): moment.Moment {
    let newDate = moment(startDate).add(noMonths, 'M');
    let month = newDate.month();
    //Saturday or Sunday Roll eventually we add holidays
    while(newDate.day() === 0 || newDate.day() === 6) {
      newDate.add(1, 'd');
    }

    //If we go over the end of months we need to find the prev business day
    if(month != newDate.month()) {
      newDate.subtract(1, 'd');
      while(newDate.day() === 0 || newDate.day() === 6) {
        newDate.subtract(1, 'd');
      }
    }

    return newDate;
  }

  todayOrNextBusinessDay(): Date {
    let today = new Date();
    if(today.getDay() == 0){
      return moment(today).add(1, "days").toDate();
    }
    if(today.getDay() == 6){
      return moment(today).add(2, "days").toDate();
    }
    return today;
  }


  protected readonly ProductService = ProductService;
  protected readonly AccountType = AccountType;
}

const DEFAULT_BUILD_DETAILS: ProductBuildDetails = {
  frequency: 'Annually',
  term: 5,
  ccy: 'USD',
  strikeDate: new Date(),
  finalValuationDate: new Date(),
  underlyings: [],
  underlyingWeights: [],
  shape: 0,
  principleExposure: PrincipleExposureComponent.NO_PROTECTION,
  autocallOptions: {
    lockoutMonths: 12,
    barrier: 100,
    barrierChange: 0,
    payoffPA: 0,
  },
  marketParticipation: {

  },
  solveFor: 0,
  accountType: AccountType.FEE
}

const DEFAULT_AUTOCALL_OPTIONS: AutocallOptions = {
  lockoutMonths: 12,
  barrier: 100,
  barrierChange: 0,
  payoffPA: 0,
}

const FREQUENCIES: any = {
  'Annually': {numberInYear: 1, inMonths: 12},
  'Semiannually': {numberInYear: 2, inMonths: 6},
  'Quarterly': {numberInYear: 4, inMonths: 3},
  'B-Monthly': {numberInYear: 6, inMonths: 2},
  'Monthly': {numberInYear: 12, inMonths: 1},
};
