import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipListboxChange } from '@angular/material/chips';
import { MatSelectChange } from '@angular/material/select';
import { Observable } from 'rxjs';
import { PricingOptionsService } from '../../services/pricing-options.service';

@Component({
  selector: 'app-autocall-trigger-inputs',
  templateUrl: './autocall-trigger-inputs.component.html',
  styleUrl: './autocall-trigger-inputs.component.scss'
})
export class AutocallTriggerInputsComponent implements OnChanges {

  @Input()
  autocallOptions: AutocallOptions;
  @Output()
  autocallOptionsChange = new EventEmitter<AutocallOptions>();

  @Input()
  frequency: string;
  @Output()
  frequencyChange = new EventEmitter<string>();

  @Output()
  somethingChanged = new EventEmitter();

  @Output()
  lockoutChanged = new EventEmitter<number>();

  @Input()
  canChangeCallType: boolean = false;

  solvingForBarrier: boolean = false;

  constructor(private pricingOptions: PricingOptionsService) {
    this.solvingForBarrier = pricingOptions.currentlySolvingFor == 8;
    pricingOptions.solvingForChange.subscribe(
      id => {
        this.solvingForBarrier = (id == 8);
      }
    )
  }


  ngOnChanges(changes: SimpleChanges): void {
    if(changes['autocallOptions']) {
      let change: AutocallOptions = changes['autocallOptions'].currentValue;
      if(change) {
        if(change.barrierChange) {
          this.callabilityType = change.barrierChange != 0 ? 1 : 0;
          this.stepUp = change.barrierChange > 0;
        } else {
          this.callabilityType = 0;
          this.stepUp = true;
        }
        if(!change.disable) {
          if(change.isIssuerCallable) {
            this.callType = 1;
          } else {
            this.callType = 0;
          }
        } else {
          this.callType = 2;
        }
      } else {
        //No Autocall Options probably should default
      }
    }
  }

  disableChange(e:MatChipListboxChange) {
    if(e.value) {
      this.autocallOptions.disable = true;
    } else {
      this.autocallOptions.disable = false;
    }
    this.inputChanged();
  }

  frequencies = [
    {'name': 'Annually'},
    {'name': 'Semiannually'},
    {'name': 'Quarterly'},
    {'name': 'B-Monthly'},
    {'name': 'Monthly'}
  ]

  callabilityType = 0;
  typeChanged(event: MatChipListboxChange) {
    if(event.value) {
      this.callabilityType = event.value;
      if(event.value == 0) {
        this.autocallOptions.barrierChange = 0;
        this.inputChanged();
      }
    }
  }

  stepUp = true;
  toggleDirection() {
    this.stepUp = !this.stepUp;
    this.invertChange();

  }

  private invertChange() {
    if(this.autocallOptions.barrierChange) {
      this.autocallOptions.barrierChange = this.autocallOptions.barrierChange * -1;
    }
    this.inputChanged();
  }

  inputChanged() {
    this.somethingChanged.emit();
  }

  lockoutUpdated(lockout: any) {
    console.log("Lockout Changed")
    this.lockoutChanged.emit(lockout.target?.value);
  }

  lockoutSelected(lockout: MatAutocompleteSelectedEvent) {
    console.log("Lockout Changed")
    this.lockoutChanged.emit(lockout.option.value);
  }

  frequencyUpdated(change: MatSelectChange) {
    this.lockoutOptions = this.calculateLockoutOptions(change.value);
    this.autocallOptions.lockoutMonths = this.lockoutOptions[0];
    this.inputChanged();
  }

  callType = 0;
  callTypeChange(e:MatChipListboxChange) {
    if(e.value) {
      this.callType = e.value;
      if(this.callType == 1) {
        this.autocallOptions.isIssuerCallable = true;
        this.autocallOptions.nonCallable = false;
        this.autocallOptions.disable = false;
        this.autocallOptions.barrierChange = 0;
        this.callabilityType = 0;
      } else if(this.callType == 2) {
        this.autocallOptions.isIssuerCallable = false;
        this.autocallOptions.disable = true;
        this.autocallOptions.nonCallable = true;
        this.autocallOptions.barrierChange = 0;
        this.callabilityType = 0;
      } else {
        this.autocallOptions.disable = false;
        this.autocallOptions.isIssuerCallable = false;
        this.autocallOptions.nonCallable = false;
      }
    }
    this.inputChanged();
  }

  calculateLockoutOptions(freq: string): number[] {
    return this.lockoutByFreq[freq];
  }

  lockoutOptions: number[] = [3,6,9,12,15,18,21,24];

  private lockoutByFreq: {[id: string]: number[]} = {
    'Annually': [12, 24, 36, 48, 60],
    'Semiannually': [6, 12, 18, 24, 30],
    'Quarterly': [3, 6, 9, 12, 15],
    'B-Monthly': [2, 4, 6, 8, 10, 12],
    'Monthly': [1, 2, 3, 4, 5, 6]
  };
}

export interface AutocallOptions {
  barrierChange: number;
  barrier: number;
  lockoutMonths: number;
  payoffPA: number;
  disable?: boolean;
  isIssuerCallable?: boolean;
  nonCallable?: boolean;
  isMemoriser?: boolean;
}
