<footer class="footer">
    <div class="col-10 offset-1">
        <hr class="pink-hr">
        <div class="addressAndLogo">
            <a href="https://www.linkedin.com/company/arrow-ia/about/"><img src="assets/landing/LinkedInIcon.png"  height="40"></a>
            <div><img src="assets/landing/Brandmark_FTPINK.png" height="50" class="" alt="Arrow text"></div>
        </div>
        <div class="copyRight">
            <div><img src="assets/landing/ARROW_Wordmark_FTPINK.png" height="20" class="" alt="Arrow text"></div>
            <div class="bottomInfo"><p (click)="onTerms()" class="copyright-text underlined">TERMS OF USE</p></div>
            <div class="bottomInfo"><p (click)="onPolicy()" class="copyright-text underlined">PRIVACY POLICY</p></div>
            <div class="bottomInfo"><p class="copyright-text">&copy; ARROW 2024</p></div>
        </div>
        <hr class="pink-hr">
        <div class="copyright-mobile">
            <div><p (click)="onTerms()" class="copyright-text underlined">TERMS OF USE</p></div>
            <div><p (click)="onPolicy()" class="copyright-text underlined">PRIVACY POLICY</p></div>
            <div><p class="copyright-text">&copy; ARROW 2024</p></div>
        </div>
        <p class="disclaimer">Arrow Investment Analytics LLC (“Arrow”) is a structured products data and analytics company providing services to financial professionals within the alternative practice industry. For more information on Arrow’s methodology and platform, please visit arrow-ia.com. Arrow’s results are strictly for informational purposes only and
            are not intended for use by the public. The information is not intended as, nor should it be construed as legal, tax, risk management or investment advice. Further, none of the information contained on this platform constitutes an offer or a recommendation to buy or sell any security, financial product or instrument, or otherwise
            to participate in any particular trading strategy. Structured products are complex and investors should consult with a qualified financial advisor prior to investing</p>

    </div>
</footer>