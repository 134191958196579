import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(private http: HttpClient) { }

  public generateZSpreadPDF() {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/export/zspread'
    this.http.get(url, { withCredentials: true, observe: 'response', responseType: 'blob', headers: {'Accept' : 'application/octet-stream'} }).subscribe(
      response => {
        this.downloadFile(response);
      }
    )
  }
  downloadFile(data: any) {
    let fileName = "ArrowIssuerCreditCurvesReport"
    const blob = new Blob([data.body], { type: 'application/octet-stream' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName + `.pdf`;
    link.click();
  }

}
