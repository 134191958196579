import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MatCheckboxChange} from "@angular/material/checkbox";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ProductService} from "../../../services/product.service";
import {map, startWith} from "rxjs";
import {AdvisorAnalyticsResponse, AdvisorAnalyticsService} from "../../../services/advisor-analytics.service";
import {ScheduleData} from "../schedule-dialog/schedule-dialog.component";

@Component({
  selector: 'app-advisor-analytics',
  templateUrl: './advisor-analytics.component.html',
  styleUrl: './advisor-analytics.component.scss'
})
export class AdvisorAnalyticsComponent implements OnInit {

  isSameAsDefault: boolean = true;
  allSelected : boolean = true;

  advisorAnalytics: AdvisorAnalyticsResponse = {
    returnMetrics: [],
    riskMetrics: [],
    arrowRatios: []
  };

  constructor(
    public dialogRef: MatDialogRef<AdvisorAnalyticsComponent>,
    private advisorAnalyticsService: AdvisorAnalyticsService,
    @Inject(MAT_DIALOG_DATA) public data: string[]
  ) {
    dialogRef.disableClose = true;

  }



  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  downloadPDF() {
    this.advisorAnalyticsService.exportToPDF(this.data, this.advisorAnalytics)
  }
  inputChanged(change: MatCheckboxChange) {
    this.isSameAsDefault = false;
    this.checkAllSelected();
  }

  checkAllSelected() {
    if (this.advisorAnalytics.arrowRatios.find((metric) => !metric.enabled) ||
      this.advisorAnalytics.riskMetrics.find((metric) => !metric.enabled) ||
      this.advisorAnalytics.returnMetrics.find((metric) => !metric.enabled)) {
      this.allSelected = false;
    } else {
      this.allSelected = true;
    }
  }


  selectAll() {
    this.advisorAnalytics.returnMetrics.forEach((m) => m.enabled = true);
    this.advisorAnalytics.riskMetrics.forEach((m) => m.enabled = true);
    this.advisorAnalytics.arrowRatios.forEach((m) => m.enabled = true);
    this.allSelected = true;
    this.isSameAsDefault = false;
  }

  saveAsDefault() {
    this.isSameAsDefault = true;
    this.advisorAnalyticsService.saveAdvisorAnalytics(this.advisorAnalytics).subscribe({
      next: data => {
        console.log("Success")
      }
    });
  }

  ngOnInit(): void {
    this.advisorAnalyticsService.getAdvisorAnalytics().subscribe({
      next: (data) => {
        this.advisorAnalytics = data;
        this.checkAllSelected();
      },
      error: (e) => {
        console.log(e);
      }
    });
  }
}

export interface Metric {
  name: string;
  enabled: boolean;
}
