import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductModel } from '../model/ProductModel';
import { BarrierModel } from '../model/BarrierModel';
import { environment } from '../../../environments/environment';
import {ProductBuildDetails, ProductBuildDetailsWithAutocallAndCouponBarriers} from '../model/ProductBuildModel';
import { ProductSaveResult } from '../model/ProductSaveResult';
import {AdvisorDetails} from "../model/AdvisorDetails";

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  public saveProduct(product: ProductModel, barriers: BarrierModel[], inputs: ProductBuildDetails, autocallBarriers: BarrierModel[], couponBarriers: BarrierModel[]): Observable<any> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/product'
    let request: ProductSaveRequest = {
      product: product,
      barriers: barriers,
      productInputs: inputs,
      autocallBarriers: autocallBarriers,
      couponBarriers: couponBarriers,
    }
    return this.http.post<ProductSaveResult>(url, request, { withCredentials: true });
  }

  public updateProductName(productId: number, name: string) {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/product/' + productId;
    let request = {
      name: name
    };
    return this.http.put(url, request, { withCredentials: true });
  }

  public updateProduct(productId: number, name: string, advisorDetails: AdvisorDetails, cusip?: string, tradeDate?: Date, supersededId?: number) {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/product/' + productId;
    let request: UpdateRequest = {
      name: name,
      advisorDetails: advisorDetails,
      cusip: cusip,
      tradeDate: tradeDate,
      supersededId: supersededId
    };
    return this.http.put(url, request, { withCredentials: true });
  }

  public bulkUpdateProduct(bulkUpdateRequest: BulkUpdateRequest) {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/product/bulk';
    return this.http.put(url, bulkUpdateRequest, { withCredentials: true });
  }

  public loadProductList() {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/product';
    return this.http.get<ProductSearchItem[]>(url, { withCredentials: true });
  }

  public getFailedSecondaryList() {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/product/failed-secondary';
    return this.http.get<FailedSecondaryAnalysisProduct[]>(url, { withCredentials: true });
  }

  public runSecondaryAnalysis(productIds: number[]) {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/product/secondary';
    let request: SecondaryAnalysisRunRequest = {
      productIds: productIds,
    }
    return this.http.post(url, request, { withCredentials: true });
  }

  public loadProduct(productId: number) {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/product/' + productId;
    return this.http.get<ProductBuildDetailsWithAutocallAndCouponBarriers>(url, { withCredentials: true });
  }

  public loadProductByName(name: string) {
    let url: string = environment.httpProtocol+environment.backendUrl + '/api/v1/product/name/' + name;
    return this.http.get<ProductBuildDetails>(url, { withCredentials: true });
  }

}

export interface ProductSaveRequest {
  product: ProductModel;
  barriers: BarrierModel[];
  productInputs: ProductBuildDetails;
  autocallBarriers: BarrierModel[];
  couponBarriers: BarrierModel[];
}

export interface ProductSearchItem {
  productId: number;
  name: string;
  advisorDetails: AdvisorDetails;
  updated: Date;
  cusip: string;
  tradeDate: Date;
}

export interface FailedSecondaryAnalysisProduct {
  jobId: string;
  productId: number;
  fairvalue?: number;
  metrics?: string;
  businessDate?: Date;
  complete?: boolean;
  error?: string;
  start?: Date;
  finish?: Date;
  isSelected?: boolean;
}

export interface SecondaryAnalysisRunRequest {
  productIds: number[];
}

export interface UpdateRequest {
  name: string;
  advisorDetails: AdvisorDetails;
  tradeDate?: Date;
  cusip?: string;
  supersededId?: number;
}

export interface BulkUpdateRequest {
  bulkUpdateDetails: UpdateDetailsForOneProduct[];
}

export interface UpdateDetailsForOneProduct {
  name: string;
  advisorDetails: AdvisorDetails;
  cusip?: string;
  tradeDate?: Date;
  supersededId?: number;
  id?: number;
}
