<!-- topnavbar -->

<!-- <div class="topnav p-1">
  <div class="arrow-header">
    <img style="cursor: pointer;" src="assets/ARROW_Wordmark_WHITE.png" (click)="goHome()"> -->
<!-- <span class="menu" (click)="openNav(true)"><i class="bi bi-list"></i></span> -->
<!-- <span>Build</span>
<span>Review</span>
<span>Trade</span>
<span>Markets</span> -->
<!-- </div>
</div> -->

<!-- <amplify-authenticator>
  <ng-template
    amplifySlot="authenticated"
    let-user="user"
    let-signOut="signOut"
  >
    <button (click)="signOut()">Sign Out</button>
  </ng-template>
</amplify-authenticator> -->
<nav class="navbar fixed-top navbar-expand-lg navbar-light topnav {{environment.env}}">
  <div class="navbar-brand arrow-header" href="#">
    <img src="{{this.wordMarkPNGLocation}}" style={{this.wordMarkStyle}} class="" alt="" (click)="goHome()">
  </div>
  <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button> -->
  <div>
    <ul class="navbar-nav">
      <div class="signin-btn" *ngIf="showSignin">
        <button mat-raised-button (click)="signin()">LOGIN</button>
      </div>

      <li *ngIf="showNavOptions" class="nav-item">
        <a class="nav-link" [href]="environment.v2url">Build</a>
      </li>
      <li *ngIf="showNavOptions" class="nav-item">
        <a class="nav-link" [href]="environment.v2TradeUrl">Trade</a>
      </li>
      <li *ngIf="showNavOptions" class="nav-item">
        <a class="nav-link" href="/review">Review</a>
      </li>
      <li *ngIf="showNavOptions" class="nav-item">
        <a class="nav-link" href="#">Markets</a>
      </li>
      <li class="nav-item nav-brand-icon" *ngIf="showMenu">
        <img src="assets/ARROW_Brandmark_WHITE.png" height="30" class="d-inline-block align-top" alt="">
        <a class="nav-link dropdown-toggle">Profile</a>
        <div class="dropdown-menu">
          <a class="dropdown-item" href="#">Change password</a>
          <a class="dropdown-item last-option" href="" (click)="signOut()">Sign out</a>
          <span class="dropdown-item">
            Contact support...
          </span>
        </div>
      </li>
    </ul>
  </div>
</nav>

<!-- topnavbar -->

<!-- left sidebar nav -->

<!-- <div class="sidebar shadow p-2" id="sidebar" [ngStyle]="responsiveMenu">
  <ul>
    <li><a [routerLink]="['']"><i class="bi bi-house"></i>Home</a></li>
    <li><a [routerLink]="['/pricing']" ><i class="bi bi-feather"></i>Pricing</a></li>
  </ul>

  <div class="bottom">
    <hr>
    <div class="dropdown">
      <a href="#" class="align-items-center text-decoration-none dropdown-toggle show" data-bs-toggle="dropdown" aria-expanded="true">
        <img src="https://avatars.githubusercontent.com/u/13521463?v=4" alt="" width="32" height="32" class="rounded-circle me-2">
        <strong>{{username}}</strong>
      </a>
      <ul class="dropdown-menu dropdown-menu-dark text-small shadow" data-popper-placement="top-start" style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(0px, -34px, 0px);">
        <li><a class="dropdown-item" href="#">Settings</a></li>
        <li><a class="dropdown-item" href="#">Profile</a></li>
        <li><hr class="dropdown-divider"></li>
        <li><a class="dropdown-item" href="#">Sign out</a></li>
      </ul>
    </div>
  </div>

</div> -->

<!-- left sidebar nav -->

<!-- main content -->

<div class="main-content" [ngStyle]="responsiveContent">
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>

<!-- main content -->
