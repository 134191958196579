import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private http: HttpClient) { }

  public send(message: Message) : Observable<void> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/message'
    return this.http.post<void>(url, message, { withCredentials: true });
  }  
}

export interface Message {
  name: string;
  email: string;
  message: string;
}