import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ProductBuildDetails } from '../../model/ProductBuildModel';
import { MatChipListboxChange } from '@angular/material/chips';
import { DigitalOptions } from '../../model/MarketParticipationOptions';
import { PricingOptionsService } from '../../services/pricing-options.service';

@Component({
  selector: 'app-digital-inputs',
  templateUrl: './digital-inputs.component.html',
  styleUrl: './digital-inputs.component.scss'
})
export class DigitalInputsComponent implements OnChanges {

  @Input()
  digitalOptions: DigitalOptions;

  @Output()
  digitalOptionsUpdated = new EventEmitter<DigitalOptions>();

  enabled: boolean = false;

  solvingForDigital: boolean = false;

  constructor(private pricingOptions: PricingOptionsService) {
    this.solvingForDigital = pricingOptions.currentlySolvingFor == 4 || pricingOptions.currentlySolvingFor == 10;
    pricingOptions.solvingForChange.subscribe(
      id => {
        this.solvingForDigital = (id == 4 || id == 10);
      }
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['digitalOptions']) {
      let change: DigitalOptions = changes['digitalOptions'].currentValue;
      if(change) {
        this.enabled = true;
        this.digitalAmount = Number((change.payoff - 100).toFixed(3));
        this.digitalThreshold = change.barrier;
      } else {
        this.enabled = false;
      }
    }
  }

  somethingChanged() {
    this.digitalOptionsUpdated.emit(this.enabled ? {
      barrier: this.digitalThreshold,
      payoff: 100 + this.digitalAmount
    } : undefined);
  }

  digitalThreshold: number = 100;
  digitalAmount: number = 0;


  digitalEnabled(e: MatChipListboxChange) {
    if(e.value) {
      this.enabled = true;
    } else {
      this.enabled = false;
    }
    this.somethingChanged();
  }

}
