import { Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(private http: HttpClient) { }

  public exportToExcel(ids: string[]) : void {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/export/excel'
    let request: ExcelExportRequest = {
      productIds: ids
    }
    this.http.post(url, request, { withCredentials: true, observe: 'response', responseType: 'blob', headers: {'Accept' : 'application/octet-stream'} }).subscribe(
      response => {
        this.downloadFile(response, request);
      }
    )
  }

  downloadFile(data: any, request: ExcelExportRequest) {

    let fileName = "ArrowExport-" + request.productIds.join("_")

    const blob = new Blob([data.body], { type: 'application/octet-stream' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName + `.xlsx`;
    link.click();
  }

}

export interface ExcelExportRequest {
  productIds: string[];
}
