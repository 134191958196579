import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import { PricingItem} from '../../model/PricingItem';
import { AnalysisService } from '../../services/analysis.service';
import { PriceService } from '../../services/price.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { SaveProductDialogComponent } from '../dialogs/save-product-dialog/save-product-dialog.component';
import {ProductService, BulkUpdateRequest, UpdateDetailsForOneProduct} from '../../services/product.service';
import { ProductBuildDetails } from '../../model/ProductBuildModel';
import { SolvingService } from '../../services/solving.service';
import {ExcelService} from "../../services/excel.service";
import {AdvisorDetails} from "../../model/AdvisorDetails";
import {PricingComponent} from "../../../pages/pricing/pricing.component";
import {PricingOptionsService} from "../../services/pricing-options.service";
import {AdvisorAnalyticsComponent} from "../dialogs/advisor-analytics/advisor-analytics.component";
import moment from "moment";
import {NameAndId} from "../../services/market-data.service";

@Component({
  selector: 'app-pricing-queue',
  templateUrl: './pricing-queue.component.html',
  styleUrl: './pricing-queue.component.scss'
})
export class PricingQueueComponent  implements OnInit {

  @Output()
  showProductBuildDetails = new EventEmitter<ProductBuildDetails>();

  @Output()
  rowSelected = new EventEmitter<PricingItem>();

  @Input()
  issuers: NameAndId[];

  constructor(private analysis: AnalysisService, private priceService: PriceService, public dialog: MatDialog,
              private productService: ProductService, private solveService: SolvingService, private excelService : ExcelService,
              private pricingOptions: PricingOptionsService) {

  }

  pricingQueue: PricingItem[] = [];

  displayedColumns: string[] = ['arrowId', 'solvedFor', 'fairValue', 'impliedIssuerPnL', 'arrowBookValue', 'selectBox']; //removing options for more space
  currentRowSelected: PricingItem;

  ngOnInit(): void {

  }

  selected(item: PricingItem) {
    this.currentRowSelected = item;
    this.rowSelected.emit(item);
    this.editProduct(item);
  }

  addToPricingQueue(item: PricingItem) {
    item.isRunning = true;
    this.pricingQueue = this.pricingQueue.concat(item);
  }

  notifySaved(item: PricingItem) {
    if(item.solvedFor == 'FairValue') {
      this.priceProduct(item);
    } else {
      this.solveProduct(item);
    }
  }

  getClass(item: PricingItem) {
    if(item == this.currentRowSelected) {
      return 'highlight-row';
    } else {
      return item.isRunning ? 'pricing-row' : '';
    }
  }

  runArrowAnalysis(item: PricingItem) {
    this.analysis.runAnalysisAsync({productId: item.productId, price: 100, isArrowMarket: true,
      asOf: this.getAsOfDate(item),
      enableVolShift: this.pricingOptions.enableVolShiftOnMetrics,
      useLambda: this.pricingOptions.useLambda}).subscribe({
      next: (data) => {
        console.log("Fetched analysis data");
        item.analysis = data;
        item.hasAnalysis = true;
      },
      error: (error) => console.error(error)
    })
  }

  getAsOfDate(item: PricingItem): Date | undefined {
    if(item.tradeDate && moment(item.tradeDate).isBefore(moment(), 'D')) {
      if(item.forceTradeDateAnalytics) {
        return item.productBuildDetails?.strikeDate!;
      }
      //We are past trade date so don't set asof date
      return undefined;
    }
    return moment(item.productBuildDetails?.strikeDate!).isBefore(moment(), 'D') ? item.productBuildDetails?.strikeDate! : undefined;
  }

  solveProduct(pricingItem: PricingItem) {
    pricingItem.isRunning = true;
    let commitResult = this.pricingOptions.commitSolve;
    this.solveService.solveProductAsync(pricingItem.productId!, pricingItem.impliedIssuerPnL!, pricingItem.solvedFor, commitResult, this.getAsOfDate(pricingItem), this.pricingOptions.useLambda).subscribe({
      next: (data) => {
        console.log("Solving Result: " + JSON.stringify(data))
        if(data.error) {
          console.log("There has been an error solving product..")
          console.log(data.error);
          pricingItem.error = 'Error: please contact Arrow';
        } else {

          //Arrow Fair Value -> data.value
          //Spread Above -> reoffer(impliedIssuerPnl) - data.fair
          //arrow book value -> data.fair
          pricingItem.solvedFor = (data.value * 100).toFixed(3) + '%'
          pricingItem.arrowFair = data.fair + data.impliedIssuerPnL;
          pricingItem.fairValue = data.fair;
          pricingItem.spreadAbove = pricingItem.productBuildDetails?.reoffer ? pricingItem.productBuildDetails?.reoffer - (pricingItem.arrowFair) : 0;

          if(pricingItem.productFees) {
            pricingItem.productFees.issuerFees = data.impliedIssuerPnL;
            pricingItem.productFees.issuerFeeExcludingVega = data.impliedIssuerPnL;
            if(data.barrierShiftUsed) {
              pricingItem.productFees.barrierShift = data.barrierShiftUsed;
            }

            pricingItem.productFees = this.deepCopy(pricingItem.productFees);
          }

          if(pricingItem.fairValue) {
            this.runArrowAnalysis(pricingItem);
          } else {
            pricingItem.error = 'Unable to Calculate'
          }

        }
        pricingItem.isRunning = false;
      },
      error: (e) => {
        console.error('Unable to Solve')
        console.error(e)
        pricingItem.isRunning = false;
        pricingItem.error = 'Error: please contact Arrow';
      }
    })
  }

  priceProduct(pricingItem: PricingItem) {
    pricingItem.isRunning = true;
    this.priceService.priceProductAsync(pricingItem.productId!, this.getAsOfDate(pricingItem), this.pricingOptions.useLambda).subscribe({
      next: (data) => {
        console.log("Pricing Result: " + JSON.stringify(data))

        if(data.error) {
          console.error(data.error)
          pricingItem.error = 'Error: please contact Arrow';
          pricingItem.isRunning = false;
          return;
        }

        pricingItem.fairValue = data.fairValue;
        pricingItem.arrowFair = data.fairValue + data.impliedIssuerPnL;
        pricingItem.impliedIssuerPnL = data.impliedIssuerPnL;
        pricingItem.spreadAbove = pricingItem.productBuildDetails?.reoffer ? pricingItem.productBuildDetails?.reoffer - (pricingItem.arrowFair) : 0;

        if(pricingItem.productFees) {
          pricingItem.productFees.issuerFees = data.impliedIssuerPnL;
          pricingItem.productFees.issuerFeeExcludingVega = data.issuerFee;
          pricingItem.productFees = this.deepCopy(pricingItem.productFees);
        }

        if(pricingItem.fairValue) {
          this.runArrowAnalysis(pricingItem);
        } else {
          pricingItem.error = 'Unable to Calculate'
        }
        pricingItem.isRunning = false;
      },
      error: (e) => {
        console.error('Unable to Price')
        console.error(e)
        pricingItem.isRunning = false;
        pricingItem.error = 'Error: please contact Arrow';
      }
    })
  }

  editProduct(element: PricingItem) {
    this.showProductBuildDetails.emit(element.productBuildDetails);
  }

  deleteProduct(element: PricingItem) {
    const i = this.pricingQueue.indexOf(element);
    if(i > -1) {
      this.pricingQueue.splice(i, 1);
      this.pricingQueue = [...this.pricingQueue]

      if(element == this.currentRowSelected) {
        this.rowSelected.emit(undefined);
      }

    }
  }

  deleteSelected() {
    this.pricingQueue.filter(p => p.isSelected).forEach(p => this.deleteProduct(p));
  }

  saveSelected() {
    let defaultAdvisorDetails: AdvisorDetails = {
      branchCode: '',
      advisor: '',
      description: '',
      portfolio: ''
    };
    const dialogRef = this.dialog.open(SaveProductDialogComponent, {
      data: {advisorDetails: defaultAdvisorDetails, bulk: true},
      height: '800px',
      width: '600px'
    });
    const bulkUpdateDetails: UpdateDetailsForOneProduct[] = [];
    dialogRef.afterClosed().subscribe(result => {
      let advisorDetails: AdvisorDetails = result.advisorDetails;
      this.pricingQueue.filter(p => p.isSelected).forEach(p => {
        p.arrowId = this.createProductName(p);
        p.advisorDetails = result.advisorDetails;
        bulkUpdateDetails.push({name: p.arrowId, advisorDetails: advisorDetails, cusip: p.cusip, tradeDate: p.tradeDate, id: p.productId, supersededId: p.supersededId});
      });
      let bulkUpdateRequest: BulkUpdateRequest = {bulkUpdateDetails: bulkUpdateDetails};
      this.productService.bulkUpdateProduct(bulkUpdateRequest).subscribe({          next: () => {
          console.log("Bulk update successful")
        },
        error: (e) => {
          console.error('Unable to bulk update. Error: ' + e.message);
        }})
    });
  }

  exportProduct(element: PricingItem) {
    this.excelService.exportToExcel(this.selectedProducts())
  }

  selectedProducts(): string[] {
    return this.pricingQueue.filter(p => p.isSelected).map(p => p.productId).map(p => p!.toString())
  }

  deepCopy(value: any) {
    return JSON.parse(JSON.stringify(value));
  }

  saveProduct(element: PricingItem) {

    let defaultName = this.createProductName(element);

    let defaultAdvisorDetails: AdvisorDetails = element.advisorDetails ? element.advisorDetails : {
      branchCode: '',
      advisor: '',
      description: '',
      portfolio: ''
    };

    const dialogRef = this.dialog.open(SaveProductDialogComponent, {
      data: {
        productName: defaultName,
        advisorDetails: defaultAdvisorDetails,
        cusip: element.cusip,
        tradeDate: element.tradeDate,
        supersededId: element.supersededId,
        bulk: false,
        productId: element.productId
      },
      height: '800px',
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        element.arrowId = result.productName;
        element.advisorDetails = result.advisorDetails;
        element.cusip = result.cusip;
        element.tradeDate = result.tradeDate;
        element.supersededId = result.supersededId;
        this.productService.updateProduct(element.productId!, element.arrowId, result.advisorDetails, result.cusip, result.tradeDate, result.supersededId).subscribe({
          next: () => {
            console.log("Updated: " + element.productId)
          },
          error: (e) => {
            console.error('Unable to update name')
            console.error(e)
          }
        });
      }
    });
  }

  viewAdvisorAnalyticsDialog() {
    const dialogRef = this.dialog.open(AdvisorAnalyticsComponent, {
      height: '600px',
      width: '500px',
      data: this.selectedProducts()
    });
  }

  createProductName(pricingItem: PricingItem): string {

    let strike: Date = pricingItem.productBuildDetails?.strikeDate!;
    let dateAsString: string = moment(strike).format('MMMM YYYY ');
    let branchCode: string = pricingItem.advisorDetails?.branchCode ? pricingItem.advisorDetails?.branchCode + ' ' : '';
    let issuer: string = this.getIssuerName(pricingItem.productBuildDetails?.issuer!) + ' ';
    let tenor: string = pricingItem.productBuildDetails?.term + "Y ";
    let isBasket = !pricingItem.productBuildDetails?.underlyingWeights.map(w => w.weight).every(z => z === 0);
    let underlyingPreText = pricingItem.productBuildDetails!.underlyingWeights.length > 1 ? (isBasket ? "BASKET": "WoF")+" " : '';
    let underlyings = pricingItem.productBuildDetails?.underlyings.join(" ");
    let shapeName = ' ' + PricingComponent.getShapeName(pricingItem.productBuildDetails?.shape!);

    return dateAsString + branchCode + issuer + tenor + underlyingPreText + underlyings + shapeName;
  }

  getIssuerName(issuerId: number): string {
    return this.issuers.find(v => v.id == issuerId)?.name!;
  }


  sendMailToArrow(element: PricingItem) {
    let subject = 'Pricing Error on ' + element.arrowId + ' (' + element.productId + ')';
    window.location.assign('mailto:pricing@arrow-ia.com?subject=' + subject);
  }
}
