export const environment = {
    production: false,
    env: 'uat',
    httpProtocol: 'https://',
    backendUrl: 'api.arrow-qa.ljstechnology.co.uk',
    cognito: {
        userPoolId: 'eu-west-2_t6dSkTtjS',
        userPoolWebClientId: '2ihs7reqdaug91pru3hsfihou3',
      },
    cookieDomain: 'arrow-qa.ljstechnology.co.uk',
};
