<div class="col-md-12">
  <mat-card>
    <mat-card-header (click)="adminPage()" class="hover-pointer">Admin Tools</mat-card-header>
    <mat-card-content>
      <mat-checkbox [(ngModel)]="commitSolve" (change)="toggleCommitSolve()" color="primary" >Commit Solve</mat-checkbox>
      <br>
      <mat-checkbox [(ngModel)]="enableVolShift" (change)="toggleEnableVolShift()" color="primary" >Vol Shift on Metrics</mat-checkbox>
      <br>
      <mat-checkbox [(ngModel)]="useLambda" (change)="toggleUseLambda()" color="primary" >Use Lambda for Pricing</mat-checkbox>
      <div class="row">
        <div class="col-md-6 label-hover">
          <mat-label>Load By Name</mat-label>
          <mat-form-field class="col-md-12">
            <input matInput [(ngModel)]="productInput" (keyup.enter)="loadProduct()">
            <mat-icon matSuffix (click)="loadProduct()">upload</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <hr *ngIf="currentLoadedProduct">
      <div class="row" *ngIf="currentLoadedProduct">
        <div>
          <h4>Editing Product</h4>
        </div>
        <div>
          {{currentLoadedProduct | json}}
        </div>
      </div>
      <hr>
      <div class="row">
        <div>
          <h4>ZSpreads</h4>
        </div>
        <div class="zspread-btn-container">
          <button class="col-md-4 zspread-btn" [disabled]="disableDownload" mat-raised-button (click)="downloadZSpreadPDF()">Download</button>
          <button class="col-md-4 zspread-btn" [disabled]="disableDownload" mat-raised-button (click)="forceRunZSpreads()">Force Run</button>
        </div>
      </div>
      <hr>
      <div class="row">
        <div>
          <h4>Secondary Analysis</h4>
        </div>
        <div class="zspread-btn-container">
          <button class="col-md-4 zspread-btn" [disabled]="isRunningSecondary" mat-raised-button (click)="forceRunSecondary()">Force Run</button>
        </div>
      </div>
      <hr>
      <button class="zspread-btn" mat-raised-button (click)="newBuildPage()">New Build Page</button>
    </mat-card-content>
  </mat-card>
</div>
