<div class="container full-screen-container">
  <h1 class="page-header">Admin Tools</h1>
  <div *ngIf="userProfile.isAdmin">

    <div class="row">
      <div class="col-md-3">
        <mat-card>
            <mat-card-header>
              Calendar Tools
            </mat-card-header>
            <mat-card-content>
              <div class="col-md-6 label-hover">
                <mat-label class="label">Calendar Recipients</mat-label>
                <mat-form-field class="col-md-12">
                  <input matInput [(ngModel)]="calendarRecipients">
                </mat-form-field>
                <mat-error *ngIf="emailError">{{emailError}}</mat-error>
              </div>
              <div style="display: flex">
                <button mat-raised-button [disabled]="calendarSuccess" color="warn" (click)="runCalendar()" matTooltip="Run the calendar and send the resulting output to the email address specified.">Run Calendar</button>
                <mat-icon class="calendar-icon" *ngIf="calendarSuccess">check_circle</mat-icon>
              </div>
            </mat-card-content>
        </mat-card>
      </div>
    </div>


    <div class="row">
      <button class="col-md-3" mat-raised-button
              (click)="selectAll()">SELECT ALL
      </button>
      <button class="col-md-3" mat-raised-button
              (click)="rerunSelected()">RERUN SELECTED
      </button>
      <div class="col-md-12">
        <mat-card>
          <mat-card-header>Secondary Analysis</mat-card-header>
          <table mat-table [dataSource]="failedSecondaryAnalysisProductsToShow" multiTemplateDataRows class="mat-elevation-z0">
            @for (col of columnsToDisplay; track col) {
              <ng-container matColumnDef="{{col.header}}">
                <th mat-header-cell *matHeaderCellDef class="table-header"> {{col.header}} </th>
                <td mat-cell *matCellDef="let element; let idx = dataIndex">
                  <div [ngSwitch]="col.type">
                    <span *ngSwitchCase="'date'">
                      {{ element[col.value] | date:'MMMM d, y, h:mm:ss a z'}}
                    </span>
                    <span *ngSwitchCase="'checkbox'">
                      <mat-checkbox class="white-checkbox" [(ngModel)]="element.isSelected"></mat-checkbox>
                    </span>
                    <span *ngSwitchDefault>
                      {{ element[col.value] }}
                    </span>
                  </div>
                </td>
              </ng-container>
            }
            <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
            <tr mat-row *matRowDef="let element; columns: columnNames;"></tr>
          </table>
          <mat-paginator [length]="failedSecondaryAnalysisProducts.length"
                         [pageSize]="10"
                         [pageSizeOptions]="[5, 10, 20]"
                         [showFirstLastButtons]="true"
                         [pageIndex]="currentPage"
                         (page)="handlePageEvent($event)"
          >
          </mat-paginator>
        </mat-card>
      </div>
    </div>
  </div>
  <div *ngIf="!userProfile.isAdmin">
    Admin permissions to access this page.
  </div>
</div>
