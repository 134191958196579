import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, filter, switchMap, take, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AnalysisResultModel } from '../model/AnalysisResultModel';
import { NameAndId } from './market-data.service';

@Injectable({
  providedIn: 'root'
})
export class AnalysisService {

  constructor(private http: HttpClient) { }

  private getResult(uuid: string): Observable<AnalysisResultModel> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/analysis/async/' + uuid;
    return this.http.get<AnalysisResultModel>(url);
  }

  public runAnalysisAsync(request: AnalysisRequest): Observable<AnalysisResultModel> {
    let url = environment.httpProtocol+environment.backendUrl + '/api/v1/analysis/async'

    //Probably need to check on error conditions
    return this.http.post<string>(url, request, { withCredentials: true }).pipe(
      switchMap(
        uuid => timer(4000, 2000).pipe(
          switchMap(() => this.getResult(uuid)),filter(data => (data != null)),take(1)
      ))
    )
  }

}

export interface AnalysisRequest {
  arrowId?: string,
  productId?: number,
  accuracy?: number,
  price?: number
  isArrowMarket: boolean,
  asOf: Date | undefined,
  enableVolShift: boolean,
  useLambda: boolean | undefined,
}
