import { Component } from '@angular/core';
import { CognitoService } from '../../services/cognito.service';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrl: './switcher.component.scss'
})
export class SwitcherComponent {

  constructor(private cognito: CognitoService) {

  }

  userLoggedIn() : boolean {
    return this.cognito.checkLoggedIn();
  }

}
